import React from "react";
import { useParams } from "react-router-dom";
import Theme404 from '../../layout/404';

import stock_list from './stock.list';
import stock_edit from './stock.edit'
import stock_add_product from './stock.addProduct'
import stock_detail from './stock.detail'

export default function StockIndex() {
    let useParam = {} as any;
    useParam = useParams();
    let Param = useParam.slug || 'list';
    let ActualPage: any;
    switch (Param) {

        case 'list':
            ActualPage = stock_list;
            break;

        case 'new':
            ActualPage = stock_edit;
            break;

        case 'edit':
            ActualPage = stock_edit;
            break;
        case 'detail':
            ActualPage = stock_detail;
            break;
        case 'add_product':
            ActualPage = stock_add_product;
            break;

        default:
            ActualPage = Theme404;
            break;
    }

    return (
        <>
            {<ActualPage />}
        </>
    );
}
