import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createEntitySlice, serializeAxiosError } from 'config/reducer.utils';
import helpers from 'helpers/index';


/**
*   Reducer used for front-end, with settings.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null,
  entities: null as any,
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'transaction_to_order';

// Actions

export const getEntities = createAsyncThunk('transaction_to_order/fetch_entity_list', async (project_id: string) => {
      const requestUrl = `${apiUrl}/${project_id}`;
      return await axios.get<any>(requestUrl);
    },
    { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'transaction_to_order/create_entity',
  async (entity: {transaction_id: string, order_pnr: string}, thunkAPI) => {
    return await axios.post<any>(`${apiUrl}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);


// slice

export const Reducer_System_TransactionToOrder = createEntitySlice({
  name: 'transaction_to_order',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = false;
    }
  },
  extraReducers(builder) {
    builder
    
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.totalItems = 0;
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true;
        state.entities = [];
      })


      .addCase(createEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(createEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(createEntity.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })

    
  },
});

export const { clearError, reset } = Reducer_System_TransactionToOrder.actions;

// Reducer
export default Reducer_System_TransactionToOrder.reducer;
