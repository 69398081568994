import { Frame, Modal, TextContainer, TextField } from '@shopify/polaris';
import { useAppDispatch } from 'config/store';
import React, { memo, useCallback, useEffect, useState } from 'react'
import { addEntityToStock } from 'store/product.store.reducer';
import { validateNumber } from 'utils';
import helpers from "../../../helpers";

const ModalSetQuantity = (props) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState('');
  const { active , setActive, stock_id , product_id} = props
  const [isError, setIsError] = useState(false)

  const handleChange = (value) => {
      setValue(value)
      if (!validateNumber(value)){
        setIsError(true)
      }else{
        setIsError(false)
      }
  }

  const onAction = () => {
    const dataProductAdd = {
      product_id: product_id,
      stock_id: stock_id,
      product_quantity: +value
    }
    dispatch(addEntityToStock(dataProductAdd))
    .then(() => {
      setValue('')
      setActive(false)
    })
  }

  useEffect(() => {
    !active && setIsError(false)
  },[active])

  return (
    <div>
      <Frame>
        <Modal
          open={active}
          onClose={() => {
            setActive(false);
          }}
          title={'Thêm sản phẩm vào kho'}
          secondaryActions={[
            {
              disabled: isError,
              content:  'Lưu thông tin',
              onAction: () => onAction(),
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <TextField
                value={value}
                onChange={(e) => handleChange(e)}
                autoComplete="off"
                label={'Số lượng sản phẩm'}
                error={isError && "Giá trị không đúng"}
              />
            </TextContainer>
          </Modal.Section>
        </Modal>
      </Frame>
    </div>
  );
}

export default memo(ModalSetQuantity)