import React from "react";
import { useParams } from "react-router-dom";
import Theme404 from '../../layout/404';

import product_collection_list from './product_collection.list';
import product_list_for_collection from './product_for_collection.list'

export default function ProductCollectionIndex() {
    let useParam = {} as any;
    useParam = useParams();
    let Param = useParam.slug || 'list';
    let ActualPage: any;
    switch (Param) {

        case 'list':
            ActualPage = product_collection_list;
            break;
        case 'detail':
            ActualPage = product_list_for_collection;
            break;

        default:
            ActualPage = Theme404;
            break;
    }

    return (
        <>
            {<ActualPage />}
        </>
    );
}
