import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { IToDoList} from '../interface/to_do_list.model';


/**
*   Reducer used for front-end, with to_do_list.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using to_do_list.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null as any,
  entities: null,
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'todo';

// Actions

export const getEntities = createAsyncThunk('todo/fetch_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return await axios.get<any>(requestUrl);
});


export const getEntity = createAsyncThunk(
  'todo/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return await axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'todo/create_entity',
  async (entity: IToDoList, thunkAPI) => {
    const result = await axios.post<IToDoList>(`${apiUrl}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'todo/update_entity',
  async (entity: IToDoList, thunkAPI) => {
    let todoID = String(entity.todo_id);
    delete entity.todo_id;
    // patch
    const result = await axios.patch<IToDoList>(`${apiUrl}/${todoID}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'todo/partial_update_entity',
  async (entity: IToDoList) => {
    return await axios.patch<IToDoList>(`${apiUrl}/${entity.todo_id}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const silentUpdateEntities = createAsyncThunk(
  'todo/update_many_entities',
  async (entity: any ) => {
    return await axios.patch<any>(`${apiUrl}/bulk_update`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

// ok
export const deleteEntity = createAsyncThunk(
  'todo/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = axios.delete<any>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_To_Do_List = createEntitySlice({
  name: 'todo',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    }
  },
  extraReducers(builder) {
    builder

      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })

      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(createEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = action.payload
      })

      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isRejected(createEntity, updateEntity, partialUpdateEntity, deleteEntity), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.payload;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        //state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(deleteEntity), state => {
        state.updating = true;
        //state.loading = true;
        state.updateSuccess = false;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { clearError } = Reducer_To_Do_List.actions;

// Reducer
export default Reducer_To_Do_List.reducer;
