import React, { useEffect } from "react";
import {Link, FormLayout, Modal, TextField, Form, Text, ProgressBar, InlineStack, Button, Toast} from '@shopify/polaris';
import {useState, useCallback} from 'react';
import helpers from 'helpers';
import { useAppDispatch, useAppSelector } from "config/store";
import {
    clearError,
    logout,
  } from "store/user.store.reducer";
import { useNavigate } from "react-router-dom";
import __ from "languages/index";


import {
    ViewIcon,
    HideIcon
} from '@shopify/polaris-icons';
import { setUserNewPassword } from "store/users.store.reducer";


/**
 * ChangePasswordModal = dành cho chính người đấy!
 * @param param0 
 * @returns 
 */
export default function ChangePasswordModal({onClose, userID, show}) {

    const updateSuccess = useAppSelector(state => state.user.updateSuccess);
    const dispatch = useAppDispatch();
    const [viewPasswordMode, setViewPasswordMode] = useState(false);
    const [passwordField1, setPasswordField1] = useState('');
    const [passwordField2, setPasswordField2] = useState('');
    const [errorInRepeatField, setErrorInRepeatField] = useState(false);
    const [errorInMainField, setErrorInMainField] = useState<boolean | string>(false);
    const [buttonLoading, setButtonLoading] = useState(false);

const generatePasswordAuto = () => {
    let autoPass = helpers.getRandomHash(12);
    setPasswordField1(autoPass);
    setPasswordField2(autoPass);
}

useEffect( () => {
    
    if ( !passwordField2 ) return;
    if ( passwordField2 !== passwordField1 ) {
      setErrorInRepeatField(true);
    } else {
      setErrorInRepeatField(false);
    }
  }, [passwordField2, passwordField1]);


  const logOutCallback = async () => {
    /** xóa app id để không dính lỗi SSO */
    localStorage.removeItem('app_id');
    localStorage.setItem('redirect', window.location.href);
    dispatch(logout());
  }

    const submitChangePassword = async () => {
        if ( errorInRepeatField || errorInMainField ) return;
        setButtonLoading(true);
        await dispatch(
            setUserNewPassword({
                password: passwordField2,
                user_id: userID
            })
        ).then(() => {
            setTimeout(() => {
                logOutCallback()
            },1000)
        });
        setButtonLoading(false);
    }
    
    const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
    }, []);

    const successNotice = updateSuccess ? (
        <Toast content={'Đổi mật khẩu thành công!'} onDismiss={clearErrorCallback} />
      ) : null;


    useEffect( () => {
        if ( updateSuccess ) onClose();
    }, [updateSuccess]);
  
    return (
        <>
            {successNotice}
            <Modal
                open={show}
                onClose={onClose}
                title="Đổi mật khẩu"
                primaryAction={{
                    content: "Đổi mật khẩu",
                    disabled: (errorInMainField || errorInRepeatField || helpers.getPasswordStrength(passwordField1) < 3 ) ? true : false,
                    loading: buttonLoading,
                    onAction: submitChangePassword
                }}
                secondaryActions={[
                    {
                        content: "Hủy",
                        onAction: onClose
                    }
                ]}
            >
                <Modal.Section>
                    <Form onSubmit={submitChangePassword}>
                    <FormLayout>
                    <TextField
                    label={__('register_form_your_password_label')}
                    type={viewPasswordMode ? "text" : "password"}
                    value={passwordField1}
                    error={errorInMainField}
                    onChange={(e) => setPasswordField1(e)}
                    suffix={(
                    <InlineStack blockAlign="center">
                        <Button variant="plain" onClick={() => setViewPasswordMode(!viewPasswordMode) } icon={viewPasswordMode ? ViewIcon : HideIcon }></Button>
                    </InlineStack>
                    )}
                    autoComplete="off"
                    helpText={
                        <>
                        <br />
                        {__( helpers.getPasswordStrengthContext( helpers.getPasswordStrength(passwordField1) ) ) }
                        <ProgressBar progress={helpers.getPasswordStrength(passwordField1) * 20} tone={ helpers.getPasswordStrength(passwordField1) < 4 ? 'critical' : 'success'} size="small" />
                        </>
                    }
                    />
                    <TextField
                        label={__('register_form_your_password_retype_label')}
                        value={passwordField2}
                        type={viewPasswordMode ? "text" : "password"}
                        error={errorInRepeatField}
                        onChange={(e) => setPasswordField2(e)}
                        autoComplete="off"
                    />
                        
                    </FormLayout>
                    <div style={{padding: '15px 0'}}>
                        <Link onClick={generatePasswordAuto}>Tạo mật khẩu</Link> tự động.
                    </div>

                    <Text as="p" tone="caution">Sau khi thay đổi mật khẩu thành công, bạn sẽ phải đăng nhập lại!</Text>
                    </Form>
                </Modal.Section>
            </Modal>
            
        </>
    )
}