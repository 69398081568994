import {
    FormLayout,
    Modal,
    TextField,
    Form,
    Select,
    Toast,
  } from "@shopify/polaris";
  import { useCallback, useEffect, useState } from "react";
  import { useAppDispatch, useAppSelector } from "config/store";
  import helpers from 'helpers/index';
  import {
    useField,
    useForm,
  } from "@shopify/react-form";
  import {
    clearError, 
    createEntity,
  } from "../../store/transactions.store.reducer";
import QuickSearchUser from "components/quickSearchUser";


  export default function TransactionNewModal(
    {onClose, moneyQuantity, default_customer, order_pnr, project_pnr}: 
    {onClose: Function, moneyQuantity?: string, default_customer?: string, order_pnr?: string, project_pnr?: string}
    ) {

    const entity = useAppSelector((state) => state.transactions.entity);
    const updating = useAppSelector((state) => state.transactions.updating);
    const loading = useAppSelector((state) => state.transactions.loading);
    const errorMessage = useAppSelector((state) => state.transactions.errorMessage);
    const updateSuccess = useAppSelector((state) => state.transactions.updateSuccess);  

    const dispatch = useAppDispatch();

    
      const [selectedTransactionMethod, setSelectedTransactionMethod] = useState('CASH');
      const handleSelectChange = useCallback(
        (value: string) => setSelectedTransactionMethod(value),
        [],
      );
    
    const {
      fields,
      submit,
      submitting,
      dirty,
      reset,
      submitErrors,
      makeClean,
    } = useForm({
      fields: {
        transaction_value: useField<string>({
          value:  helpers.formatNumberWithCommas(moneyQuantity) || '',
          validates: [
            (inputVal) => {
              if ( ! inputVal || inputVal === '' ) {
                return 'Trường này không được để trống.';
              }
            }
          ],
        }),
  
        transaction_note: useField<string>({
          value:  '',
          validates: [],
        }),
  
        transaction_ref: useField<string>({
          value:  '',
          validates: [],
        }),
  
        customer_id: useField<string>({
          value:  default_customer ?? '',
          validates: [],
        }),

      },
  
      async onSubmit(values) {
        try {
          // create new
            dispatch(
              createEntity({
                customer_id: values.customer_id,
                order_pnr: order_pnr,
                project_pnr: project_pnr,
                transaction_value: helpers.parseNumeric(values.transaction_value),
                transaction_note: values.transaction_note,
                transaction_method: selectedTransactionMethod,
                transaction_ref: values.transaction_ref,
              })
            );
          return { status: "success" };
        } catch (e: any) {
          console.error(`Submit error`, e);
          const message =
            e?.response?.data?.title ?? "Undefined error. Try again!";
          const field = e?.response?.data?.errorKey ?? "base";
          return { status: "fail", errors: [{ field, message }] };
        }
      },
    });

    /* End form */
  
    useEffect( () => {
        reset();
    }, []);



    
  /**
   * Toast here meet an error ...
   */
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const toastMarkup = showSuccessNotification ? (
    <Toast content={'Thành công! Tiếp tục nào...'} onDismiss={() => { setShowSuccessNotification(false); }} />
  ) : null;

  
    useEffect( () => {
      if ( updateSuccess ) {
        dispatch( clearError() );
        reset();
        setShowSuccessNotification(true);
      }
    }, [updateSuccess]);



  
    return (
      <>
      {toastMarkup}
      <Modal
        open={true}
        onClose={() => onClose()}
        title={"Thêm giao dịch mới"}
        primaryAction={{
          content: "Lưu lại",
          disabled: !dirty,
          loading: updating,
          onAction: submit,
        }}
        secondaryActions={[
          {
            content: "Đóng",
            onAction: () => onClose(),
          }
        ]}
      >
        <Modal.Section>
          <Form onSubmit={submit}>
              <FormLayout>

                <Select
                  label="Phương thức nhận tiền"
                  options={[
                      {label: 'Tiền mặt', value: 'CASH'},
                      {label: 'Chuyển khoản', value: 'BANK'},
                      {label: 'Bên thứ 3', value: 'STORE'},
                  ]}
                  onChange={handleSelectChange}
                  value={selectedTransactionMethod}
                />

                <QuickSearchUser title="Ghi có cho khách hàng" user_role="customer" current_user_id={default_customer} onClose={(a) => fields.customer_id.onChange(String(a.user_id)) } />

                <TextField
                  label="Số tiền"
                  autoFocus
                  requiredIndicator
                  autoComplete="off"
                  value={ helpers.formatNumberWithCommas( fields.transaction_value.value ) }
                  onChange={ (a) => fields.transaction_value.onChange(a) }
                  error={ fields.transaction_value.error }
                />  

                <TextField
                  label="Ghi chú"
                  autoComplete="off"
                  showCharacterCount
                  multiline={2}
                  {...fields.transaction_note}
                />

  
                <TextField
                  label="transaction_ref"
                  autoComplete="off"
                  {...fields.transaction_ref}
                />

              </FormLayout>
          </Form>
          <br />          
        </Modal.Section>
      </Modal>
      </>
    );


  }
  