import { Button, Form, TextField } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";


export default function NoteComponent({label, default_string, onSave, args = {} }: {label: string, default_string: string, onSave: (value: string, args: any) =>  void, args: any}) {
    const [notyValue, setNotyValue] = useState( default_string || ''); /** Dùng chung ... */
    const [loading, setLoading] = useState(false);
    useEffect( () => {
        setLoading(false);
    }, []);

    const savingFormCallback  = useCallback( () => {
        setLoading(true);
        onSave(notyValue, args);
    }, [notyValue, args]);

    return (
        <>
        <Form onSubmit={savingFormCallback}>
            <TextField
              label={label}
              multiline={3}
              maxLength={500}
              disabled={loading}
              showCharacterCount
              value={notyValue}
              onChange={(val) => setNotyValue(val)}
              autoComplete="off"
            /><br />
            <Button onClick={savingFormCallback} loading={loading} variant="plain" size="medium">Lưu lại</Button>
          </Form>
        </>
    )
}