import { BlockStack, InlineGrid, Layout, LegacyCard, Select, SelectOption, TextField } from "@shopify/polaris";
import { Field } from "@shopify/react-form";
import SelectMultiBox from "components/selectMultiBox";
import { useAppDispatch, useAppSelector } from "config/store";
import { SHOW_PRODUCT } from "constant";
import __ from "languages/index";
import LayoutChildrenProduct from "layout/layout.infomation.product";
import { memo, useEffect, useState } from "react";
import { getCategories } from "store/product_category.store.reducer";
import { getCollections } from "store/product_collection.store.reducer";
import helpers from "../../../helpers";

interface TypedProps {
    fieldProductName: Field<string>;
    fieldCollection: Field<string[]>;
    fieldProductCategory: Field<string[]>;
    fieldProductUnit: Field<string>;
    fieldProductCode: Field<string>;
    fieldOriginalPrice: Field<string>;
    fieldProductStatus: Field<string>;
    fieldProductWidth: Field<string>;
    fieldProductWeight: Field<string>;
    fieldProductHeight: Field<string>;
    fieldProductLength: Field<string>;
    fieldVat?: Field<string>;
    setStockProduct?: Function
    stockProducts
    commissionValues?: { group_id: string, price: string, price_percent: string | null }[]
    setCommissionValues?: Function
    commissionErrors?: { group_id: string, error: string | null }[]
    setCommissionErrors?: Function
    dirty?: boolean
};

const InfomationGeneral = (props: TypedProps) => {
    const dispatch = useAppDispatch();
    const { fieldProductName,
        fieldCollection,
        fieldProductCategory,
        fieldProductUnit,
        fieldProductCode,
        fieldOriginalPrice,
        fieldProductStatus,
        fieldProductWidth,
        fieldProductWeight,
        fieldProductHeight,
        fieldProductLength,
        setStockProduct,
        stockProducts,
        fieldVat,
        commissionValues,
        setCommissionValues,
        commissionErrors,
        setCommissionErrors,
        dirty
    } = props;

    const [optionCategories, setOptionCategories] = useState<SelectOption[]>([])
    const [optionTypeProduct, setOptionTypeProduct] = useState<SelectOption[]>([])
    const entitiesUserGroup = useAppSelector((state) => state.group_user.entities);

    const handleCommissionChange = (groupId: string, newValue: string) => {
        setCommissionValues(prevValues => {
            const existingEntry = prevValues?.find(entry => entry.group_id === groupId);
            if (existingEntry) {
                return prevValues?.map(entry =>
                    entry.group_id === groupId ? { ...entry, price: newValue } : entry
                );
            } else {
                return [...prevValues, { group_id: groupId, price: newValue, price_percent: null }];
            }
        });

        setCommissionErrors(prevErrors => {
            const existingError = prevErrors?.find(error => error?.group_id === groupId);

            if (!helpers.parseNumeric(newValue)) {
                if (existingError) {
                    return prevErrors?.map(error =>
                        error?.group_id === groupId ? { ...error, error: __('require') } : error
                    );
                } else {
                    return [...prevErrors, { group_id: groupId, error: __('require') }];
                }
            } else {
                return prevErrors?.filter(error => error?.group_id !== groupId);
            }
        });
    };

    //call api list type, category, distributor of product here
    useEffect(() => {

        dispatch(getCategories({})).unwrap().then((res) => {
            if (Array.isArray(res.data)) {
                const data = res.data.map(i => ({
                    value: i.category_id,
                    label: i.category_name
                }))
                setOptionCategories(data);
            }
        }).catch((e) => {
            console.log("E125 >>>>", e);
        });


        dispatch(getCollections({})).unwrap().then((res) => {

            if (Array.isArray(res.data)) {
                const data = res.data.map(i => ({
                    value: i.collection_id,
                    label: i.collection_name
                }));

                setOptionTypeProduct(data)
            }

        }).catch((e) => {
            console.log("E123 >>>>", e);
        })

    }, []);


    // Số lượng trong kho
    const handleChange = (newValue: string, item: any) => {
        setStockProduct(stockProducts.map(val => {
            if (val.stock_id === item.stock_id) {
                return {
                    ...item,
                    product_quantity: newValue
                }
            } else {
                return val
            }
        }));

    }

    return (
        <LayoutChildrenProduct title={'infomation_general'}>
            <BlockStack gap={'400'}>
                <TextField
                    autoComplete="off"
                    autoFocus
                    label={'Tên sản phẩm'}
                    {...fieldProductName}
                />

                <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2 }} gap="300">
                    <TextField
                        autoComplete="off"
                        label={'Mã sản phẩm'}
                        {...fieldProductCode}
                    />

                    <TextField
                        label={'Đơn vị tính'}
                        autoComplete="off"
                        {...fieldProductUnit}
                    />
                </InlineGrid>

                <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2 }} gap="300">
                    <TextField
                        label={'Giá gốc sản phẩm'}
                        suffix="vnđ"
                        maxLength={12}
                        type={'text'}
                        autoComplete="off"
                        value={helpers.formatNumberWithCommas(fieldOriginalPrice.value)}
                        onChange={(a) => fieldOriginalPrice.onChange(a)}
                        error={fieldOriginalPrice.error}
                    />
                    <TextField
                        label={'VAT'}
                        suffix="%"
                        type={'text'}
                        autoComplete="off"
                        value={helpers.formatNumberWithCommas(fieldVat.value)}
                        onChange={(a) => fieldVat.onChange(a)}
                        error={fieldVat.error}
                    />
                </InlineGrid>
                <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 3 }} gap="300">
                    {
                        entitiesUserGroup.length > 0 && (
                            entitiesUserGroup.map((item) => {
                                const commissionValue = commissionValues?.find(value => value?.group_id === item?.group_id);
                                const commissionError = commissionErrors?.find(error => error?.group_id === item?.group_id);
                                return (
                                    <TextField
                                        key={item.group_id}
                                        label={`Hoa hồng ${item.group_name.toLowerCase()}`}
                                        name={item.group_id}
                                        suffix="vnđ"
                                        type={'text'}
                                        autoComplete="off"
                                        value={commissionValue ? helpers.formatNumberWithCommas(commissionValue?.price) : ''}
                                        onChange={(value) => handleCommissionChange(item.group_id, helpers.formatNumberWithCommas(value))}
                                        error={commissionError ? commissionError.error : null}
                                    />
                                )
                            })
                        )

                    }
                </InlineGrid>

                <Layout>
                    <Layout.Section>
                        <LegacyCard title="Chọn kho cho sản phẩm" sectioned>
                            {
                                stockProducts?.map((item, index) => {
                                    return (
                                        <div key={index} style={{ paddingTop: 20 }}>
                                            <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
                                                <span>{item.stock_name}</span>
                                                <TextField
                                                    label={''}
                                                    suffix="Số lượng"
                                                    value={item.product_quantity ? helpers.formatNumberWithCommas(item.product_quantity) : ''}
                                                    autoComplete="off"
                                                    onChange={e => handleChange(e, item)}
                                                />
                                            </InlineGrid>
                                        </div>
                                    )
                                })
                            }
                        </LegacyCard>
                    </Layout.Section>
                </Layout>

                <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2 }} gap="300">
                    <TextField
                        label={'Chiều dài sản phẩm'}
                        type="number"
                        suffix="cm"
                        autoComplete="off"
                        min={0}
                        {...fieldProductLength}
                    />
                    <TextField
                        label={'Chiều rộng sản phẩm'}
                        type="number"
                        suffix="cm"
                        autoComplete="off"
                        min={0}
                        {...fieldProductWidth}
                    />
                </InlineGrid>

                <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2 }} gap="300">
                    <TextField
                        label={'Khối lượng sản phẩm'}
                        type="number"
                        suffix="gr"
                        autoComplete="off"
                        min={0}
                        {...fieldProductWeight}
                    />
                    <TextField
                        label={'Chiều cao sản phẩm'}
                        type="number"
                        suffix="cm"
                        autoComplete="off"
                        min={0}
                        {...fieldProductHeight}
                    />
                </InlineGrid>

                <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 1 }} gap="300">
                    <SelectMultiBox current_value={[...fieldProductCategory.value]} field={fieldProductCategory} label={'Danh mục sản phẩm'} dataSelect={optionCategories}/>
                </InlineGrid>

                <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 1 }} gap="300">
                    <SelectMultiBox label={'Bộ sưu tập sản phẩm'} current_value={[...fieldCollection.value]} dataSelect={optionTypeProduct} field={fieldCollection}/>
                </InlineGrid>

                <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 1 }} gap="300">
                    <Select
                        label={'Hiển thị'}
                        options={[{
                            value: '',
                            label: ''
                        }
                            , {
                            value: SHOW_PRODUCT.SHOW,
                            label: 'Hiển thị'
                        }, {
                            value: SHOW_PRODUCT.HIDDEN,
                            label: 'Không hiển thị'
                        }]}
                        value={fieldProductStatus.value}
                        onChange={(e) => fieldProductStatus.onChange(e)}
                        {...fieldProductStatus}
                    />
                </InlineGrid>
            </BlockStack>

        </LayoutChildrenProduct>
    )

}

export default memo(InfomationGeneral);
