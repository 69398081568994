import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createEntitySlice, serializeAxiosError } from 'config/reducer.utils';
import helpers from 'helpers/index';

export type TypedPhoneCall = {
  phonelog_id?: string,
  phonelog_number?: string,
  phonelog_source?: string,
  user_id?: string,
  createdAt?: string,
  user: any
}
/**
*   Reducer used for front-end, with settings.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null,
  entities: null as TypedPhoneCall[],
  entity: null as TypedPhoneCall,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'phonelog';


export const getEntities = createAsyncThunk('phonelog/Reducer_phonelog', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return await axios.get<any>(requestUrl);
});

export const updateEntity = createAsyncThunk(
  'phonelog/update_entity',
  async (entity: any, thunkAPI) => {
    // patch
    const result = await axios.patch<any>(`${apiUrl}/${entity.phonelog_id}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);


// slice

export const Reducer_phonelog = createEntitySlice({
  name: 'phonelog',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
        state.errorMessage = null;
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateEntity.rejected, (state, action) => {
        state.updating = false;
        state.errorMessage = action.error.message;
      })
      .addCase(updateEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.entity = action.payload.data;
        state.errorMessage = null;
      })
      .addCase(updateEntity.pending, (state, action) => {
        state.updating = true;
      })
  },
});

export const { clearError } = Reducer_phonelog.actions;
// Reducer
export default Reducer_phonelog.reducer;
