import { useCallback } from "react";
import { useAppSelector } from "config/store";
import { useNavigate, useParams } from "react-router-dom";
import { Page } from "@shopify/polaris";
import 'media/css/login.scss'
import loginLogo from 'assets/images/logoLogin.png';
import __, { ___ } from "languages/index";
import { IDENTIFY } from "assets";
import Analytics from "entities/analytics";

  /************************************************************ *
   * MAINN
   * Private route
   ************************************************************ */
  
  export default function Dashboard() {

    const currentUserData = useAppSelector((state) => state.user.account);
    const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
    const history = useNavigate();

      /**
       * If user apply filter, it will add to URL, then parse URL back to initial state
       */
      let useParam = {} as any;
          useParam = useParams();

    const loginRedirect = useCallback( () => {
      /** Xóa app ID để không bị dính lỗi SSO */
      localStorage.removeItem('app_id');
      history('/login');
    }, []);
  
    return (
      <>
        {isAuthenticated && <Analytics/>}
        <Page>
          <div className="wrapper">
            {
              !isAuthenticated && (
                <div className="wrapLogin">
                  <div className="box-column-center">
                    <img src={loginLogo} style={{ width: "50%" }} alt="" className="imageAfterUploadPlaceholder" />
                    <p className="btnTitleLogin">{IDENTIFY.TITLE}</p>
                    <button className="btnLogin" onClick={loginRedirect}>{__('btnLogin')}</button>
                  </div>
                </div>
              )
            }
          </div>
        </Page>
        <br />
      </>
    );
  }
  