import React, { useCallback, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../config/store';
import { logout } from '../store/user.store.reducer';
import TodoQuickAdd from 'components/todo_quick_add';

import Sidebar from '../config/sidebar';
import { IDENTIFY } from 'assets';
import Logo from 'assets/images/logo.png';
import Hotkeys from 'react-hot-keys';
import GlobalProgressingBar from "components/GlobalProgressingBar";

import {
  ActionList,
  Frame,
  Toast,
  Icon,
  TopBar,
} from '@shopify/polaris';
import {
  ExitIcon,
  ProfileIcon,
  PhoneInIcon,
  ChatIcon,
  NotificationIcon
} from '@shopify/polaris-icons';
import SidebarPopup from 'components/sidebarPopup';
import PhoneCallLog from './phoneCall';
import SMSLog from './smsLog';
import NotificationLog from './notificationLog';
import {  clearError, clearNormalMessage } from "store/global.warning.store.reducer";
import helpers from 'helpers/index';
import __ from 'languages/index';

// rootReducer.js

export default function AppFrame({children, hasSidebar = true}: {children: any, hasSidebar?: boolean}) {

  const loading = useAppSelector((state) => state.global_process_bar.loading);

  /**
   * Check login ...
   */
   const dispatch = useAppDispatch();

   const skipToContentRef = useRef(null);

   /**
    * Global error message, and show toast
    */
   const errorMessage = useAppSelector((state) => state.global_notification.errorMessage);
   const normalMessage = useAppSelector((state) => state.global_notification.normalMessage);

  const account = useAppSelector(state => state.user.account);
  const isAuthenticated = useAppSelector(state => state.user.isAuthenticated);

  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const [openQuickTodos, setOpenQuickTodos] = useState<boolean>(false);

  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue('');
  }, []);
  const handleSearchFieldChange = useCallback(value => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);


  const toggleUserMenuActive = useCallback(() => setUserMenuActive(_userMenuActive => !_userMenuActive), []);
  const toggleMobileNavigationActive = useCallback(
    () => setMobileNavigationActive(_mobileNavigationActive => !_mobileNavigationActive),
    []
  );

  const logOutCallback = async () => {
    /** xóa app id để không dính lỗi SSO */
    localStorage.removeItem('app_id');
    localStorage.setItem('redirect', window.location.href);
    dispatch(logout());
  }


  const userMenuMarkup = !helpers.isEmpty(account) ? (
      <TopBar.UserMenu
        actions={[
          {
            items: [
              { content: __('my_profile'), url: '/profile', icon: ProfileIcon },
            ]
          },
          {
            items: [{ content: __('logout'), icon: ExitIcon, onAction:  () => logOutCallback()    }],
          }
        ]}
        name={account?.display_name || account?.user_email || account?.user_login}
        detail={account?.user_email}
        initials={String(account?.display_name || account?.user_email || account?.user_login || 'unknown').charAt(0)}
        open={userMenuActive}
        avatar={account?.user_avatar}
        onToggle={toggleUserMenuActive}  
      />
      
    ) : (
      <TopBar.UserMenu 
        actions={[
          // {
          //   items: [
          //     { content: __('login'), url: '/login?app_id=unset', icon: ExitIcon },
          //     { content: __('register'), url: '/register?app_id=unset', icon: ProfileIcon },
          //   ]
          // }
        ]} 
        name={__('welcome')} initials="" open={userMenuActive} onToggle={toggleUserMenuActive}
       />
    );

  const searchResultsMarkup = <ActionList items={[{ content: 'Shopify help center' }, { content: 'Community forums' }]} />;

  const searchFieldMarkup = <TopBar.SearchField onChange={handleSearchFieldChange} value={searchValue} placeholder="Search" />;

/**
 * Nút show bẹn website
 */
  const [showPhoneCallLog, setShowPhoneCallLog] = useState(false);
  const [showSMSLog, setShowSMSLog] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
 const secondaryMenuMarkup = (
    <TopBar.Menu
    key="OpenPhoneBook"
      activatorContent={
        <span>
          <Icon source={PhoneInIcon} />
        </span>
      }
      open={false}
      actions={[]}
      onOpen={ () => setShowPhoneCallLog(true) }
      onClose={()=>{}}
    />
  );


 const smsSidebarMenuActivator = (
    <TopBar.Menu
    key="OpenSMS"
      activatorContent={
        <span>
          <Icon source={ChatIcon} />
        </span>
      }
      open={false}
      actions={[]}
      onOpen={() => {
        setShowSMSLog(true);
      }}
      onClose={()=>{}}
    />
  );

 const notificationSidebarMenuActivator = (
    <TopBar.Menu
    key="OpenNotification"
      activatorContent={
        <span>
          <Icon source={NotificationIcon} />
        </span>
      }
      open={false}
      actions={[]}
      onOpen={() => setShowNotification(true)}
      onClose={()=> alert('close notification')}
    />
  );



  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      searchResultsVisible={searchActive}
      searchField={ isAuthenticated && searchFieldMarkup}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
      secondaryMenu={ isAuthenticated ? [
        // secondaryMenuMarkup, smsSidebarMenuActivator,
         notificationSidebarMenuActivator
      ] : [] }
    />
  );

  const logo = {
    width: IDENTIFY.WIDTH_LOGO,
    topBarSource: Logo,
    url: '/',
    accessibilityLabel: IDENTIFY.ACCESSI_BILITY_LABEL,
  }



/** Global error notification ... */
  const clearErrorMessage = useCallback( () => {
    dispatch( clearError() )
  }, []);

  const toastErrorMarkup = errorMessage ? (
    <Toast content={errorMessage} error onDismiss={clearErrorMessage} duration={5000} />
  ) : null;
/** Global notification notification ... */
  const _clearNormalMessage = useCallback( () => {
    dispatch( clearNormalMessage() )
  }, []);
  
  const toastNotificationMarkup = normalMessage ? (
    <Toast content={normalMessage} onDismiss={_clearNormalMessage} duration={5000} />
  ) : null;

  return (
    <>
      <GlobalProgressingBar show={loading} />
      <Frame
        topBar={topBarMarkup}
        logo={logo}
        navigation={ hasSidebar && ( isAuthenticated && <Sidebar />) }
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        skipToContentTarget={skipToContentRef.current}
      >
        {toastNotificationMarkup}
        {toastErrorMarkup}
        {
          isAuthenticated && (
            <>

              {/* <SidebarPopup title="Phone call" show={showPhoneCallLog} onClose={() => setShowPhoneCallLog(false)}>
                <PhoneCallLog show={showPhoneCallLog} />
              </SidebarPopup>
              
              <SidebarPopup title="SMS log" show={showSMSLog} onClose={() => setShowSMSLog(false)}>
                <SMSLog show={showSMSLog} />
              </SidebarPopup> */}

              <SidebarPopup title="Thông báo" show={showNotification} onClose={() => setShowNotification(false)} >
                <NotificationLog show={showNotification} />
              </SidebarPopup>

              <Hotkeys 
                keyName="shift+c" 
                onKeyDown={(keyname: any, event: any, _handler)=> {
                  event.preventDefault();
                  setOpenQuickTodos(true);
                }}
              />
              
            </>
          )
        }

          {
            openQuickTodos && isAuthenticated ? (
              <TodoQuickAdd prefill="" show={true} onClose={() => setOpenQuickTodos(false)} />
            ) : null
          }

        
        { children }
      </Frame>
    </>
  );
};