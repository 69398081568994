import { createAsyncThunk, isFulfilled, isPending } from "@reduxjs/toolkit";
import axios from "axios";
import { createEntitySlice, serializeAxiosError } from "config/reducer.utils";
import { ENDPOINT_URL } from "constant";
import __helpers from "helpers/index";
import { TypedGroupUser, TypedUserInGroup } from "interface/group_user.model";


const initialState = {
  loading: false,
  errorMessage: null as any,
  entities: [] as TypedGroupUser[],
  members: [] as TypedUserInGroup[],
  entity: {} as TypedGroupUser,
  membersUnapproved: [] as TypedGroupUser[],
  totalItemMemebersUnapproved: 0,
  updating: false,
  totalItems: 0,
  totalItemMemebers: 0,
  updateSuccess: false,
};

const apiUrl = `${ENDPOINT_URL}/user_group`

export const getEntities = createAsyncThunk('group_user/fetch_entity_list', async (object: any) => {
  const EndURL = __helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return axios.get<TypedGroupUser[]>(requestUrl);
},
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk('group_user/fetch_entity', async (group_id: string) => {
  const requestUrl = `${apiUrl}/${group_id}`;
  return axios.get<TypedGroupUser>(requestUrl);
},
  { serializeError: serializeAxiosError }
);

export const getEntitiesUnapproved = createAsyncThunk('group_user/fetch_entity_unapproved_list', async (object: any) => {
  const EndURL = __helpers.buildEndUrl(object);
  const requestUrl = `${ENDPOINT_URL}/user_to_group/unapproved${EndURL}`;
  return axios.get<TypedGroupUser[]>(requestUrl);
},
  { serializeError: serializeAxiosError }
);

export const createEntities = createAsyncThunk(
  'group_user/create_entity',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<TypedGroupUser>(`${apiUrl}`, __helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}))
    return result;
  }
);

export const updateEntities = createAsyncThunk(
  'group_user/update_entity',
  async (entity: any, thunkAPI) => {
    const groupId = entity.group_id;
    delete entity.group_id;
    const result = await axios.patch<TypedGroupUser>(`${apiUrl}/${groupId}`, __helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}))
    return result;
  }
);

export const deleteEntity = createAsyncThunk(
  'group_user/delete_entity',
  async (entity: { group_id: string }, thunkAPI) => {
    const result = await axios.delete<TypedGroupUser>(`${apiUrl}/${entity.group_id}`);
    thunkAPI.dispatch(getEntities({}))
    return result;
  }
);

export const getListMembers = createAsyncThunk(
  'group_user/memeber',
  async ({ group_id, entity }: {
    group_id: string;
    entity: any
  }) => {
    const EndURL = __helpers.buildEndUrl(entity);
    const requestUrl = `${apiUrl}/${group_id}/members${EndURL}`;
    return axios.get<TypedUserInGroup[]>(requestUrl);
  }
);

export const deleteUserInGroup = createAsyncThunk(
  'group_user/delete_user_in_group',
  async (entity: { user_group_id: string }) => {
    const result = await axios.delete<TypedGroupUser>(`${apiUrl}/assign/${entity.user_group_id}`);
    return result;
  }
);

export const assignUserToGroup = createAsyncThunk(
  'group_user/assign_user_to_group',
  async (entity: {
    user_id: string;
    group_id: string;
  }) => {
    const result = await axios.post<TypedGroupUser>(`${apiUrl}/assign`, __helpers.cleanEntity(entity));
    return result;
  }
);

export const changeUserToGroup = createAsyncThunk(
  'group_user/change_user_to_group',
  async (entity: {
    user_id: string;
    group_id: string;
  }) => {
    const result = await axios.post<TypedGroupUser>(`${apiUrl}/upgrade`, __helpers.cleanEntity(entity));
    return result;
  }
);

export const groupUser = createEntitySlice({
  name: 'group_user',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEntitiesUnapproved.fulfilled, (state, action) => {
        state.membersUnapproved = action.payload.data;
        state.loading = false;
        state.totalItemMemebersUnapproved = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(getListMembers.fulfilled, (state, action) => {
        state.members = action.payload.data;
        state.loading = false;
        state.totalItemMemebers = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.entity = action.payload.data;
        state.loading = false;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getEntities, getListMembers, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.totalItems = 0;
        state.totalItemMemebers = 0;
        state.loading = true;
        state.entity = null;
      })
  }
});

export const { clearError, reset } = groupUser.actions;

// Reducer
export default groupUser.reducer;

