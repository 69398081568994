import {
  Toast,
} from "@shopify/polaris";

import { useState, useCallback, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../config/store";
import './media/user_profile.scss';

import {
  clearError,
} from "../../store/user.store.reducer";
import helpers from "../../helpers";
import { useParams } from "react-router-dom";
import ProfileEditModal from './profile.edit.modal';
import ChangePasswordModal from './profile.change.password.modal';
import { IUser } from "interface/user.model";
import ProfileHeader from "./profile-header";

/************************************************************ *
 * MAINN
 * Private route
 ************************************************************ */

export default function MyProfile() {

  const currentUserData = useAppSelector((state) => state.user.account);
  const entity = useAppSelector((state) => state.user.entity);
  const updateSuccess = useAppSelector((state) => state.user.updateSuccess);

  const dispatch = useAppDispatch();

  const [profileData, setProfileData] = useState<IUser>(null);
  useEffect( () => {
    if ( entity ) {
      setProfileData(entity);
    }
  }, [entity])
  
/**
 * Show editor model
 */
const [showEditModel, setShowEditModel] = useState(false);
const [showPasswordModel, setShowPasswordModel] = useState(false);
  
    /**
     * If user apply filter, it will add to URL, then parse URL back to initial state
     */
    let useParam = {} as any;
        useParam = useParams();
    let Param = useParam.user_id || currentUserData?.user_id;

    useEffect( () => {
      dispatch( clearError() );
    }, []);

  /**
   * Do NOT remove, it handle login page if user not loginnn ...
   */
  
  const [internalErrorNotice, setInternalErrorNotice] = useState("");

  const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
  }, []);

  useEffect( () => {
    clearErrorCallback();
  }, []);

  const successToastMarkup = updateSuccess ? (
    <Toast content={'Cập nhật thành công!'} onDismiss={clearErrorCallback} />
  ) : null;
  
  const fullAddress = useRef('Chưa có thông tin');
  const getFullAddress = useCallback( async(profileData) => {
    try {
      if ( ! profileData?.user_address ) return;

      let ward = await helpers.getDiaChinh(profileData?.user_address_ward);
      let distric = await helpers.getDiaChinh(profileData?.user_address_district);
      let city = await helpers.getDiaChinh(profileData?.user_address_city);

      fullAddress.current = `${profileData?.user_address}, ${ward?.name}, ${distric?.name}, ${city?.name}`;
    } catch(e) {}
  }, []);
  
  useEffect( () => {
    getFullAddress(profileData);
  }, [profileData]);

  return (
    <>
    {successToastMarkup}
    {internalErrorNotice ? (
      <Toast
        content={internalErrorNotice}
        error
        onDismiss={() => setInternalErrorNotice("")}
      />
    ) : null}
    {
      <ProfileEditModal show={showEditModel} account={profileData} onClose={() => setShowEditModel(false) }/>
    }

    {
      <ChangePasswordModal show={showPasswordModel} userID={profileData?.user_id} onClose={() => setShowPasswordModel(false) }/>
    }

    <div id="staff-profile">
      <ProfileHeader current_user_id={Param} />
    </div>
    </>
  );
}
