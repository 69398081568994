import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createEntitySlice } from 'config/reducer.utils';
import helpers from 'helpers/index';

/**
*   Reducer used for front-end, with settings.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
*/

type Typedsms = {
  SMS_id?: string,
  SMS_source?: string,
  SMS_content?: string,
  SMS_numberphone?: string,
  createdAt?: string
}

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as Typedsms[],
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'sms';


export const getEntities = createAsyncThunk('smslog/fetch_entities', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return await axios.get<any>(requestUrl);
});


// slice

export const Reducer_smslog = createEntitySlice({
  name: 'smslog',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.errorMessage = null;
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true;
        state.entities = [];
      })
  },
});

export const { clearError } = Reducer_smslog.actions;
// Reducer
export default Reducer_smslog.reducer;
