import { Card, Text, IndexTable, Link, Page, useIndexResourceState, Toast, Thumbnail, Button, Tooltip, InlineStack } from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  NoteIcon,
  PlusIcon,
} from '@shopify/polaris-icons';
import {
  clearError,
  getEntitiesByStock,
} from "store/product.store.reducer";
import { useAppDispatch, useAppSelector } from "config/store";
import helpers from "../../helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "components/pagination";
import SkeletonLoading from 'components/skeletonLoading';
import __ from 'languages/index';
import SimpleFilter from 'components/simpleFilter';
import ModalSetQuantity from "./components/modalSetQuantity";

export default function StockAddProduct() {

  const entities = useAppSelector((state) => state.product.entities);
  const loading = useAppSelector((state) => state.product.loading);
  const totalItems = useAppSelector((state) => state.product.totalItems);
  const updating = useAppSelector((state) => state.product.updating);
  const updateSuccess = useAppSelector((state) => state.product.updateSuccess);
  const [showModal, setShowModal] = useState(false);
  const [productId, setProductId] = useState(null);
  const dispatch = useAppDispatch();
  const history = useNavigate();

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);



  const toastSuccessMarkup = updateSuccess ? (
    <Toast content={'Cập nhật thành công'} onDismiss={toggleActive} />
  ) : null;

  // get stock_id
  let { stock_id } = useParams()
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  const initialQuery = {
    query: "",
    page: 1,
    limit: 25,
    sort: "createdAt:desc",
  };

  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...initialQuery,
    stock_id: `!${stock_id}`
  });

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({ ...mainQuery, page: numPage, limit: limit });
  }, [mainQuery]);



  let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      setMainQuery(m);
    }
  }, []);


  /**
   * Lòng và lòng vòng, cuối cùng là để nó không gọi quá nhiều lần ...
   */
  const reduceLoad = useCallback((_mainQuery: any) => {
    dispatch(getEntitiesByStock(_mainQuery));
  }, []);

  const reducerLoading = useMemo(() => helpers.debounce(_value => reduceLoad?.call(this, _value), 900), []);

  useEffect(() => {
    reducerLoading(mainQuery);
  }, [mainQuery]);

  useEffect(()=> {
    updateSuccess && reducerLoading(mainQuery);
  },[updateSuccess])

  function getProductDetail(product_id: string) {
    history("/product/edit/" + product_id);
    return;
  }

  const resourceName = {
    singular: "sản phẩm",
    plural: "sản phẩm",
  };
  const resourceIDResolver = (products) => {
    return products.product_id;
  };
  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(entities ?? [], {
    resourceIDResolver,
  });

  const handleAddProductToStock = (product_id: string) => {
    setShowModal(true);
    setProductId(product_id)
  }

  const rowMarkup = entities?.map(
    (
      {
        product_id,
        product_media,
        product_name,
        product_price,
        product_meta
      },
      index
    ) => (
      <IndexTable.Row
        id={product_id}
        key={product_id}
        selected={selectedResources.includes(product_id)}
        position={index}
      >
        <IndexTable.Cell><Thumbnail size="small" source={Array.isArray(product_media) && product_media.length ? product_media[0] : NoteIcon} alt="" /></IndexTable.Cell>
        <IndexTable.Cell><Link removeUnderline onClick={() => getProductDetail(product_id)}>{product_name}</Link></IndexTable.Cell>
        <IndexTable.Cell><Text as="span" variant="bodyMd">{helpers.formatNumber(product_price, ".")} vnđ</Text></IndexTable.Cell>
        <IndexTable.Cell>
          <InlineStack gap={"200"} align="start" blockAlign="center">
            <Tooltip content="Thêm sản phẩm vào kho">
              <Button tone="success" onClick={() => handleAddProductToStock(product_id)} icon={PlusIcon}>
                Thêm
              </Button>
            </Tooltip>
          </InlineStack>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  return (
    <>
      {entities === null ? <SkeletonLoading fullWidth /> :
        (
          <Page
            fullWidth
            title={`Thêm sản phẩm vào kho: ${useParam.state.stockName}`}
            backAction={{
              url: useParam.state.currentPath
            }}
          >
            <Card padding="0">
              <SimpleFilter loading={loading}
                onCallback={filterCallback}
                options={[
                  // { label: 'Hết hàng', value: '0', field: 'product_meta.product_in_stock_quantity' },
                  // { label: 'Ngừng bán', value: '0', field: 'product_status' },
                  // { label: 'Đang bán', value: '1', field: 'product_status' },
                ]}
                sortField={[
                  // { label: 'Giá sản phẩm', field: 'product_price' },
                  // { label: 'Tồn kho', field: 'product_quantity' },
                  // { label: 'Đã bán', field: 'product_sold_quantity' },
                ]}
                totalItems={totalItems}
              />

              <IndexTable
                resourceName={resourceName}
                itemCount={entities?.length || 0}
                selectedItemsCount={
                  allResourcesSelected ? "All" : selectedResources.length
                }
                selectable={false}
                onSelectionChange={handleSelectionChange}
                bulkActions={[]}
                onSort={null}
                loading={updating}
                sortable={[]}
                headings={[
                  { title: "" },
                  { title: "Sản phẩm" },
                  { title: "Giá bán" },
                  { title: "Hành động" },
                ]}
              >
                {entities && rowMarkup}
              </IndexTable>
            </Card>

            <br />
            {totalItems > mainQuery.limit ? (
              <Pagination
                TotalRecord={totalItems}
                onChange={onChangePagination}
                pageSize={Number(mainQuery.limit)}
                activeCurrentPage={Number(mainQuery.page)}
              />
            ) : null}
            <ModalSetQuantity active={showModal} setActive={setShowModal} stock_id={stock_id} product_id={productId}/>
          </Page>
        )}

      {toastSuccessMarkup}
    </>
  )
}