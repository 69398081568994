import axios from 'axios';
import { createAsyncThunk, isFulfilled,  } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError,  } from '../config/reducer.utils';
import { TypedCategory } from '../interface/product.model';
import { ENDPOINT_URL } from 'constant';
import { TypedVariantProduct } from 'interface/variant.model';
import {
  getEntity as getEntityProduct,
} from "store/product.store.reducer";
import { TypeProductVariantGroup } from 'interface/product.variant.model';
/**
*   Reducer used for front-end, with media.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using media.model.ts
*/


// interface getQuery extends IQueryParams, TypedProduct{};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: null as any as TypeProductVariantGroup[],
  entity: null as TypeProductVariantGroup,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};
const apiUrlVariantGroup = `${ENDPOINT_URL}/product_variant_group`;
// const apiUrlVariantProduct = `${ENDPOINT_URL}/product_variant`;
// Actions

//get product_variant_group
export const getVariantGroup = createAsyncThunk('product_variant/fetch_entitys_product_variant_group', async () => {
  const EndURL = helpers.buildEndUrl({});
  const requestUrl = `${apiUrlVariantGroup}${EndURL}`;
  return axios.get<TypeProductVariantGroup[]>(requestUrl);
});

// create product_variant_group
export const createVariantGroup = createAsyncThunk(
  'product_variant/create_product_variant_group',
  async (entity:any, thunkAPI) => {
    const result = await axios.post<TypedVariantProduct>(`${ENDPOINT_URL}/product_variant_group`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getVariantGroup());
    return result;
  },
  { serializeError: serializeAxiosError }
);

// edit product_variant_group
export const editVariantGroup = createAsyncThunk(
  'product_variant/edit_product_variant_group',
  async (entity: any, thunkAPI) => {
    const { idVariantGroup, variantGroupName }  = entity
    const result = await axios.patch<TypedVariantProduct>(`${ENDPOINT_URL}/product_variant_group/${idVariantGroup}`, helpers.cleanEntity({variant_group_name :variantGroupName}));
    thunkAPI.dispatch(getVariantGroup());
    return result;
  },
  { serializeError: serializeAxiosError }
);

// edit product_variant_group
export const deleteVariantGroup = createAsyncThunk(
  'product_variant/delete_product_variant_group',
  async (entity: any, thunkAPI) => {
    const idVariantGroup = entity.variant_group_id
    const result = await axios.delete<TypedVariantProduct>(`${ENDPOINT_URL}/product_variant_group/${idVariantGroup}`);
    thunkAPI.dispatch(getVariantGroup());
    return result;
  },
  { serializeError: serializeAxiosError }
);

// create product_variant
export const createVariantProduct = createAsyncThunk(
  'product_variant/create_variant_product',
  async (entity:any, thunkAPI) => {
    const { product_id } = entity
    const result = await axios.post<TypedVariantProduct>(`${ENDPOINT_URL}/product_variant/${product_id}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntityProduct(entity.product_id))
    return result;
  },
  { serializeError: serializeAxiosError }
);

// edit product_variant
export const editVariantProduct = createAsyncThunk(
  'product_variant/edit_variant_product',
  async (entity:any, thunkAPI) => {
    const { variant_id, variant_name, product_id }  = entity
    const result = await axios.patch<TypedVariantProduct>(`${ENDPOINT_URL}/product_variant/${variant_id}`, helpers.cleanEntity({ variant_name: variant_name }));
    thunkAPI.dispatch(getEntityProduct(product_id))
    return result;
  },
  { serializeError: serializeAxiosError }
);


// Reducer
export const Reducer_Product_Variant = createEntitySlice({
  name: 'product_variant',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage  = null;
      state.updateSuccess  = false;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createVariantGroup.pending, (state, action) => {
        state.loading = true;
        state.updateSuccess  = false;
      })
      .addCase(createVariantGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess  = true;
      })
      .addCase(createVariantGroup.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Thêm thuộc tính không thành công";
        state.updateSuccess  = false;
      })

      .addCase(editVariantGroup.pending, (state, action) => {
        state.loading = true;
        state.updateSuccess  = false;
      })
      .addCase(editVariantGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess  = true;
      })
      .addCase(editVariantGroup.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Sửa thuộc tính không thành công";
        state.updateSuccess  = false;
      })

      .addCase(deleteVariantGroup.pending, (state, action) => {
        state.loading = true;
        state.updateSuccess  = false;
      })
      .addCase(deleteVariantGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess  = true;
      })
      .addCase(deleteVariantGroup.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Sửa thuộc tính không thành công";
        state.updateSuccess  = false;
      })

      .addCase(createVariantProduct.pending, (state, action) => {
        state.loading = true;
        state.updateSuccess  = false;
      })
      .addCase(createVariantProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess  = true;
      })
      .addCase(createVariantProduct.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Thêm thuộc tính cho sản phẩm không thành công";
        state.updateSuccess  = false;
      })

      .addCase(editVariantProduct.pending, (state, action) => {
        state.loading = true;
        state.updateSuccess  = false;
      })
      .addCase(editVariantProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess  = true;
      })
      .addCase(editVariantProduct.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Sửa thuộc tính cho sản phẩm không thành công";
        state.updateSuccess  = false;
      })

      .addMatcher(isFulfilled(getVariantGroup), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: (action.payload as any).data,
          entity: null,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
  },
});

export const { clearError, reset } = Reducer_Product_Variant.actions;

// Reducer
export default Reducer_Product_Variant.reducer;
