import {
  Frame,
  FormLayout,
  TextField,
  Form,
  Button,
  Link,
  Checkbox,
  Text,
  FooterHelp,
  Banner,
  Loading,
  InlineStack,
  Box,
  Page,
  Popover,
  ActionList,
  Thumbnail,
} from "@shopify/polaris";

import React, { useState, useCallback, useEffect, useLayoutEffect } from "react";
import LoginLogo from "assets/images/logoLogin.png";
import { useAppDispatch, useAppSelector } from "../config/store";
import {
  useNavigate,
  useLocation,
} from "react-router-dom";
import 'media/css/login.scss'
import {
  asChoiceField,
  lengthLessThan,
  lengthMoreThan,
  notEmptyString,
  useField,
  useForm,
} from "@shopify/react-form";

import helpers from "../helpers";
import {
  login,
  clearError,
  reset,
  getCurrentUser,
  // createSession,
} from "../store/user.store.reducer";

import { clearError as clearGlobalError } from 'store/global.warning.store.reducer';
import __, { ___ } from "languages/index";



import {
  EnterIcon,
  ViewIcon,
  HideIcon,
  LanguageTranslateIcon,
  LanguageIcon
} from '@shopify/polaris-icons';
import axios, { AxiosResponse } from "axios";
import { TypedApp } from "interface/app.model";
import { IDENTIFY } from "assets";


export default function Login() {

  const __passport = (window as any).__passport || '';
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [viewPasswordMode, setViewPasswordMode] = useState(false);
  const [sso_notice, setSso_notice] = useState<any>(null);
  const [loginLogoURI, setLoginLogoURI] = useState<string>(LoginLogo);
  const [loginMainTitle, setLoginMainTitle] = useState<string>(__('welcome'));
  const [loginSubTitle, setLoginSubTitle] = useState<string>(__('login'));

  useLayoutEffect(() => {
    const SearchParam = new URLSearchParams(search);
    let app_id = SearchParam.get('app_id');
    if (app_id) {
      localStorage.setItem('app_id', app_id);
    }
    if (SearchParam.get('redirect_to')) {
      let decodeURI = decodeURIComponent(SearchParam.get('redirect_to'));
      localStorage.setItem('redirect', decodeURI);
    }
    fetchSSOApp();
  }, []);

  // /login/sso
  let { pathname, state: loginState, search } = useLocation();

  const fetchSSOApp = useCallback(async () => {
    let app_id = localStorage.getItem('app_id');
    if (!app_id) return;
    try {
      let _appData: AxiosResponse<TypedApp> = await axios.get(process.env.REACT_APP_AJAX_URL + '/app/' + app_id);
      let appData = _appData.data;

      setSso_notice(___('After login, you will be redirect to {app_name}, back to {app_homepage} homepage!', {
        app_name: <strong>{appData.app_name}</strong>,
        app_homepage: <Link url={appData.app_homepage}>{appData.app_name}</Link>
      }));

      /**
       * Check before redirect
       */
      try {
        let redirect = localStorage.getItem('redirect');
        let decodeURI = decodeURIComponent(redirect);
        let { host } = new URL(decodeURI);
        let { host: appHost } = new URL(appData.app_homepage);
        if (host !== appHost) {
          localStorage.setItem('redirect', appData.app_homepage);
        }
      } catch (e) {
        localStorage.setItem('redirect', appData.app_homepage);
      }



      if (appData.app_thumbnail)
        setLoginLogoURI(process.env.REACT_APP_AJAX_UPLOAD_PERMALINK + '/' + appData.app_thumbnail);

      setLoginMainTitle(appData.app_name);
      setLoginSubTitle(appData.app_description);
    } catch (e) {
      // nếu lỗi gì, xóa luôn localStorage cho chắc chắn
      localStorage.removeItem('app_id');
      localStorage.removeItem('redirect');
    }

  }, [search]);

  /**
   * Log out truoc !
   */
  const loginRegisterErrorMessage = useAppSelector((state) => state.user.loginRegisterErrorMessage);
  const loading = useAppSelector((state) => state.user.loading);


  const redirectTo = useCallback((access_token_for_sso?: string) => {
    dispatch(clearGlobalError());
    navigate('/')
    // let redirect_to = localStorage.getItem('redirect') || process.env.REACT_APP_PUBLIC_URL;
    // let app_id = localStorage.getItem('app_id');

    // if ( app_id ) {
    //   // cần chuyển URL sang bên khác ...
    //   window.location.href = (decodeURIComponent(redirect_to) + `#oauth_access_token=` + access_token_for_sso );
    // } else {
    //   window.location.replace(decodeURIComponent(redirect_to));
    // }
  }, [pathname]);


  const removeSSO = () => {
    localStorage.removeItem('app_id');
    localStorage.removeItem('redirect');
    window.location.href = '/login';
  }

  /**
   * Khai báo field cho form!
   */
  const useFields = {
    user_input: useField<string>({
      value: "",
      validates: [
        notEmptyString("Trường này không được để trống."),
        lengthLessThan(50, "Bạn điền quá dài!"),
        lengthMoreThan(6, "Email/số điện thoại quá ngắn!"),
        (inputValue) => {
          if (!helpers.isPhoneNumber(inputValue))
            if (!helpers.isEmail(inputValue)) {
              return "Định dạng Email không hợp lệ! Vui lòng kiểm tra lại email của bạn!";
            }
          if (helpers.isUTF8(inputValue)) {
            return "Trường này không nên có mã Unicode, bạn vui lòng kiểm tra lại!";
          }
        },
      ],
    }),
    password: useField<string>({
      value: "",
      validates: [
        notEmptyString("Trường này không được để trống."),
        lengthMoreThan(6, "Mật khẩu quá ngắn!"),
        (inputValue) => {
          if (helpers.isUTF8(inputValue)) {
            return "Không nên dùng mã Unicode trong mật khẩu của bạn!";
          }
        },
      ],
    }),
    remember: useField<number>({
      value: 0,
      validates: [],
    }),
  };

  const {
    fields,
    submit,
    reset: resetForm,
  } = useForm({
    fields: useFields,
    async onSubmit(form) {
      await dispatch(
        login({
          user_password: form.password,
          user_input: form.user_input,
          domain: 'https://dev.gamifa.com',
          device_type: "website",
        })
      ).unwrap().then(e => {
        // let access_token_for_sso = e.headers['x-authorization'] ?? '';
        dispatch(getCurrentUser())
        redirectTo();
      }).catch(e => { });
      return { status: "success" };
    },
  });

  useEffect(() => {
    dispatch(clearGlobalError());
    dispatch(reset());
    resetForm();
    dispatch(clearError());
  }, []);


  const toggleBannerActive = useCallback(() => {
    dispatch(clearError());
  }, []);

  const errorBanner = loginRegisterErrorMessage ? (
    <>
      <Banner tone="critical" onDismiss={toggleBannerActive}>
        {loginRegisterErrorMessage}
      </Banner>
      <br />
    </>
  ) : null;


  const [buttonFacebookLoading, setbuttonFacebookLoading] = useState(false);
  const loginByFacebookButton = useCallback(() => {
    setbuttonFacebookLoading(true);
  }, []);

  const [languagePopoverStatus, setLanguagePopoverStatus] = useState(false);
  const toggleLanguagePopoverActive = useCallback(() => setLanguagePopoverStatus((active) => !active), []);

  const setDefaultLanguage = useCallback((lang_code: string) => {
    localStorage.setItem('language', lang_code);
    setLanguagePopoverStatus(false);
  }, []);


  return (
    <Frame>
      {loading ? <Loading /> : null}
      <Page>
        <InlineStack blockAlign="center" align="center">
          <div id="login_page" className="wrapper">

            {/* <InlineStack blockAlign="center" align="end" gap="200">

              <Popover
                active={languagePopoverStatus}
                activator={
                  <Button onClick={toggleLanguagePopoverActive} disclosure variant="plain" icon={LanguageTranslateIcon}>
                    {__('switch_language_button')}
                  </Button>
                }
                autofocusTarget="first-node"
                onClose={toggleLanguagePopoverActive}
              >
                <ActionList
                  actionRole="menuitem"
                  items={[
                    {
                      content: 'Vietnamese',
                      onAction: () => setDefaultLanguage('vi'),
                      icon: LanguageTranslateIcon
                    },
                    {
                      content: 'English',
                      onAction: () => setDefaultLanguage('en'),
                      icon: LanguageTranslateIcon
                    },
                  ]}
                />
              </Popover>
            </InlineStack> */}



            <br />

            {sso_notice && (
              <>
                <Banner onDismiss={removeSSO}>
                  <p>{sso_notice}</p>
                </Banner>
                <br />
              </>
            )}

            <div className="wrapLogin">
              <Form onSubmit={submit}>

                <InlineStack wrap={false} gap="200" blockAlign="center" align="space-between">
                  <div>
                    <span className="titleLogin">{IDENTIFY.TITLE}</span>
                  </div>
                  <div>
                    <img style={{ maxWidth: IDENTIFY.MAX_WIDTH_LOGO_LOGIN }} src={loginLogoURI} alt={"Login Logo"} />
                  </div>
                </InlineStack>

                <br />


                {errorBanner}
                {
                  loginState &&
                  (
                    <>
                      <Text tone="success" as="p">{loginState.message || ' '}</Text>
                      <br />
                    </>
                  )
                }
                <FormLayout>
                  <TextField
                    type="text"
                    disabled={loading}
                    autoFocus
                    placeholder=""
                    label={__('login_by_your_account')}
                    {...fields.user_input}
                    requiredIndicator
                    autoComplete="on"
                  />
                  <TextField
                    disabled={loading}
                    label={__('login_by_your_password')}
                    placeholder="******"
                    type={viewPasswordMode ? "text" : "password"}
                    requiredIndicator
                    autoComplete="off"
                    suffix={(
                      <InlineStack blockAlign="center">
                        <Button variant="plain" onClick={() => setViewPasswordMode(!viewPasswordMode)} icon={viewPasswordMode ? ViewIcon : HideIcon}></Button>
                      </InlineStack>
                    )}
                    {...fields.password}
                  />


                  {/* <Checkbox
                    disabled={loading}
                    label={__('login_by_remember_me')}
                    {...asChoiceField(fields.remember)}
                    helpText={__('login_by_remember_me_help_text')}
                  /> */}

                  <InlineStack wrap={false} gap="200" blockAlign="center" align="space-between">
                    <Text as="span">
                      {
                        <Link removeUnderline url="/recover_password">{__("Forgot your password")}</Link>
                      }
                    </Text>
                    <Button
                      submit
                      variant="primary"
                      disabled={loading}
                      onClick={submit}
                      key={'button_login_normal'}
                      size="large"
                    >
                      {__('login_button_context')}
                    </Button>
                  </InlineStack>
                  {/* {
                  loginByFacebookURL && (
                    <Link target="_self" removeUnderline monochrome url={loginByFacebookURL} onClick={loginByFacebookButton}>
                      <Button size="large" icon={EnterIcon} fullWidth loading={buttonFacebookLoading}>{__('login_by_facebook_button')}</Button>
                    </Link>
                  )
                } */}

                  {/* <Text as="p">{___('Can not login? Visit {help_center_link}', {
                    help_center_link: <Link url="/help_center">{__('help_center_text')}</Link>
                  })}</Text> */}

                </FormLayout>
              </Form>
            </div>
          </div>
        </InlineStack>
      </Page>
    </Frame>
  );
}
