import helpers from "helpers/index";
import { Avatar, InlineGrid, InlineStack, LegacyCard, ResourceItem, ResourceList, Text, BlockStack } from "@shopify/polaris";
import { getEntities } from "store/notification.store.reducer";
import dateandtime from 'date-and-time';
import { useAppDispatch, useAppSelector } from "config/store";
import { useCallback, useEffect, useState } from "react";


export default function NotificationLog({show}: {show: boolean}) {
    const account = useAppSelector((state) => state.user.account);
    const entities = useAppSelector((state) => state.notification.entities);
    const loading = useAppSelector((state) => state.notification.loading);
    const dispatch = useAppDispatch();

    const loadData = useCallback( async() => {
        return await dispatch(getEntities({
            sort: 'createdAt:desc',
            notification_user: account.user_id,
            limit: 100
        }));
    }, [account]);

    useEffect( () => {
        if ( !show ) return;
        loadData();
    }, [show]);


    const [todayNotification, setTodayNotification] = useState([]);
    const [otherdayNotification, setOtherdayNotification] = useState([]);

    useEffect( () => {
        if ( ! entities ) return;
        let t = dateandtime.format( new Date(), 'YYYYMMDD' );
        let td = [];
        let tmr = [];
        for ( let el of entities) {
            let n = dateandtime.format( new Date(Number(el.createdAt)), 'YYYYMMDD' );
            if ( n===t ) {
                td.push(el);
            } else {
                tmr.push(el);
            }
        };

        setTodayNotification(td);
        setOtherdayNotification(tmr);
    }, [entities]);

    return (
        <>
        { 
            loading ? (
                <div style={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    marginTop: '2rem'
                }}>Loading...</div>
            ) : (
                <div className="notification_wrap">
                    <div className="notification_heading">Hôm nay</div>
                    {
                        todayNotification.length < 1 ? (
                            <div style={{padding: '15px', textAlign: 'center'}}><Text as="p">Chưa có thông báo nào...</Text></div>
                        ) : 
                        todayNotification?.map( (el, index) => {
                            let url = 'javascript:void(0)';
                            try {
                                url = JSON.parse(el.notification_data).click_action;
                            } catch(e) {}
                            return (
                                <a href={url} className="notification_element" key={index + '_notification'}>
                                    <div className="notification_avatar"><Avatar source={null} size="sm" name="Avatar" initials="H" /></div>
                                    <div className="notification_body">
                                        <div className="l1"><strong>{el.notification_title}</strong>:  {el.notification_content}</div>
                                        <div className="l2">{helpers.subtractTimeHistory(new Date(el.createdAt).getTime())}</div>
                                    </div>
                                </a>
                            )
                        })
                    }
                    
                    <div className="notification_heading">Trước đó</div>
                    {
                        otherdayNotification.length < 1 ? (
                            <div style={{padding: '15px', textAlign: 'center'}}><Text as="p">Không có thông báo nào...</Text></div>
                        ) : 
                        otherdayNotification?.map( (el, index) => {
                            let url = 'javascript:void(0)';
                            try {
                                url = JSON.parse(el.notification_data).click_action;
                            } catch(e) {}
                            return (
                                <a href={url} className="notification_element" key={index + '_notification'}>
                                    <div className="notification_avatar"><Avatar source={null} size="sm" name="Avatar" initials="H" /></div>
                                    <div className="notification_body">
                                        <div className="l1"><strong>{el.notification_title}</strong>:  {el.notification_content}</div>
                                        <div className="l2">{helpers.subtractTimeHistory(new Date(el.createdAt).getTime())}</div>
                                    </div>
                                </a>
                            )
                        })
                    }

                </div>
            )
        }


        </>
    )
}