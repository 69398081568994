import React from "react";
import { useEffect, useState } from "react";

import { Divider, InlineGrid, SkeletonDisplayText, Text, BlockStack, Card, LegacyCard, Page } from "@shopify/polaris";
import { useAppDispatch, useAppSelector } from '../../../config/store';
import DateRangePickerChart from "../../../components/dateRangePickerChart";
import { getSumarizeOrderForAdmin, getEntities } from "store/order.store.reducer";
import EchartsStatusOrderLinePieChart from "./components/order_status_chart";

export default function ReportOrdersIndex() {

  const entities = useAppSelector((state) => state.order.entities);
  const loading = useAppSelector((state) => state.order.loading);
  const dispatch = useAppDispatch();
  const sumarizeOrder = useAppSelector((state) => state.order.sumarizeOrder);
  const [timeLog, setTimeLog] = useState({
    title: "theo năm",
    alias: "year",
    since: "",
    until: "",
  });
  const [dataChart, setDataChart] = useState(null);

  useEffect(() => {
    setDataChart({
      data_value: [
        // ['product', ...timeLog.alias !== 'year' ? generateDaysArray(timeLog?.since, timeLog?.until) : dataTimeYear],
        ['product', '2012', '2013', '2014', '2015', '2016', '2017'],
        ['Mới', 56.5, 82.1, 88.7, 70.1, 53.4, 85.1],
        ['Đã xác nhận', 51.1, 51.4, 55.1, 88.7, 70.1, 68.7],
        ['Giao hàng thành công', 40.1, 62.2, 69.5, 36.4, 45.2, 32.5],
        ['Khách hàng huỷ', 25.2, 88.7, 70.1, 18, 33.9, 49.1],
        ['Hệ thống huỷ', 51.1, 51.4, 55.1, 88.7, 70.1, 68.7],
        ['Huỷ đơn hoàn tiền', 40.1, 88.7, 70.1, 36.4, 45.2, 32.5],
        ['Không nhận hàng', 88.7, 70.1, 41.2, 18, 33.9, 49.1]
      ]
    })
  },[timeLog])

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  // Lấy tổng các order theo từng trạng thái
  useEffect(() => {
    dispatch(getSumarizeOrderForAdmin());
  }, [])

  const onChangeRangeDatePicker = (value) => {
    setTimeLog(value)
  }

  return (
    <Page
      fullWidth
      title={'Thống kê đơn hàng (Đang phát triển phần mềm dữ liệu chưa chính xác)'}
    >
      <InlineGrid gap={"600"} columns={{ xs: 1, sm: 1, md: 3, lg: 3 }}>
        <Card>
          <img src={require('media/images/4l.png')} width={"50px"} alt="" />
          <BlockStack>
            {
              loading ? <SkeletonDisplayText size="small" /> : (
                <>
                  <Text as="h3" fontWeight="regular" variant="headingXl">Tổng đơn hàng mới</Text>
                  <Text as="h3" fontWeight="bold" variant="headingXl">{sumarizeOrder?.draft ?? 0} Đơn hàng</Text>
                </>
              )}

          </BlockStack>
        </Card>

        <Card>
          <img src={require('media/images/4l.png')} width={"50px"} alt="" />
          <BlockStack>
            {
              loading ? <SkeletonDisplayText size="small" /> : (
                <>
                  <Text as="h3" fontWeight="regular" variant="headingXl">Tổng đơn hàng thành công</Text>
                  <Text as="h3" fontWeight="bold" variant="headingXl">{sumarizeOrder?.completed ?? 0} Đơn hàng</Text>
                </>
              )}
          </BlockStack>
        </Card>

        <Card>
          <img src={require('media/images/4l.png')} width={"50px"} alt="" />
          <BlockStack>
            {
              loading ? <SkeletonDisplayText size="small" /> : (
                <>
                  <Text as="h3" fontWeight="regular" variant="headingXl">Tổng đơn hàng thất bại</Text>
                  <Text as="h3" fontWeight="bold" variant="headingXl">{(Number(sumarizeOrder?.cancel ?? 0) + Number(sumarizeOrder?.customer_cancel ?? 0) + Number(sumarizeOrder?.refunded ?? 0) + Number(sumarizeOrder?.returned) ?? 0)} Đơn hàng</Text>
                </>
              )}
          </BlockStack>
        </Card>

      </InlineGrid>
      <br />
      <InlineGrid gap="400" columns={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
        <div className="dashboard_column_1_wrap">
          <Divider />
          <LegacyCard sectioned>
            <DateRangePickerChart onChange={onChangeRangeDatePicker} />
            {dataChart && <EchartsStatusOrderLinePieChart dataChart={dataChart}/>}
          </LegacyCard>
        </div>
      </InlineGrid>
    </Page>
  )
}