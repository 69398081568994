import axios from 'axios';
import { createAsyncThunk} from '@reduxjs/toolkit';
import { IQueryParams, createEntitySlice, serializeAxiosError } from '../config/reducer.utils';

import helpers from 'helpers/index';
import { iUserAchievement } from 'interface/achievement.model';

export const initialState = {
  loading: false,
  updating: false,
  entities: null as iUserAchievement[],
  entity: null as iUserAchievement,
  assignee: null as any[],
  totalItems_assignee: 0,
  totalItems: 0,
  errorMessage: null as unknown as string, // Errors returned from server side
  updateSuccess: false,
};

const apiUrl = 'achievement';

type TypedQuery = iUserAchievement & IQueryParams;

export const getEntities = createAsyncThunk('achievement/get_achievement_list', async (params: TypedQuery) => {
    params = helpers.filterEmptyObject(params);
    return await axios.get<iUserAchievement>(`${apiUrl}`, {params: params});
  }
);


export const getEntity = createAsyncThunk('achievement/get_achievement', async (achievement_id: string | bigint) => {
    return await axios.get<iUserAchievement>(`${apiUrl}/${achievement_id}`);
  }
);


export const createEntity = createAsyncThunk(
    'achievement/create_entity',
    async (entity: iUserAchievement, thunkAPI) => {
      entity = helpers.filterEmptyObject(entity);
      return await axios.post<iUserAchievement>(`${apiUrl}`, helpers.cleanEntity(entity));
    },
    { serializeError: serializeAxiosError }
  );
  
  export const updateEntity = createAsyncThunk(
    'achievement/update_entity',
    async (entity: iUserAchievement, thunkAPI) => {
      // patch
      entity = helpers.filterEmptyObject(entity);
      let achievement_id = entity.achievement_id;
      return await axios.put<iUserAchievement>(`${apiUrl}/${achievement_id}`, helpers.cleanEntity(entity));
    },
    { serializeError: serializeAxiosError }
  );



/**
 * JamDev: RecoverPassword
 */
export const assign = createAsyncThunk(
  'achievement/assign_user_to_achievement',
  async (entity: {achievement_id: string, user_id: string}) => {
    return await axios.post<any>(`${apiUrl}/assign`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

/**
 * JamDev: assigned
 */
export const getAssignee = createAsyncThunk(
  'achievement/get_assign_user_to_achievement',
  async (params: IQueryParams) => {
    return await axios.get<any>(`${apiUrl}/assignee/`, {
      params
    });
  },
  { serializeError: serializeAxiosError }
);

/**
 * JamDev: delete assign
 */
export const removeAssignee = createAsyncThunk(
  'achievement/remove_assign_user_to_achievement',
  async (achievement_id: string) => {
    return await axios.delete<any>(`${apiUrl}/assignee/${achievement_id}`);
  },
  { serializeError: serializeAxiosError }
);



export const ACHIEVEMENT = createEntitySlice({
  name: 'achievement',
  initialState: initialState as any,
  reducers: {
    reset: (state) => {
      return {...state, ...initialState};
    }
  },

  extraReducers(builder) {
    builder

      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })

      .addCase(getEntity.fulfilled, (state, action) => {
        state.entity = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(getEntity.pending, (state, action) => {
        state.entity = null;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.entity = null;
        state.errorMessage = action.payload;
      })

      .addCase(createEntity.fulfilled, (state, action) => {
        state.entity = action.payload.data;
        state.updating = false;
      })
      .addCase(createEntity.pending, (state, action) => {
        state.entity = null;
        state.updating = true;
      })
      .addCase(createEntity.rejected, (state, action) => {
        state.entity = null;
        state.updating = false;
        state.errorMessage = action.payload;
      })

      .addCase(updateEntity.fulfilled, (state, action) => {
        state.entity = action.payload.data;
        state.updating = false;
      })
      .addCase(updateEntity.pending, (state, action) => {
        state.entity = null;
        state.updating = true;
      })
      .addCase(updateEntity.rejected, (state, action) => {
        state.entity = null;
        state.updating = false;
        state.errorMessage = action.payload;
      })

      .addCase(assign.fulfilled, (state, action) => {
        state.updating = false;
      })
      .addCase(assign.pending, (state, action) => {
        state.updating = true;
      })
      .addCase(assign.rejected, (state, action) => {
        state.updating = false;
        state.errorMessage = action.payload;
      })

      .addCase(getAssignee.fulfilled, (state, action) => {
        state.loading = false;
        state.assignee = action.payload.data;
        state.totalItems_assignee = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(getAssignee.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAssignee.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })

      .addCase(removeAssignee.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(removeAssignee.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(removeAssignee.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })


  },
});
export const {reset} = ACHIEVEMENT.actions;
// Reducer
export default ACHIEVEMENT.reducer;