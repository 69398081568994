import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { IQueryParams, createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { TypedDataMember } from 'interface/member.model';
import { IUser } from 'interface/user.model';


export interface TypedUser_activity {
	activity_id?: string; 
	customer_id?: string; 
	activity_type?: string; 
	activity_source?: string; 
	createdAt?: string; 
}

type iQuery = TypedUser_activity & IQueryParams;

/**
*   Reducer used for front-end, with comment.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using comment.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null as any,
  entities: [] as IUser[],
  totalItems: 0
};

const apiUrl = 'channel/list-members';

// Actions

export const getEntities = createAsyncThunk(
  'member/get_list_members',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      const response = await axios.get<TypedDataMember[]>(`${apiUrl}`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

// slice

export const Reducer_member= createEntitySlice({
  name: 'member',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEntities.fulfilled, (state, action) => {
        state.entities = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
        state.loading = false;
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true;
        state.entities = [];
      })
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.entities = [];
        state.errorMessage = action.payload
      })
      
  },
});

export const { clearError, reset } = Reducer_member.actions;

// Reducer
export default Reducer_member.reducer;
