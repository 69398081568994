import {Button, Frame, Modal, TextContainer} from '@shopify/polaris';
import {useState, useCallback, useEffect, memo} from 'react';

function ModalConfirm(props) {
  const { active , setActive, onAction, title, btnText, contentText} = props
  return (
    <div>
      <Frame>
        <Modal
          open={active}
          onClose={() => setActive(false)}
          title={title}
          secondaryActions={[
            {
              content: btnText,
              onAction: () => onAction(),
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>
                {contentText}
              </p>
            </TextContainer>
          </Modal.Section>
        </Modal>
      </Frame>
    </div>
  );
}

export default memo(ModalConfirm)