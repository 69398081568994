import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from '../config/reducer.utils';
import { IPostToContent, IPosts } from '../interface/post.model';


const initialState: EntityState<IPosts> = {
  loading: false,
  errorMessage: null,
  entities: null,
  allPostType: [],
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'posts';

// Actions

// export const getEntities = createAsyncThunk('posts/fetch_entity_list', async ({ query, page, limit, sort,post_type }: IQueryParams) => {
//   const requestUrl = `${apiUrl}?query=${query?? ''}&page=${page?? 1}&limit=${limit?? 20}&sort=${sort?? ''}&post_type=${post_type?? ''}`;
//   return axios.get<any>(requestUrl);
// });

export const getEntities = createAsyncThunk('posts/fetch_entity_list', async (_URL: IQueryParams) => {
  // const requestUrl = `${apiUrl}?query=${query?? ''}&page=${page?? 1}&limit=${limit?? 20}&sort=${sort?? ''}&post_type=${post_type?? ''}`;
  let __URL = helpers.buildEndUrl(_URL);
  const requestUrl = `${apiUrl}${__URL}`;
  return axios.get<any>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'posts/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getPostType = createAsyncThunk(
  'post_type/fetch_entity',
  async () => {
    const requestUrl = `post_type`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'posts/create_entity',
  async (entity: IPosts) => {
    return await axios.post<IPosts>(apiUrl, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    // return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'posts/update_entity',
  async (entity: IPosts, thunkAPI) => {
    let postID = String(entity.post_id);
    delete entity.post_id;
    const result = await axios.patch<IPosts>(`${apiUrl}/${postID}`, helpers.cleanEntity(entity));
    //  thunkAPI.dispatch(getEntity(postID));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateInsertPostContent = createAsyncThunk(
  'posts/upsert_post_content_entity',
  async (entity: IPostToContent, thunkAPI) => {
    return await axios.post<IPostToContent>(`${apiUrl}/content`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);



export const partialUpdateEntity = createAsyncThunk(
  'posts/partial_update_entity',
  async (entity: IPosts, thunkAPI) => {
    let postID = String(entity.post_id);
    delete entity.post_id;
    const result = await axios.patch<IPosts>(`${apiUrl}/${postID}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'posts/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    thunkAPI.dispatch(getEntities({}));
    return axios.delete<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const POSTS = createEntitySlice({
  name: 'posts',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })

      .addCase(deleteEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = "Can not delete this Entity"
      })
      .addCase(updateEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = action.error.message
      })

      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })

      .addMatcher(isFulfilled(getPostType), (state, action) => {
        state.allPostType = action.payload.data;
      })

      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
        state.entity = null;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { clearError, reset } = POSTS.actions;

// Reducer
export default POSTS.reducer;
