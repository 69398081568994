import { ActionList, Badge, Button, Card, DataTable, Icon, Link, Loading, Page, Popover, Text, Toast, Tooltip, InlineStack, EmptyState } from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import emptyIMG from "media/images/empty.png";
import orderFullfillmentStatus from './order.fullfillment.status.json';
import __ from 'languages';

import {
  clearError,
  updateEntity,
  getEntities,
  getEntity as getOrder,
  createEntity,
  bulkUpdateEntities,
  setStatusEntity,
  getSumarizeOrderForAdmin,
  setOrderFinish,
} from "store/order.store.reducer";
import dateandtime from 'date-and-time';
import instructImg from 'media/images/support.jpg'

import {
  PrintIcon,
  AlertDiamondIcon,
  ViewIcon,
  LockIcon,
  CheckIcon,
  AlertCircleIcon,
  ChevronDownIcon
} from "@shopify/polaris-icons";

import {
  getEntities as getLogisticList
} from 'store/logistic_service.store.reducer';
import {
  getEntity as getOrderFullfillment,
  updateEntity as updateOrderFullfillment,
} from 'store/order_fullfillment.store.reducer';
import Pagination from "components/pagination";
import SimpleFilter from "components/simpleFilter";
import { LockedOrder, OrderStatus, PaymentStatus } from "enum/order.enum";
import { TypedOrder } from "interface/order.model";
import NoteComponent from "components/noteComponent";
import helpers from "helpers";

export default function OrderList() {
  const account = useAppSelector(state => state.user.account);
  const rootData = useAppSelector((state) => state.order.entities);
  const entity = useAppSelector((state) => state.order.entity);
  const sumarizeOrder = useAppSelector((state) => state.order.sumarizeOrder);
  const newCreateData = useAppSelector((state) => state.order.newCreateData);
  const loading = useAppSelector((state) => state.order.loading);
  const totalItems = useAppSelector((state) => state.order.totalItems);
  const updating = useAppSelector((state) => state.order.updating);
  const updateSuccess = useAppSelector((state) => state.order.updateSuccess);
  const [showImgStruct, setShowImgStruct] = useState(false)
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
  }, []);

  useEffect(() => {
    clearErrorCallback();
  }, []);

  // Lấy tổng các order theo từng trạng thái
  useEffect(() => {
    dispatch(getSumarizeOrderForAdmin());
  }, [])

  const [entities, setEntities] = useState([]);
  useEffect(() => {
    if (rootData) {
      setEntities(rootData);
    }
  }, [rootData]);


  const mergeEntityToMainStream = async (entity) => {
    let cloneDataInternal = entities?.map((el) => {
      if (entity && el.order_id === entity.order_id) {
        return {
          ...el,
          ...entity
        };
      }
      return el;
    });
    setEntities(cloneDataInternal);
  }

  /**
   * Get one record and update to main Data...
   */
  const reloadOrderData = useCallback(async (order_id) => {
    await dispatch(getOrder(order_id));
    // if ( orderData?.payload?.data ) {
    // mergeEntityToMainStream(orderData.payload.data);
    // }
  }, []);


  const [showBulkOrderAction, setShowBulkOrderAction] = useState(false);

  /**
   * create new Order
   */

  const createNewEmptyOrder = useCallback(() => {
    dispatch(createEntity({}));
  }, []);

  useEffect(() => {
    if (newCreateData) {
      history('/order/view/' + newCreateData.order_id)
    }
  }, [newCreateData]);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery = helpers.ExtractUrl(useParam.search) || false;
  /**
   * Nếu bên khác truyền state sang thì có thể dùng luôn để filter ...
   */
  // const stateProp = useParam?.state || {};

  const initialQuery = {
    query: "",
    page: 1,
    limit: 20,
    order_status: '',
    payment_status: '',
    sort: "createdAt:desc",
  }
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery,
    // ...stateProp
  });

  /**
   * Change page number
   */
  const onChangePageNumber = useCallback((numPage: number, Limit: number) => {
    setMainQuery({ ...mainQuery, page: numPage, limit: Limit });
  }, [mainQuery]);

  const reduceRequest = useCallback((mainQuery) => {
    dispatch(getEntities(mainQuery));
    return mainQuery;
  }, []);

  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce((_value) => {
      reduceRequest?.call(this, _value)
    }, 500);
  }, []);

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, "Order", "/orders" + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);


  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */

  let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      setMainQuery(m);
    }
  }, []);


  function getOrderDeliveryMethod(_method: string) {
    return __(_method) || <Badge></Badge>;
  }

  function getOrderStatusBadge(_status: OrderStatus): any {
    switch (_status) {
      case OrderStatus.DRAFT:
        return <Badge tone={"attention"}>{__(`order_status_${_status}`)}</Badge>;
      case OrderStatus.APPROVED:
        return <Badge tone="success">{__(`order_status_${_status}`)}</Badge>;
      case OrderStatus.TRANSPORTING:
        return <Badge tone="info">{__(`order_status_${_status}`)}</Badge>;
      case OrderStatus.COMPLETED:
        return <Badge tone="success-strong">{__(`order_status_${_status}`)}</Badge>;
      case OrderStatus.CANCEL:
        return <Badge tone="critical">{__(`order_status_${_status}`)}</Badge>;
      case OrderStatus.CUSTOMER_CANCEL:
        return <Badge tone="critical">{__(`order_status_${_status}`)}</Badge>;
      case OrderStatus.REFUNDED:
        return <Badge tone="warning">{__(`order_status_${_status}`)}</Badge>;
      case OrderStatus.RETURNED:
        return <Badge tone="critical-strong">{__(`order_status_${_status}`)}</Badge>;
    }

  }

  function getOrderFullfillmentBadge(order_id: string, order_fullfillment_status: number, order_fullfillment_error: string): any {
    let name = orderFullfillmentStatus[order_fullfillment_status] || "";
    switch (order_fullfillment_status) {
      case 10:
      case 11:
      case 12:
      case 13:
        return <Badge tone="info" progress="partiallyComplete">{name}</Badge>
      case 14:
        return <Badge tone="success" progress="complete">{name}</Badge>
      case 15:
        return <Badge tone="attention" progress="complete">{name}</Badge>
      case 16:
        return (
          <Link onClick={() => clearOrderFullfillmentError(order_id)}>
            <span className="Polaris-Badge Polaris-Badge--statusCritical" title={order_fullfillment_error}>
              <span className="Polaris-Text--root Polaris-Text--bodySm" style={{ fontSize: '12px' }}>
                <Icon source={AlertDiamondIcon} tone="critical" />
              </span>
              <span className="Polaris-Text--root Polaris-Text--bodySm">error</span>
            </span>
          </Link>
        )
      case 19:
        return <Badge tone="critical">{name}</Badge>
      default:
        return <Badge></Badge>;
    }
  }

  // Hiển thị danh sách chọn trạng thái giao hàng orders
  const renderItemOrderStatus = (content, order_id: string, status: OrderStatus, tone, active: boolean = false) => {
    return (
      <div style={{ marginTop: 5, cursor: 'pointer' }} onClick={() => changeOrderStatus(order_id, status)}>
        <Badge icon={active && CheckIcon} tone={tone}>{content}</Badge>
      </div>
    )
  }

  // Hiển thị danh sách chọn trạng thái thanh toán orders
  const renderItemPaymentStatus = (content, order_id: string, status: PaymentStatus, tone, active: boolean = false) => {
    return (
      <div style={{ marginTop: 5, cursor: 'pointer' }} onClick={() => changePaymentStatus(order_id, status)}>
        <Badge icon={active && CheckIcon} tone={tone}>{content}</Badge>
      </div>
    )
  }

  function getPaymentStatusBadge(status: number) {
    switch (status) {
      case -1:
        return <Badge tone="critical-strong">Chưa thanh toán</Badge>
      case 0:
        return <Badge tone="critical-strong">Chưa thanh toán</Badge>
      case 1:
        return <Badge tone={'warning'} progress={'partiallyComplete'}>T.Toán một phần</Badge>
      case 2:
        return <Badge tone={'success'} progress={'complete'}>Đã thanh toán</Badge>
      // case 3:
      //   return <Badge tone="critical" progress="incomplete">Chờ hoàn tiền</Badge>
      // case 4:
      //   return <Badge tone="critical" progress="incomplete">Hoàn tiền</Badge>
      default:
        return <Badge></Badge>;
    }
  }

  useEffect(() => {
    // if ( ! updateSuccess ) return;
    if (!entity) return;
    mergeEntityToMainStream(entity);
    dispatch(clearError());
  }, [entity]);



  /** Mọi update đều vào đây ... */
  const quickUpdateOrder = useCallback(async (_v: any) => {
    await dispatch(updateEntity(_v));
  }, []);

  const quickUpdateOrderFullfillment = useCallback(async (_v: any) => {
    let userFullfillment: any = await dispatch(updateOrderFullfillment(_v));
    if (userFullfillment?.payload?.data)
      reloadOrderData(userFullfillment.payload.data.order_id);
  }, []);

  const clearOrderFullfillmentError = useCallback(async (order_id: any) => {
    quickUpdateOrderFullfillment({
      order_id: order_id,
      order_fullfillment_status: 10,
      order_fullfillment_error: ''
    });
  }, []);


  /**
   * THay đổi loại giao vận
   */
  const [showDeliveryType, setShowDeliveryType] = useState(null);
  const onClosePophoverOderCallback = useCallback((order_id: any) => {
    setShowDeliveryType(!order_id);
  }, []);
  const togglePophoverOrderCallback = useCallback((_s: any) => {
    setShowDeliveryType(showDeliveryType === _s ? false : _s);
  }, [showDeliveryType]);

  const savingDeliveryTypeCallback = useCallback((order_id: any, order_delivery_type: any) => {
    quickUpdateOrder({
      order_id: order_id,
      order_delivery_type: order_delivery_type
    });
    onClosePophoverOderCallback(order_id);
  }, []);

  /**!SECTION
   * Get all logistic service and memo it 
   */
  useEffect(() => {
    dispatch(getLogisticList({
      sort: 'createdAt:desc',
      limit: 100,
      logistic_status: 1
    }))
  }, []);

  /**
   * Ghi chú đơn hàng ...
   */

  const [noteForOrder, setNoteForOrder] = useState('');
  const [showPophoverOrderNote, setShowPophoverOrderNote] = useState(null);
  const onClosePophoverOderNoteCallback = useCallback((customer_campaign_id: any) => {
    setShowPophoverOrderNote(!customer_campaign_id);
    setNoteForOrder('');
  }, []);


  const togglePophoverOrderNoteCallback = useCallback((_s: any, order_note: string) => {
    setShowPophoverOrderNote(showPophoverOrderNote === _s ? false : _s);
    setNoteForOrder(order_note);
  }, [showPophoverOrderNote]);

  const submitNoteOrderCallback = useCallback(async (value: string, order_id: any) => {
    await quickUpdateOrder({
      order_id: order_id,
      order_note: value
    });
    setShowPophoverOrderNote(null);
    setNoteForOrder('');
  }, []);


  /**
   * Action menu ...
   */
  const [showActionList, setShowActionList] = useState(null);
  const onCloseActionList = useCallback((order_id: any) => {
    setShowActionList(!order_id);
  }, []);
  const togglePophoverActionListCallback = useCallback((_s: any) => {
    setShowActionList(showActionList === _s ? false : _s);
  }, [showActionList]);

  /**
   * markAsChecked
   * @param order_pnr String
   * @param mode String
   */
  const markAsChecked = async (order_pnr: string, order_id: any, mode: string) => {
    setShowActionList(null);
    await dispatch(bulkUpdateEntities({
      order_pnr: order_pnr,
      mode: mode,
    }));
    reloadOrderData(order_id);
  }

  // sort table
  const sortTable = (headingIndex, direction) => {
    switch (headingIndex) {
      case 6:
        filterCallback({
          sort: `createdAt:${direction === 'descending' ? 'desc' : 'asc'}`,
        })
        break;

      default:
        break;
    }
  }

  /**
   * Order status
   */

  const [showPopupForChangeOrderStatus, setShowPopupForChangeOrderStatus] = useState('');

  const changeOrderStatus = useCallback((order_id: string, new_status: OrderStatus) => {
    setShowPopupForChangeOrderStatus('');
    if (new_status === OrderStatus.LOCK) {
      dispatch(setOrderFinish({
        order_id: order_id
      }))
    } else {
      dispatch(setStatusEntity({
        order_id: order_id,
        order_status: new_status
      })).unwrap().then((res) => {
        if (res.data) {
          dispatch(getSumarizeOrderForAdmin());
        }
      }).catch((e) => {
        console.log("E125 >>>>", e);
      });;
    }

  }, []);

  /**
   * Order payment status
   */

  const [showPopupForChangePaymentStatus, setShowPopupForChangePaymentStatus] = useState('');

  const changePaymentStatus = useCallback((order_id: string, new_status: PaymentStatus) => {
    setShowPopupForChangePaymentStatus('');
    dispatch(updateEntity({
      order_id: order_id,
      payment_status: new_status
    }))
  }, []);


  /**
  * END order Status
  */

  const EmptyData = () => {

    return (
      <EmptyState heading="Dữ liệu trống" image={emptyIMG}>
        <p>Không tìm thấy danh sách đơn hàng</p>
      </EmptyState>
    )
  }

  const successNotice = updateSuccess ? (
    <Toast content={'Cập nhật trạng thái thành công!'} onDismiss={clearErrorCallback} />
  ) : null;

  const renderItem = (orderDetail: TypedOrder) => {
    const {
      order_pnr,
      user,
      order_total_price,
      createdAt,
      order_status,
      order_id,
      locked,
      orders_fullfillment,
      order_delivery_type,
      payment_status,
      order_note,
      stock,
      order_total_mustpay
    } = orderDetail;
    let fullfillmentData = orders_fullfillment[0] || null;
    let order_fullfillment_status = fullfillmentData?.order_fullfillment_status;
    let order_fullfillment_error = fullfillmentData?.order_fullfillment_error;
    return [
      order_id,
      <Popover
        active={showActionList === order_id}
        activator={<Button variant="plain" disclosure={'down'} onClick={() => togglePophoverActionListCallback(order_id)}>{order_pnr}</Button>}
        autofocusTarget="first-node"
        onClose={() => onCloseActionList(order_id)}
      >
        <ActionList
          actionRole="menuitem"
          sections={[
            {
              title: 'Chung',
              items: [
                {
                  content: 'Xem chi tiết',
                  icon: ViewIcon,
                  onAction: () => history('/orders/view/' + order_id)
                },
                {
                  content: 'In Packing Slip',
                  icon: PrintIcon,
                  onAction: () => history(process.env.REACT_APP_AJAX_URL + '/orders/packing_slip/print/' + order_id)
                },
              ]
            },
          ]}

        />
      </Popover>,
      <span>{user?.display_name ?? '-'}</span>,

      // Xử lý trạng thái thanh toán
      (['admin', 'super_admin', 'accountant'].includes(account?.user_role)) && order_status !== OrderStatus.COMPLETED ?
        <Popover
          active={showPopupForChangePaymentStatus === order_id}
          activator={<>
            <span style={{ cursor: 'pointer' }} onClick={() => setShowPopupForChangePaymentStatus(order_id)}>
              <InlineStack>
                {
                  getPaymentStatusBadge(payment_status)
                }
                <Icon
                  source={ChevronDownIcon}
                  tone="base"
                />
              </InlineStack>
            </span>
          </>}
          autofocusTarget="first-node"
          onClose={() => setShowPopupForChangePaymentStatus('')}
        >
          <div style={{ padding: 10 }}>
            {
              (payment_status == PaymentStatus.UNPAID || payment_status == PaymentStatus.UNKNOW) ?
                renderItemPaymentStatus(`Đã thanh toán`, order_id, PaymentStatus.PAID, 'success')
                : renderItemPaymentStatus(`Chưa thanh toán`, order_id, PaymentStatus.UNPAID, 'critical-strong')
            }
          </div>
        </Popover> :
        <span>{getPaymentStatusBadge(payment_status)}</span>,

      // Xử lý trạng thái vận chuyển đơn sau khi đã thanh toán
        order_status === OrderStatus.CANCEL ||
        order_status === OrderStatus.CUSTOMER_CANCEL ||
        order_status === OrderStatus.RETURNED ||
        locked === LockedOrder.LOCKED
        ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{locked === LockedOrder.LOCKED ? <Badge tone="enabled">{__(`order_lock`)}</Badge> : getOrderStatusBadge(order_status)}</span>
            <span className="small-icon">{
              order_status === OrderStatus.CANCEL ||
              order_status === OrderStatus.CUSTOMER_CANCEL ||
              order_status === OrderStatus.RETURNED ||
              locked === LockedOrder.LOCKED
              ? <Tooltip content={"Bạn không có quyền thay đổi trạng thái , cần liên hệ với quản lý"}><Text as="span"><LockIcon /></Text></Tooltip> : ''}</span>
          </div>
        ) : (
          <Popover
            active={showPopupForChangeOrderStatus === order_id}
            activator={<>
              <span style={{ cursor: 'pointer' }} onClick={() => setShowPopupForChangeOrderStatus(order_id)}>
                <InlineStack>
                  {
                    getOrderStatusBadge(order_status)
                  }
                  <Icon
                    source={ChevronDownIcon}
                    tone="base"
                  />
                </InlineStack>
              </span>
            </>}
            autofocusTarget="first-node"
            onClose={() => setShowPopupForChangeOrderStatus('')}
          >
            <div style={{ padding: 10 }}>
              {order_status !== OrderStatus.COMPLETED && renderItemOrderStatus(__(`order_status_draft`), order_id, OrderStatus.DRAFT, 'attention', order_status === OrderStatus.DRAFT)}
              {order_status !== OrderStatus.COMPLETED && renderItemOrderStatus(__(`order_status_approved`), order_id, OrderStatus.APPROVED, 'success', order_status === OrderStatus.APPROVED)}
              {(payment_status === PaymentStatus.PAID && order_status !== OrderStatus.COMPLETED) && renderItemOrderStatus(__(`order_status_transporting`), order_id, OrderStatus.TRANSPORTING, 'info', order_status === OrderStatus.TRANSPORTING)}
              {(payment_status === PaymentStatus.PAID && order_status !== OrderStatus.COMPLETED) && renderItemOrderStatus(__(`order_status_completed`), order_id, OrderStatus.COMPLETED, 'success-strong')}
              {(payment_status === PaymentStatus.PAID && order_status !== OrderStatus.COMPLETED) && renderItemOrderStatus(__(`order_status_cancel`), order_id, OrderStatus.CANCEL, 'critical')}
              {(payment_status === PaymentStatus.PAID && order_status !== OrderStatus.COMPLETED) && renderItemOrderStatus(__(`order_status_customer_cancel`), order_id, OrderStatus.CUSTOMER_CANCEL, 'critical')}
              {/* {(payment_status === PaymentStatus.PAID && order_status === OrderStatus.COMPLETED) && renderItemOrderStatus(__(`order_status_refunded`), order_id, OrderStatus.REFUNDED, 'warning')} */}
              {(payment_status === PaymentStatus.PAID && order_status === OrderStatus.COMPLETED) && renderItemOrderStatus(__(`order_status_returned`), order_id, OrderStatus.RETURNED, 'critical-strong')}
              {(payment_status === PaymentStatus.PAID && order_status === OrderStatus.COMPLETED) && renderItemOrderStatus(__(`order_lock`), order_id, OrderStatus.LOCK, 'enabled')}
            </div>
          </Popover>
        ),
      <span>{helpers.formatNumber(order_total_mustpay, '.')} vnđ</span>,
      createdAt ? dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm') : '-',
      stock.stock_name,
      getOrderDeliveryMethod(order_delivery_type),
      <div className="sale_order_note">
        <Popover
          active={showPophoverOrderNote === order_id}
          activator={
            <Link removeUnderline onClick={() => togglePophoverOrderNoteCallback(order_id, order_note)}>
              {order_note || <><Badge></Badge><Badge></Badge></>}
            </Link>
          }
          onClose={() => onClosePophoverOderNoteCallback(order_id)}
          ariaHaspopup={false}
          sectioned
        >
          <NoteComponent label="Ghi chú đơn hàng" default_string={noteForOrder} onSave={submitNoteOrderCallback} args={order_id} />
        </Popover>
      </div>,
    ];
  };

  const OrderList =
    <DataTable
      sortable={[false, false, false, false, false, false, true, false, false, false]}
      onSort={(headingIndex, direction) => sortTable(headingIndex, direction)}
      defaultSortDirection="descending"
      initialSortColumnIndex={0}
      columnContentTypes={[
        "numeric",
        "text",
        "text",
        "text",
        "text",
        "text",
        "text",
      ]}
      headings={[
        "Mã",
        "PNR",
        "Khách hàng",
        "Trạng thái thanh toán",
        <div style={{ display: 'flex' }}>
          {showImgStruct && <img style={{ maxWidth: helpers.isMobile() ? 350 : 450, objectFit: 'contain', overflow: 'hidden', zIndex: 9999, position: 'fixed', margin: '2% auto', left: 5, right: 5 }} src={instructImg} />}
          <span style={{ cursor: 'pointer' }} onClick={() => setShowImgStruct(true)} onMouseOut={() => setShowImgStruct(false)}>
            <Icon
              source={AlertCircleIcon}
              tone="base"
            />
          </span>
          <span>Tình trạng đơn hàng</span>
        </div>,
        "Giá trị",
        "Ngày tạo",
        "Kho vận",
        "Vận chuyển",
        "Ghi chú",
      ]}
      rows={entities.map(res => renderItem(res))}
      hideScrollIndicator
      // fixedFirstColumns={1}
      stickyHeader
      firstColumnMinWidth="100px"
      footerContent={`Display page ${mainQuery.page} of total ${totalItems} results...`}

    />


  return (
    <>
      {/* {
        showBulkOrderAction ? <BulkModal onClose={() => setShowBulkOrderAction(false)} /> : null
      } */}
      {successNotice}
      {(loading || updating) ? <Loading /> : null}
      <Page
        fullWidth
        title="Danh sách đơn hàng"
        subtitle="Quản lý và chỉnh sửa danh sách đơn hàng"
        compactTitle
        // primaryAction={{
        //   content: 'Thêm đơn hàng',
        //   loading: updating,
        //   onAction: createNewEmptyOrder
        // }}
        secondaryActions={[
          // {
          //   content: 'Đối soát hàng loạt',
          //   icon: ListBulletedIcon,
          //   onAction: () => setShowBulkOrderAction(true),
          // }
        ]}
      >
        <Card>
          <SimpleFilter loading={loading}
            onCallback={filterCallback}
            options={[
              { label: `Chưa thanh toán`, value: `${PaymentStatus.UNPAID.toString()}`, field: 'payment_status' },
              { label: `Đã thanh toán`, value: PaymentStatus.PAID.toString(), field: 'payment_status' },
              { label: `${__('order_status_draft')} (${sumarizeOrder?.draft ?? 0})`, value: OrderStatus.DRAFT, field: 'order_status' },
              { label: `${__('order_status_approved')} (${sumarizeOrder?.approved ?? 0})`, value: OrderStatus.APPROVED, field: 'order_status' },
              { label: `${__('order_status_transporting')} (${sumarizeOrder?.transporting ?? 0})`, value: OrderStatus.TRANSPORTING, field: 'order_status' },
              { label: `${__('order_status_completed')} (${sumarizeOrder?.completed ?? 0})`, value: OrderStatus.COMPLETED, field: 'order_status' },
              { label: `${__('order_status_cancel')} (${sumarizeOrder?.cancel ?? 0})`, value: OrderStatus.CANCEL, field: 'order_status' },
              { label: `${__('order_status_customer_cancel')} (${sumarizeOrder?.customer_cancel ?? 0})`, value: OrderStatus.CUSTOMER_CANCEL, field: 'order_status' },
              { label: `${__('order_status_refunded')} (${sumarizeOrder?.refunded ?? 0})`, value: OrderStatus.REFUNDED, field: 'order_status' },
              { label: `${__('order_status_returned')} (${sumarizeOrder?.returned ?? 0})`, value: OrderStatus.RETURNED, field: 'order_status' },
            ]}
            sortField={[
              { label: 'Giá sản phẩm', field: 'product_price' },
              { label: 'Tồn kho', field: 'product_quantity' },
              { label: 'Đã bán', field: 'product_sold_quantity' },
            ]}
            totalItems={totalItems}
          />
          {entities.length > 0 ?
            OrderList
            : <EmptyData />
          }
          {totalItems > mainQuery.limit ? (
            <Pagination
              TotalRecord={totalItems}
              activeCurrentPage={Number(mainQuery?.page)}
              pageSize={mainQuery?.limit}
              onChange={onChangePageNumber}
            />
          ) : null}
        </Card>
      </Page>
      <br />
      <br />
    </>
  );

}










