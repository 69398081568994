import { useParams } from "react-router-dom";
import Theme404 from '../../layout/404';

import users_list from './users.internal';
import users_view from './users.internal.new';

export default  function UserInternalIndex() {
    let useParam =  {} as any;
        useParam = useParams();
    let Param = useParam.slug || 'list';
    let ActualPage: any;
    switch (Param) {
        
        case 'list':
            ActualPage = users_list;
        break;

        case 'view':
            ActualPage = users_view;
        break;

        case 'edit':
            ActualPage = users_view;
        break;

        case 'new':
            ActualPage = users_view;
        break;

        default:
            ActualPage =  Theme404;
        break;
    }

    return (
        <>
            {<ActualPage />}
        </>
    );
}
