import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';


/**
*   Reducer used for front-end, with order.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using order.model.ts
*/



interface TypedOrder_fullfillment {
    order_fullfillment_id?: string; 
	order_id?: string; 
	logistic_id?: string; 
    order_fullfillment_status?: number; 
	order_fullfillment_code?: string; 
	createdAt?: string; 
	createdBy?: string; 
	updatedAt?: string; 
    logistic_service?: any
}

const initialState = {
  loading: false,
  errorMessage: null as any,
  entity: null as TypedOrder_fullfillment,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'order_fullfillment';


export const getEntity = createAsyncThunk(
  'order_fullfillment/fetch_entity',
  async (order_id: string | number) => {
    return await axios.get<any>(`${apiUrl}/${order_id}`);
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'order_fullfillment/update_entity',
  async (entity: TypedOrder_fullfillment, thunkAPI) => {
    // patch
    let order_id = String(entity.order_id);
    delete entity.order_id;
    const result = await axios.patch<TypedOrder_fullfillment>(`${apiUrl}/${order_id}`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_order_fullfillment = createEntitySlice({
  name: 'order_fullfillment',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(getEntity.pending, (state, action) => {
        state.loading = true;
        state.entity = null;
      })

      .addCase(updateEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.entity = action.payload.data;
      })
      .addCase(updateEntity.rejected, (state, action) => {
        state.updating = false;
      })
      .addCase(updateEntity.pending, (state, action) => {
        state.updating = true;
      })
  },
});

export const { clearError, reset } = Reducer_order_fullfillment.actions;

// Reducer
export default Reducer_order_fullfillment.reducer;







