import {
  HomeIcon,
  TeamIcon,
  ProductIcon,
  InventoryIcon,
  ContentIcon,
  ReceiptIcon,
  ChartCohortIcon,
  EditIcon,
  DeleteIcon,
  PlusCircleIcon,
  CollectionReferenceIcon,
  TransferInternalIcon,
  CategoriesIcon,
  CollectionListIcon,
  ChartDonutIcon,
  CartDownIcon,
  PriceListFilledIcon,
} from "@shopify/polaris-icons";
import { Navigation, Text, BlockStack, Modal, TextContainer, TextField, RadioButton } from "@shopify/polaris";
import { useAppDispatch, useAppSelector } from "./store";
import { useLocation } from "react-router-dom";
import __, { ___ } from "languages/index";
import { default as packageInformation } from '../../package.json';
import helpers from "helpers/index";
import { useCallback, useEffect, useState } from "react";
import { createEntities, deleteEntity, getEntities, updateEntities } from "store/group_user.store.reducer";
import { SecondaryAction } from "@shopify/polaris/build/ts/src/components/Navigation/types";
import { useField, useForm } from "@shopify/react-form";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { GROUP_APPROVED_STATUS } from "constant";

/**
 * START ...
 * @returns
 */
export default function Sidebar() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const entities = useAppSelector(state => state.group_user.entities);
  const account = useAppSelector(state => state.user.account);
  const [userRole, setUserRole] = useState('')
  const version = process.env.REACT_APP_VERSION;
  const polarisVersion = packageInformation['dependencies']["@shopify/polaris"] || '';

  const [menuStaff, setMenuStaff] = useState({
    label: "Hệ thống",
    url: '/group_user',
    icon: ChartCohortIcon,
    subNavigationItems: []
  });
  // active modal thêm group user menu
  const [active, setActive] = useState(false);
  // active modal xóa group user menu
  const [activeModalComfirm, setActiveModalComfirm] = useState(false);
  const [groupId, setGroupId] = useState('');
  const [memberStatusInGroup, setMemberStatusInGroup] = useState(GROUP_APPROVED_STATUS.APPROVED_REQUIRE);

  const generalMenu: any[] = [
    {
      url: "/",
      label: "Trang chủ",
      icon: HomeIcon,
      new: true,
      exactMatch: true,
    },

  ]

  const userInternal: any[] = [
    {
      url: "/user_internal",
      label: "Nhân sự nội bộ",
      icon: TransferInternalIcon,
      disabled: !(['admin', 'super_admin', 'accountant'].includes(userRole))
    },

  ]

  const userMenu: any[] = [
    {
      url: "/customer",
      label: "Người dùng vào App",
      icon: TeamIcon,
      disabled: !(['admin', 'super_admin', 'accountant'].includes(userRole))
    },
    {
      url: "/user_referrer",
      label: "Danh sách giới thiệu",
      icon: CollectionReferenceIcon,
      disabled: !(['admin', 'super_admin', 'accountant'].includes(userRole))
    }
  ];

  const paymentManager: any[] = [
    {
      url: "/withdraw",
      label: "Duyệt đề nghị thanh toán",
      icon: ReceiptIcon,
      disabled: !(['admin', 'super_admin', 'accountant'].includes(userRole))
    },
  ]

  const productMenu: any[] = [
    {
      url: "/product",
      label: "Danh sách sản phẩm",
      icon: ProductIcon,
      disabled: !(['admin', 'super_admin', 'accountant', 'sale'].includes(userRole))
    },
    {
      url: "/product_category",
      label: "Danh mục sản phẩm",
      icon: CategoriesIcon,
      disabled: !(['admin', 'super_admin', 'accountant', 'sale'].includes(userRole))
    },
    {
      url: "/product_collection",
      label: "Bộ sưu tập sản phẩm",
      icon: CollectionListIcon,
      disabled: !(['admin', 'super_admin', 'accountant', 'sale'].includes(userRole))
    },
    {
      url: "/stock",
      label: "Kho hàng",
      icon: InventoryIcon,
      disabled: !(['admin', 'super_admin', 'accountant', 'stock_manager'].includes(userRole))
    },
    //Chức năng hoa hồng ốp cho sản phẩm ( chưa sử dụng đến , đã có code )
    // {
    //   url: "/commission",
    //   label: "Hoa hồng",
    //   icon: TipJarIcon,
    //   disabled: !(['admin', 'super_admin', 'accountant'].includes(userRole))
    // },
    //Chức năng hoa hồng ốp cho người giới thiệu ( chưa sử dụng đến , đã có code )
    // {
    //   url: "/user_referrer_commission",
    //   label: "Hoa hồng",
    //   icon: TipJarIcon,
    //   disabled: !(['admin', 'super_admin', 'accountant'].includes(userRole))
    // },
    // Chức năng ốp cho nhóm hệ hống user_group ( chưa sử dụng đến , đã có code lý do đang fix cứng công thức cho behapy)
    // {
    //   url: "/user_group_commission",
    //   label: "Hoa hồng",
    //   icon: TipJarIcon,
    //   disabled: !(['admin', 'super_admin', 'accountant'].includes(userRole))
    // },
    // {
    //   url: "/combo",
    //   label: "Combo",
    //   icon: PackageIcon,
    // }
  ];

  const orderMenu: any[] = [
    {
      url: "/orders",
      label: "Danh sách đơn hàng",
      icon: ReceiptIcon,
      disabled: !(['admin', 'super_admin', 'accountant', 'sale', 'accountant'].includes(userRole))
    }
  ]

  const settings: any[] = [
    {
      url: "/setting_banner",
      label: "Cài đặt banner",
      icon: ContentIcon,
      disabled: !(['admin', 'super_admin', 'accountant'].includes(userRole))
    }
  ]

  const reportMenus: any[] = [
    {
      url: "/reports_product",
      label: "Sản phẩm",
      icon: ChartDonutIcon
    },
    {
      url: "/reports_orders",
      label: "Đơn hàng",
      icon: CartDownIcon
    },
    {
      url: "/reports_revenue",
      label: "Doanh thu",
      icon: PriceListFilledIcon
    },
  ]

  useEffect(() => {
    account && setUserRole(account?.user_role)
  }, [account])

  useEffect(() => {
    dispatch(getEntities({}))
  }, []);

  // khởi tạo menu của phần hệ thống
  useEffect(() => {
    if (!helpers.isEmpty(entities)) {
      let subNavigationItems: {
        label: string;
        url: string;
        onClick?: () => void;
        secondaryActions?: SecondaryAction[]
      }[] = [];

      const userGroupMenu = {
        label: "Danh sách đại lý",
        url: `/group_user/list`,
        icon: ChartCohortIcon,
        subNavigationItems: [],
        disabled: !(['admin', 'super_admin', 'accountant'].includes(userRole)),
        secondaryAction: (['admin', 'super_admin'].includes(userRole)) && {
          url: '',
          accessibilityLabel: 'Thêm nhóm',
          icon: PlusCircleIcon,
          tooltip: {
            content: 'Thêm nhóm',
          },
          onClick: () => setActive(true)
        },
      };


      (['admin', 'super_admin', 'accountant'].includes(userRole)) && entities?.forEach((res) => {
        subNavigationItems.push({
          url: `/group_user/${res.group_id}`,
          label: `${res.group_name}`,
          secondaryActions: (['admin', 'super_admin'].includes(userRole)) && ( res.group_total_member === 0 ? [
            {
              accessibilityLabel: 'edit group name',
              icon: EditIcon,
              tooltip: {
                content: 'Sửa tên nhóm',
              },
              onClick: () => {
                setGroupId(res.group_id);
                fields.group_name.onChange(res.group_name);
                fields.join_must_pay.onChange(res.join_must_pay);
                fields.group_description.onChange(res.group_description);
                setMemberStatusInGroup(+res.join_must_approved)
                setActive(true);
              }
            },
            {
              accessibilityLabel: 'delete group',
              icon: DeleteIcon,
              tooltip: {
                content: 'Chỉ có thể xóa nhóm khi nhóm không có người dùng',
              },
              onClick: () => {
                console.log('res.group_total_member', res.group_total_member === 0)
                if (res.group_total_member === 0) {
                  setGroupId(res.group_id)
                  setActiveModalComfirm(true)
                }
              },

            }
          ] : [
            {
              accessibilityLabel: 'edit group name',
              icon: EditIcon,
              tooltip: {
                content: 'Sửa tên nhóm',
              },
              onClick: () => {
                setGroupId(res.group_id);
                fields.group_name.onChange(res.group_name);
                fields.join_must_pay.onChange(res.join_must_pay);
                fields.group_description.onChange(res.group_description);
                setMemberStatusInGroup(+res.join_must_approved)
                setActive(true);
              }
            }
          ]),
        })
      });
      userGroupMenu.subNavigationItems = [{
        url: "/group_user/list",
        label: "Danh sách chờ duyệt",
      }, ...subNavigationItems];

      setMenuStaff(userGroupMenu);
    } else {
      let subNavigationItems: {
        label: string;
        url: string;
        onClick?: () => void;
        secondaryActions?: SecondaryAction[]
      }[] = [];

      const userGroupMenu = {
        label: "Hệ thống người dùng",
        url: `/group_user/list`,
        icon: ChartCohortIcon,
        subNavigationItems: [],
        disabled: !(['admin', 'super_admin', 'accountant'].includes(userRole)),
        secondaryAction: {
          url: '',
          accessibilityLabel: 'Thêm nhóm',
          icon: PlusCircleIcon,
          tooltip: {
            content: 'Thêm nhóm',
          },
          onClick: () => setActive(true)
        },
      };

      setMenuStaff(userGroupMenu);
    }
  }, [entities, userRole])


  const { submit, fields, reset } = useForm({
    fields: {
      group_name: useField<string>({
        value: "",
        validates: [
          inputValue => {
            if (!inputValue) {
              return __('require');
            }
          }
        ],
      }),
      join_must_pay: useField<string>({
        value: "",
        validates: [
          inputValue => {
            if (!inputValue) {
              return __('require');
            }
          }
        ],
      }),

      join_must_approved: useField<boolean>({
        value: true,
        validates: [
          inputValue => {
            if (!inputValue) {
              return __('require');
            }
          }
        ],
      }),

      group_description: useField<string>({
        value: "",
        validates: [],
      }),
    },
    async onSubmit(value) {
      try {
        if (groupId) {
          dispatch(updateEntities({
            ...value,
            join_must_pay: helpers.parseNumeric(value.join_must_pay),
            join_must_approved: memberStatusInGroup,
            group_id: groupId,
          }));
        } else {
          dispatch(createEntities({ 
            ...value,
            join_must_pay: helpers.parseNumeric(value.join_must_pay),
            join_must_approved: memberStatusInGroup
          }));
        }

        handleOnClose();
        return { status: 'success' };
      } catch (e: any) {
        console.error(`Submit error E356`, e);
        const message = e?.response?.data?.title ?? 'Lỗi không xác định, vui lòng thử lại sau.';
        const field = e?.response?.data?.errorKey ?? 'base';
        return { status: 'fail', errors: [{ field, message }] };
      }
    }
  });

  const handleDeleteGroupUser = useCallback((group_id) => {
    dispatch(deleteEntity({ group_id }))
    handleOnClose();
  }, [entities]);

  const handleOnClose = useCallback(() => {
    setGroupId('');
    reset()
    setActive(false);
    setActiveModalComfirm(false);
    setMemberStatusInGroup(GROUP_APPROVED_STATUS.APPROVED_REQUIRE);
  }, []);

  return (
    <Navigation location={location.pathname}>

      <Navigation.Section
        items={generalMenu}
      />
      <Navigation.Section
        items={userInternal}
      />
      <Navigation.Section
        title="Quản lí đại lý"
        separator
        items={[
          ...userMenu,
          menuStaff
        ]}
      />
      <Navigation.Section
        title="Sản phẩm"
        separator
        items={productMenu}
      />
      <Navigation.Section
        title="Đơn hàng"
        separator
        items={orderMenu}
      />
     <Navigation.Section
        title="Thanh toán"
        separator
        items={paymentManager}
      />
      <Navigation.Section
        title="Báo cáo"
        separator
        items={[
          ...reportMenus,
          entities.length > 0 && {
            label: "Báo cáo đại lý",
            url: `/reports_group_user/1`,
            icon: ChartCohortIcon,
            subNavigationItems: entities?.map((val) => {
              return {
                url: `/reports_group_user/${val.group_id}`,
                label: `${val.group_name}`,
              }
            }),
          },
        ]}
      /> 
      <br />
      <br />
      <Navigation.Section
        title="Cài đặt"
        separator
        items={settings}
      />
      <br />
      <br />
      <br />
      <br />
      {/* vd phan quyen sidebar */}
      {/* <Capacity current_user_can="admin">
      <Navigation.Section
          title="Quản trị CRM"
          separator
          items={adminMenuItems}
        />

    </Capacity> */}

      <Navigation.Section fill items={[]} />

      <BlockStack align="center" inlineAlign="center" gap={"200"}>
        <Text as="p" variant="bodySm" tone="subdued">
          Version {version}
          <br />
          {
            helpers.isDev() && (
              `Polaris Framework ver ${polarisVersion}`
            )
          }
        </Text>
        <br />
        <br />
      </BlockStack>

      {/* modal thêm nhóm người dùng */}
      <Modal
        open={active}
        onClose={handleOnClose}
        title={groupId ? "Sửa nhóm người dùng hệ thống" : "Thêm nhóm người dùng hệ thống"}
        primaryAction={{
          content: groupId ? "Sửa" : 'Thêm',
          onAction: submit,
          disabled: !fields.group_name || !fields.join_must_pay
        }}
        secondaryActions={[
          {
            content: 'Hủy',
            onAction: handleOnClose,
          },
        ]}
      >
        <Modal.Section>
          <TextField
            label="Tên nhóm"
            autoComplete={'off'}
            autoFocus={true}
            {...fields.group_name}
          />

          <br />
          <TextField
            label="Số tiền gia nhập nhóm"
            suffix="vnđ"
            maxLength={12}
            type={'text'}
            autoComplete="off"
            value={helpers.formatNumberWithCommas(fields.join_must_pay.value)}
            onChange={(a) => fields.join_must_pay.onChange(a)}
            error={fields.join_must_pay.error}
          />
          <br />
          <div>
            <RadioButton
              label="Bắt buộc quản lý phê duyệt vào hệ thống bán hàng"
              id={`approve_require`}
              name="group_approved"
              checked={memberStatusInGroup === GROUP_APPROVED_STATUS.APPROVED_REQUIRE}
              onChange={() => setMemberStatusInGroup(GROUP_APPROVED_STATUS.APPROVED_REQUIRE)}
            />
          </div>
          <div>
            <RadioButton
              label="Không bắt buộc quản lý phê duyệt vào hệ thống bán hàng"
              id={`approve_not_require`}
              name="group_approved"
              checked={memberStatusInGroup === GROUP_APPROVED_STATUS.APPROVED_NOT_REQQUIRE}
              onChange={() => setMemberStatusInGroup(GROUP_APPROVED_STATUS.APPROVED_NOT_REQQUIRE)}
            />

          </div>
          <br />
          <div>
            <Text as="span" fontWeight='bold'>Mô tả</Text>
            <CKEditor
              editor={ClassicEditor}
              data={fields?.group_description?.value}
              onChange={(event, editor) => {
                fields?.group_description?.onChange(editor.getData())
              }}
              config={{
                placeholder: `Mô tả`,
                plugins: ['Bold', 'Italic', 'Heading', 'Link', 'List', 'Table', 'TableToolbar'],
              }}

            />
          </div>
        </Modal.Section>
      </Modal>

      <Modal
        title="Reach more shoppers with Instagram product tags"
        titleHidden
        open={activeModalComfirm}
        onClose={handleOnClose}
        primaryAction={{
          content: 'Xóa',
          onAction: () => handleDeleteGroupUser(groupId),
        }}
        secondaryActions={[
          {
            content: 'Hủy',
            onAction: handleOnClose,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              Bạn có muốn xóa không?
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </Navigation >

  );
}