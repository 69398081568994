import axios from 'axios';
import { createAsyncThunk, isFulfilled,  } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { ENDPOINT_URL } from 'constant';
import { TypeDataUserGroupCommission } from 'interface/user_group_comission.model';
/**
*   Reducer used for front-end, with media.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using media.model.ts
*/


// interface getQuery extends IQueryParams, TypedProduct{};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: null as any as TypeDataUserGroupCommission[],
  entity: null as  TypeDataUserGroupCommission,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};
const apiUrlReferrerCommission = `${ENDPOINT_URL}/user_group_commission`;

//get user group commissions
export const getEntities = createAsyncThunk('commission/fetch_entity_list', async (object: TypeDataUserGroupCommission) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrlReferrerCommission}${EndURL}`;
  return await axios.get<TypeDataUserGroupCommission[]>(requestUrl);
},
{ serializeError: serializeAxiosError }
);

//get user group commission rule
export const getEntity = createAsyncThunk('userGroupCommission/fetch_entity_user_referrer_commission', async (entity?:any) => {
  const { user_group_commission_id } = entity
  const requestUrl = `${apiUrlReferrerCommission}/${user_group_commission_id}`;
  return await axios.get<TypeDataUserGroupCommission[]>(requestUrl);
},
{ serializeError: serializeAxiosError }
);

// create user group commission rule
export const createUserGroupCommission = createAsyncThunk(
  'userGroupCommission/create_user_referrer_commission',
  async (entity:any, thunkAPI) => {
    const result = await axios.post<TypeDataUserGroupCommission>(`${apiUrlReferrerCommission}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// edit user group commission rule
export const editUserGroupCommission = createAsyncThunk(
  'userGroupCommission/edit_user_referrer_commission',
  async (entity: any, thunkAPI) => {
    const { user_group_commission_id, ...dataForm }  = entity
    const result = await axios.patch<TypeDataUserGroupCommission>(`${apiUrlReferrerCommission}/${user_group_commission_id}`, helpers.cleanEntity(dataForm));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);


// delete user group commission rule
export const deleteUserGroupCommission = createAsyncThunk(
  'userGroupCommission/delete_user_referrer_commission_rule',
  async (user_group_commission_id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrlReferrerCommission}/${user_group_commission_id}`;
    thunkAPI.dispatch(getEntities({}));
    return await axios.delete<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);



// Reducer
export const Reducer_User_Group_Commission = createEntitySlice({
  name: 'userGroupCommission',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage  = null;
      state.updateSuccess  = false;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = (action.payload as any).data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Hiển thị nhóm hoa hồng không thành công";
      })

      .addCase(deleteUserGroupCommission.pending, (state, action) => {
        state.loading = true;
        state.updateSuccess  = false;
      })
      .addCase(deleteUserGroupCommission.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess  = true;
      })
      .addCase(deleteUserGroupCommission.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Xoá kiểu hoa hồng không thành công";
        state.updateSuccess  = false;
      })

      .addCase(createUserGroupCommission.pending, (state, action) => {
        state.loading = true;
        state.updateSuccess  = false;
      })
      .addCase(createUserGroupCommission.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess  = true;
      })
      .addCase(createUserGroupCommission.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Thêm nhóm hoa hồng không thành công";
        state.updateSuccess  = false;
      })

      .addCase(editUserGroupCommission.pending, (state, action) => {
        state.loading = true;
        state.updating = true;
        state.updateSuccess  = false;
      })
      .addCase(editUserGroupCommission.fulfilled, (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess  = true;
      })
      .addCase(editUserGroupCommission.rejected, (state, action) => {
        state.loading = false;
        state.updating = false;
        state.errorMessage = "Sửa nhóm hoa hồng không thành công";
        state.updateSuccess  = false;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: (action.payload as any).data,
          entity: null,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
  },
});

export const { clearError, reset } = Reducer_User_Group_Commission.actions;

// Reducer
export default Reducer_User_Group_Commission.reducer;
