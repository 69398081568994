import axios from 'axios';
import { createAsyncThunk, isFulfilled, } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { TypeBanner } from '../interface/setting_banner.model';
import { ENDPOINT_URL } from 'constant';

/**
*   Reducer used for front-end, with media.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using media.model.ts
*/


// interface getQuery extends IQueryParams, TypedProduct{};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: null as any as TypeBanner[],
  entity: null as TypeBanner,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};
const apiUrl = `${ENDPOINT_URL}/banner`;

// Actions
export const getBanners = createAsyncThunk('setting_banner/fetch_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return axios.get<TypeBanner[]>(requestUrl);
});

export const getEntity = createAsyncThunk('setting_banner/fetch_entity', async (banner_id: string) => {
  const requestUrl = `${apiUrl}/${banner_id}`;
  return axios.get<TypeBanner>(requestUrl);
},
  { serializeError: serializeAxiosError }
);

// edit banner
export const editBanner = createAsyncThunk(
  'setting_banner/edit_banner',
  async (entity: any, thunkAPI) => {
    const { banner_id, ...dataForm } = entity
    const result = await axios.patch<TypeBanner>(`${apiUrl}/${banner_id}`, helpers.cleanEntity(dataForm));
    thunkAPI.dispatch(getBanners({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// delete banner
export const deleteBanner = createAsyncThunk(
  'setting_banner/delete_banner',
  async (banner_id: any, thunkAPI) => {
    const result = await axios.delete<TypeBanner>(`${apiUrl}/${banner_id}`);
    thunkAPI.dispatch(getBanners({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// create banner
export const createBanner = createAsyncThunk(
  'setting_banner/create_banner',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<TypeBanner>(`${apiUrl}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getBanners({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const Reducer_Setting_Banner = createEntitySlice({
  name: 'setting_banner',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = false;
    }
  },
  extraReducers(builder) {
    builder

      .addCase(createBanner.pending, (state, action) => {
        state.loading = true;
        state.updateSuccess = false;
      })
      .addCase(createBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess = true;
      })
      .addCase(createBanner.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Thêm banner không thành công";
        state.updateSuccess = false;
      })

      .addCase(editBanner.pending, (state, action) => {
        state.loading = true;
        state.updating = true;
        state.updateSuccess = false;
      })
      .addCase(editBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(editBanner.rejected, (state, action) => {
        state.loading = false;
        state.updating = false;
        state.errorMessage = "Sửa banner không thành công";
        state.updateSuccess = false;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.entity = action.payload.data;
        state.loading = false;
      })
      .addMatcher(isFulfilled(getBanners), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: (action.payload as any).data,
          entity: null,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
  },
});

export const { clearError, reset } = Reducer_Setting_Banner.actions;

// Reducer
export default Reducer_Setting_Banner.reducer;

export const settingBannerSelector = (store) => store.setting_banner.entities;