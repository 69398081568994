import React from 'react';
import { Form, FormLayout, Modal, BlockStack, TextField } from '@shopify/polaris';
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "config/store";
import { clearError, createEntity } from "store/to_do_list.reducer";
import { useField, useForm } from '@shopify/react-form';

export default function TodoQuickAdd( {show = false, onClose = null, prefill = '', onSave = null} ) {

    const entities = useAppSelector((state) => state.to_do_list.entities);
    const errorMessage = useAppSelector((state) => state.to_do_list.errorMessage);
    const updating = useAppSelector((state) => state.to_do_list.updating);
    const updateSuccess = useAppSelector((state) => state.to_do_list.updateSuccess);
  
    const dispatch = useAppDispatch();
    useEffect( () => {
        dispatch(clearError());
    }, []);

    /**
     * Show or hide
     */
    const [showModal, setShowModal] = useState(show);
    const closeCallback = () => {
        setShowModal(false);
        if ( typeof onClose === 'function') {
            onClose();
        }
    }

    useEffect( () => {
        setShowModal(show);
    }, [show]);
        
    const {
        fields,
        submit,
        reset: formReset,
    } = useForm({
        fields: {
            todo_title: useField<string>({
                value: "",
                validates: [],
            }),
            todo_description: useField<string>({
                value: prefill ?? "",
                validates: [],
            }),
        },

        async onSubmit(values) {
        try {
            dispatch(
                createEntity({
                    todo_title: values.todo_title,
                    todo_description: values.todo_description,
                    status: 0,
                    todo_order: 0
                })
            );
            return { status: "success" };
        } catch (e: any) {
            console.error(`Submit error`, e);
            const message = e?.response?.data?.title ?? "Undefined error. Try again!";
            const field = e?.response?.data?.errorKey ?? "base";
            return { status: "fail", errors: [{ field, message }] };
        }
        },
    });

    /**!SECTION
     * Chỉ khi tạo thành công mới xóa form...
     */
    useEffect( () => {
        if ( updateSuccess ) {
            formReset();
            if ( typeof onSave === 'function') {
                onSave();
            }
        }
    }, [updateSuccess]);



    return (
        <>
      <Modal
        activator={null}
        open={showModal}
        onClose={ closeCallback }
        title="Tạo một công việc"
        primaryAction={{
          content: 'Tạo công việc',
          loading: updating,
          onAction: submit,
        }}
        secondaryActions={[
            {
              content: 'Thoát',
              onAction: closeCallback,
            },
        ]}
      >
        <Modal.Section>
          <BlockStack>
            <Form onSubmit={submit}>
                <FormLayout>

                    <TextField
                        label=""
                        autoFocus
                        placeholder='Tựa đề'
                        autoComplete="off"
                        maxLength={150}
                        {...fields.todo_title}
                    />

                    <TextField
                        label=""
                        autoFocus
                        placeholder='Nội dung của nhắc nhở'
                        multiline={4}
                        autoComplete="off"
                        maxLength={500}
                        showCharacterCount
                        {...fields.todo_description}
                    />

                </FormLayout>
            </Form>
          </BlockStack>
        </Modal.Section>
      </Modal>
        </>
    )
}