import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { IQueryParams, createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { TypedOrder, TypedOrderFee, TypeOrderSumarize } from '../interface/order.model';
import { OrderStatus } from 'enum/order.enum';
import { ENDPOINT_URL } from 'constant';


/**
*   Reducer used for front-end, with order.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using order.model.ts
*/

type bulkUpdateEntitiesResult = {
  success: number,
  fail: number,
  invalidPNR: string[]
}

type bulkUpdateInput = {
  order_pnr: string,
  mode: string,
}

const initialState = {
  loading: false,
  errorMessage: null as any,
  entities: null as TypedOrder[],
  entity: null as TypedOrder,
  newCreateData: null as TypedOrder,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  bulkUpdateEntitiesResult: null as bulkUpdateEntitiesResult,
  bulkUpdateEntitiesLoading: false,
  assignLoading: false,
  addAssigneeLoading: false,
  assignList: [] as any,
  sumarizeOrder: {} as TypeOrderSumarize
};

type getEntityQuery = TypedOrder & IQueryParams;

const apiUrl = `${ENDPOINT_URL}/orders`;

// Actions

export const getEntities = createAsyncThunk('order/fetch_entity_list', async (object: getEntityQuery) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return axios.get<any>(requestUrl);
});


export const getEntity = createAsyncThunk(
  'order/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'order/create_entity',
  async (entity: TypedOrder, thunkAPI) => {
    return await axios.post<TypedOrder>(`${apiUrl}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'order/update_entity',
  async (entity: TypedOrder, thunkAPI) => {
    // patch
    let order_id = String(entity.order_id);
    delete entity.order_id;
    return await axios.put<TypedOrder>(`${apiUrl}/${order_id}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);



export const updatepartialEntity = createAsyncThunk(
  'order/updatepartialEntity',
  async (entity: TypedOrder) => {
    let order_id = String(entity.order_id);
    delete entity.order_id;
    return await axios.patch<TypedOrder>(`${apiUrl}/${order_id}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);


export const bulkUpdateEntities = createAsyncThunk(
  'order/bulkUpdateEntities',
  async (input: bulkUpdateInput) => {
    return await axios.patch<any>(`${apiUrl}/bulkUpdateEntities`, input);
  },
  { serializeError: serializeAxiosError }
);


export const deleteEntity = createAsyncThunk(
  'order/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = axios.delete<any>(requestUrl);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);


/**
 * Assign
 */
export const getAssignList = createAsyncThunk('order/assign/fetch_entity_list', async (order_id: string | bigint) => {
  const requestUrl = `${apiUrl}/assign/${order_id}`;
  return await axios.get<any>(requestUrl);
});

// add
export const addAssignee = createAsyncThunk('order/assign/create_entity', async (entity: { user_id: any, order_id: any }) => {
  return await axios.post<any>(`${apiUrl}/assign/`, helpers.cleanEntity(entity));
});

// deleteAssign
export const deleteAssign = createAsyncThunk('order/assign/delete_entity', async (assign_id: string | bigint) => {
  return await axios.delete<any>(`${apiUrl}/assign/${assign_id}`);
});


export const setStatusEntity = createAsyncThunk(
  'order/set_status_entity',
  async (entity: {
    order_id: string;
    order_status: OrderStatus
  }, thunkAPI) => {
    // patch
    let order_id = String(entity.order_id);
    delete entity.order_id;
    return await axios.patch<TypedOrder>(`${apiUrl}/set_status/${order_id}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const setOrderFullfillment = createAsyncThunk(
  'order/set_logistic',
  async (entity: {
    order_id: string;
    logistic_id: string;
  }, thunkAPI) => {
    // patch
    const result = await axios.post<any>(`${apiUrl}/assign/fullfillment`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const assignProductChangeQuantity = createAsyncThunk(
  'order/assign_change_quantity',
  async (entity: {
    order_product_id: string;
    item_quantity: number;
  }) => {
    const orderProductId = entity.order_product_id;
    delete entity.order_product_id;

    const result = await axios.patch<any>(`${apiUrl}/assign/product/${orderProductId}`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  }
)

export const assignOrderFee = createAsyncThunk(
  'order/assign_order_fee',
  async (entity: {
    order_id: string;
    order_fee_name: string;
    order_fee_value: number;
  }) => {
    // thunkAPI.dispatch(getEntities({}));
    return await axios.post<TypedOrderFee>(`${apiUrl}/assign/fee`, helpers.cleanEntity(entity));;
  }
);

export const assignOrderProduct = createAsyncThunk(
  'order/assign_order_product',
  async (entity: {
    order_id: string;
    product_id: string;
    item_quantity: number;
  }, thunkAPI) => {
    const result = await axios.post<TypedOrderFee>(`${apiUrl}/assign/product`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntity(entity.order_id));
    return result;
  }
);

export const unassignOrderProduct = createAsyncThunk(
  'order/unassign_order_product',
  async (entity: {
    order_id: string;
    order_product_id: string;
  }, thunkAPI) => {
    const orderPtdId = entity.order_product_id;
    delete entity.order_product_id;
    const result = await axios.delete<TypedOrderFee>(`${apiUrl}/assign/product/${orderPtdId}`, {data: helpers.cleanEntity(entity)});
    thunkAPI.dispatch(getEntity(entity.order_id));
    return result;
  }
);

//Lấy danh sách tổng hợp order theo trạng thái
export const getSumarizeOrderForAdmin = createAsyncThunk('order/sumarizeOrderForAdmin', async () => {
  const requestUrl = `${apiUrl}/simple_analytics`;
  return await axios.get<any>(requestUrl);
});

// order finish and lock
export const setOrderFinish = createAsyncThunk(
  'order/set_order_finish',
  async (entity: {
    order_id: string;
  }, thunkAPI) => {
    // patch
    let order_id = String(entity.order_id);
    delete entity.order_id;
    return await axios.patch<TypedOrder>(`${apiUrl}/lock/${order_id}`,{});
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_order = createEntitySlice({
  name: 'order',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    },
    reset: (state) => {
      return { ...state, ...initialState };
    }
  },
  extraReducers(builder) {
    builder

      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
        state.newCreateData = null;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(createEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = action.payload
      })

      .addCase(getSumarizeOrderForAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.sumarizeOrder = action.payload.data;
      })
      .addCase(getSumarizeOrderForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.sumarizeOrder = {};
      })
      
      .addCase(getAssignList.fulfilled, (state, action) => {
        state.assignLoading = false;
        state.assignList = action.payload.data;
      })
      .addCase(getAssignList.pending, (state, action) => {
        state.assignLoading = true;
      })
      .addCase(getAssignList.rejected, (state, action) => {
        state.assignLoading = false;
        state.assignList = [];
        state.errorMessage = action.payload;
      })

      .addCase(addAssignee.fulfilled, (state, action) => {
        state.addAssigneeLoading = false;
        state.assignList = action.payload.data;
      })
      .addCase(addAssignee.pending, (state, action) => {
        state.addAssigneeLoading = true;
      })
      .addCase(addAssignee.rejected, (state, action) => {
        state.addAssigneeLoading = false;
        state.errorMessage = action.payload;
      })




      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          newCreateData: null,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isRejected(createEntity, updateEntity, setStatusEntity, setOrderFinish, updatepartialEntity, deleteEntity), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.payload;
      })

      .addMatcher(isFulfilled(updatepartialEntity), (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })

      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.newCreateData = action.payload.data;
      })
      .addMatcher(isFulfilled(updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(setStatusEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(setOrderFinish), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        //state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(deleteEntity), state => {
        state.updating = true;
        //state.loading = true;
        state.updateSuccess = false;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, setStatusEntity, setOrderFinish, updatepartialEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isFulfilled(bulkUpdateEntities), (state, action) => {
        state.bulkUpdateEntitiesResult = action.payload.data;
        state.bulkUpdateEntitiesLoading = false;
      })
      .addMatcher(isPending(bulkUpdateEntities), (state) => {
        state.bulkUpdateEntitiesResult = null;
        state.bulkUpdateEntitiesLoading = true;
      })
      .addMatcher(isRejected(bulkUpdateEntities), (state) => {
        state.bulkUpdateEntitiesResult = {
          success: 0,
          fail: 0,
          invalidPNR: []
        };
        state.bulkUpdateEntitiesLoading = false;
      });

  },
});

export const { clearError, reset } = Reducer_order.actions;

// Reducer
export default Reducer_order.reducer;







