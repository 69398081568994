import React, { useCallback, useEffect } from 'react';
import { Form, Modal, TextField, Text, Toast } from "@shopify/polaris";
import { lengthLessThan, lengthMoreThan, useField, useForm } from '@shopify/react-form';
import { useAppDispatch, useAppSelector } from 'config/store';
import {
  clearError,
  updateEntity
} from "../../store/users.store.reducer";
import { getCurrentUser } from 'store/user.store.reducer';


export default function ProfileEditModal({ onClose, account, show }) {
  const loading = useAppSelector((state) => state.user.loading);
  const updateSuccess = useAppSelector((state) => state.user.updateSuccess);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (updateSuccess) onClose();
  }, [updateSuccess]);


  const useFields = {
    display_name: useField<string>({
      value: account?.display_name,
      validates: [
        lengthLessThan(60, "Tên của bạn quá dài!"),
        lengthMoreThan(2, "Tên của bạn quá ngắn!"),
      ],
    }),

    user_phone: useField<string>({
      value: account?.user_phone || "",
      validates: [
        (inputValue) => {
          if (inputValue) {
            if (!new RegExp("^[0-9]+$", "g").test(inputValue)) {
              return "Định dạng số điện thoại không hợp lệ. Chỉ dùng số và dấu cách!";
            }
          }
        },
      ],
    }),

  };



  const {
    fields,
    submit,
    submitting,
    dirty,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        dispatch(
          updateEntity({
            user_id: account?.user_id || 0,
            display_name: values.display_name,
            user_phone: values.user_phone,
          })
        ).then(() => {
          dispatch(getCurrentUser())
          onClose()
        });
      } catch (e: any) {
        console.error(`Submit error`, e);
      }
      return { status: "success", errors: [] };

    },
  });

  const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
  }, []);

  const successNotice = updateSuccess ? (
    <Toast content={'Cập nhật thành công!'} onDismiss={clearErrorCallback} />
  ) : null;


  return (
    <>
      {successNotice}
      <Modal
        open={show}
        title={"Chỉnh sửa thông tin cá nhân"}
        onClose={onClose}
        primaryAction={{
          content: "Lưu lại",
          loading: loading,
          disabled: !dirty,
          onAction: submit
        }}
      >
        <Modal.Section>
          <Form onSubmit={submit}>
            <Text as="h3" variant='headingMd'>Thông tin chung</Text>
            <br />
            <TextField
              label="Tên hiển thị"
              autoComplete="off"
              {...fields.display_name}
            />
            <br />
            <TextField
              label="Số điện thoại"
              autoComplete="off"
              helpText="Ví dụ: 0906111111"
              {...fields.user_phone}
            />

          </Form>
        </Modal.Section>
      </Modal>
    </>
  )
}