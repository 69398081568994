import {
  DataTable,
  EmptyState,
  Layout,
  Page,
  Card,
  Button,
  Badge,
  Popover,
  Icon,
  Thumbnail,
  InlineStack,
  Link,
  Select,
  Toast
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import emptyIMG from "../../media/images/empty.png";
import Pagination from "../../components/pagination";
import helpers from "../../helpers";
import __ from "languages/index";
import { NoteIcon, ChevronDownIcon, EditIcon } from '@shopify/polaris-icons'
import dateandtime from "date-and-time";
import {
  PlusIcon
} from '@shopify/polaris-icons';
import { TypedUserInGroup } from "interface/group_user.model";
import { assignUserToGroup, deleteUserInGroup, getListMembers, getEntity, changeUserToGroup } from "store/group_user.store.reducer";
import ModalAssignUserToGroup from "./component/ModalAssignUserToGroup";
import { MEMBER_GROUP_STATUS } from "constant";
import ImgsViewer from 'react-images-viewer';
import { useGetThumbnail } from "utils";

export default function UserInGroupManagement() {
  const entities = useAppSelector((state) => state.group_user.members);
  const totalItems = useAppSelector((state) => state.group_user.totalItemMemebers);
  const entityGroupUser = useAppSelector(state => state.group_user.entity);
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const [active, setActive] = useState(false);
  const [showPopupForChangeUserStatus, setShowPopupForChangeUserStatus] = useState('');
  const [currImg, setCurrImg] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [imgViewerUrl, setImgViewerUrl] = useState('');
  const account = useAppSelector(state => state.user.account);
  const userGroups = useAppSelector(state => state.group_user.entities);
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const { ref, getThumbnailUrl } = useGetThumbnail();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let Params = useParams();
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  const initialQuery = {
    query: "",
    page: 1,
    limit: 20,
    sort: "createdAt: desc",
    user_role: ''
  }
  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...initialQuery
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({ ...mainQuery, page: numPage, limit: limit });
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      setMainQuery(m);
    }
  }, []);



  /**
   * New model
   */

  const toggleNewActive = useCallback(
    () => setActive((active) => !active),
    []
  );

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch) history(`/group_user/${Params?.slug}` + buildURLSearch);
    dispatch(getListMembers({ group_id: Params?.slug, entity: mainQuery }));
    dispatch(getEntity(Params?.slug))
  }, [mainQuery, Params.slug]);

  const emptyData = (
    <EmptyState heading="Oh! Chưa có đối tác trong nhóm này!" image={emptyIMG}>
    </EmptyState>
  );

  const handleSort = useCallback(
    (index, direction) => {
      let _direction = direction === "descending" ? "desc" : "asc";
      let sort = "";
      switch (index) {
        case 0:
          sort = "franchise_branch_name:";
          break;
        case 1:
          sort = "franchise_branch_address:";
          break;
        case 2:
          sort = "franchise_branch_owner:";
          break;
        case 3:
          sort = "franchise_branch_contact_info:";
          break;
        case 4:
          sort = "franchise_branch_status:";
          break;
        case 5:
          sort = "franchise_branch_sign_date:";
          break;

        default:
          break;
      }

      if (sort.length) {
        entities.sort((a, b) => {
          if (_direction == 'desc') {
            return a[sort] - b[sort]
          }
          return b[sort] - a[sort]
        })
      }

    },
    [entities]
  );

  const handleDeleteItem = (_id: string) => {
    if (window.confirm('Bạn có chắc chắn muốn huỷ phê duyệt không? Sau khi huỷ phê duyệt người dùng sẽ bị xoá khỏi cấp hệ thống. Nếu muốn quay lại hệ thống người dùng sẽ phải đăng ký lại cấp kệ thống phía APP hoặc Web.')) {
      dispatch(deleteUserInGroup({ user_group_id: _id }))
        .unwrap()
        .then(res => {
          dispatch(getListMembers({ group_id: Params?.slug, entity: mainQuery }));
        })
    }
  }

  // Change group
  const handleSelectChange = useCallback(
    (value: string, user_id: string) => {
      dispatch(changeUserToGroup({
        group_id: value,
        user_id: user_id
      })).unwrap()
        .then(res => {
          setUpdateSuccess(true)
          dispatch(getListMembers({ group_id: Params?.slug, entity: mainQuery }));
        }).catch(err => setUpdateSuccess(false))
    },
    [],
  );

  // open viewer image
  const handleOnViewer = (url) => {
    setImgViewerUrl(url)
    setIsOpen(true)
  }

  const convertMemberGroupStatus = (value) => {
    switch (value) {
      case MEMBER_GROUP_STATUS
        .IN_ACTIVE:
        return <Badge tone="critical-strong">Chờ duyệt</Badge>
        break;
      case MEMBER_GROUP_STATUS
        .ACTIVE:
        return <Badge tone="success">Đã duyệt</Badge>
        break;
      default:
        break;
    }
  }

  const renderItem = (item: TypedUserInGroup, index) => {
    const {
      user: {
        display_name,
        user_email,
        user_phone,
        user_address,
      },
      approved,
      user_id,
      group_id,
      createdAt,
      user_to_group_id,
      payment_witness
    } = item;

    const sign_date = dateandtime.format(new Date(+createdAt), 'DD/MM/YYYY')

    return [
      index + 1,
      ['admin', 'super_admin'].includes(account?.user_role) ?
        <Link onClick={() => history(`/customer/edit/${user_id}`)}>{display_name}</Link>
        : display_name,
      user_phone,
      user_email,
      payment_witness ?
      <div style={{ cursor: payment_witness ? 'pointer' : '' }} onClick={payment_witness ? () => handleOnViewer(payment_witness) : () => { }}><Thumbnail size="small" source={getThumbnailUrl(payment_witness, 40, 40)} alt="" /></div>
      : <Badge></Badge>,
      <Popover
        active={showPopupForChangeUserStatus === user_id}
        activator={<>
          <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => setShowPopupForChangeUserStatus(user_id)}>
            {
              convertMemberGroupStatus(approved)
            }
            <Icon
              source={ChevronDownIcon}
              tone="base"
            />
          </div>
        </>}
        autofocusTarget="first-node"
        onClose={() => setShowPopupForChangeUserStatus('')}
      >
        <div style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
          {+approved === MEMBER_GROUP_STATUS.IN_ACTIVE ?
            <div style={{ cursor: 'pointer' }} onClick={() => handleAssignMemberToGroup(user_id)}><Badge tone="success-strong">Phê duyệt</Badge></div>
            : <div style={{ cursor: 'pointer' }} onClick={() => handleDeleteItem(user_to_group_id)}><Badge tone="critical-strong">Huỷ phê duyệt</Badge></div>
          }
        </div>
      </Popover>
      ,
      sign_date,
      <Select
        label=""
        disabled={Params?.slug == '1'}
        placeholder={userGroups?.find(item => item.group_id === Params?.slug)?.group_name}
        options={userGroups?.filter(val => val.group_id < Params?.slug)?.map(item => {
          return {
            value: item?.group_id,
            label: item?.group_name
          }
        }) || []}
        onChange={(value) => handleSelectChange(value, user_id)}
      />
    ];
  };

  const FranchiseBranchList = (
    <>
      {
        entities.length > 0 ? (
          <DataTable
            sortable={[
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
            ]}
            defaultSortDirection="descending"
            initialSortColumnIndex={0}
            onSort={handleSort}
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
            ]}
            headings={[
              "STT",
              __('user_full_name'),
              __('general_agent_phone'),
              __('franchise_branch_email'),
              'Bằng chứng thanh toán',
              'Trạng thái',
              __('franchise_branch_sign_date'),
              'Nâng cấp đại lý',
            ]}
            rows={entities?.map(renderItem)}
            hideScrollIndicator
            footerContent={`Display page ${mainQuery.page} of total ${totalItems} results...`}
          />
        ) : (emptyData)
      }
    </>
  );

  const successNotice = updateSuccess ? (
    <Toast content={'Chuyển cấp đại lý thành công!'} onDismiss={() => setUpdateSuccess(false)} />
  ) : null;

  const Actual_page = (
    <>
      <Page
        fullWidth
        title={`Hệ thống  ${entityGroupUser?.group_name || ""}`}
      // primaryAction={<Button icon={PlusIcon} variant="primary" onClick={() => toggleNewActive()}>Thêm người dùng vào nhóm</Button>}
      >
        <Layout>
          <Layout.Section>
            <Card padding={'0'}>
              {FranchiseBranchList}
            </Card>
            <br />
            {totalItems > mainQuery.limit ? (
              <Pagination
                TotalRecord={totalItems}
                activeCurrentPage={mainQuery.page}
                pageSize={mainQuery.limit}
                onChange={onChangePagination}
              />
            ) : null}
          </Layout.Section>
        </Layout>
      </Page>

    </>
  );

  const handleAssignMemberToGroup = (_id: string) => {
    dispatch(assignUserToGroup({
      group_id: Params?.slug,
      user_id: _id
    })).unwrap().then((res) => {
      dispatch(getListMembers({ group_id: Params?.slug, entity: mainQuery }));
      setActive(false);
    })
  }

  return (
    <>
      {successNotice}
      {Actual_page}
      {active && (
        <ModalAssignUserToGroup
          show={active}
          closeBtn={() => setActive(false)}
          selectableCallback={handleAssignMemberToGroup}

        />
      )}
      <ImgsViewer
        imgs={[
          {
            src: imgViewerUrl
          }
        ]}
        class={'test'}
        currImg={currImg}
        showThumbnails={false}
        isOpen={isOpen}
        onClickPrev={e => setCurrImg(currImg - 1)}
        onClickNext={e => setCurrImg(currImg + 1)}
        onClickThumbnail={index => setCurrImg(index)}
        onClose={e => setIsOpen(false)}
        showImgCount={false}
      />
    </>
  );
}
