import {
  DataTable,
  EmptyState,
  Layout,
  Page,
  Text,
  Badge,
  Card,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import helpers from "../../helpers";
import dateandtime from "date-and-time";
import Pagination from "components/pagination";
import emptyIMG from "../../media/images/empty.png";
import { useAppDispatch, useAppSelector } from "config/store";
import {
  getEntities,
} from "store/notification.store.reducer";
import SkeletonLoading from "components/skeletonLoading";
import NotificationNew from "./notification.new";
import {
  PlusIcon
} from '@shopify/polaris-icons';
import SimpleFilter from 'components/simpleFilter'

export default function NotificationList() {
  
  const entities = useAppSelector((state) => state.notification.entities);
  const loading = useAppSelector((state) => state.notification.loading);
  const totalItems = useAppSelector((state) => state.notification.totalItems);

  const dispatch = useAppDispatch();
  const history = useNavigate();


  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery = helpers.ExtractUrl(useParam.search) || false;

  const initialQuery = {
    query: "",
    page: 1,
    limit: 20,
    sort: "createdAt:desc",
  };

  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...initialQuery,
  });

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch) history("/notification" + buildURLSearch);
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);


  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({ ...mainQuery, page: numPage, limit: limit });
  }, [mainQuery]);

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (useParam.search !== buildURLSearch)
      window.history.replaceState(
        null,
        "Notification",
        "/notification" + buildURLSearch
      );
    dispatch(getEntities(mainQuery));
  }, [dispatch, mainQuery, useParam.search]);


  
/**
 * I do not know ...
 * Bug: In React, do NOT remove this code
 */
let m = mainQuery;
const filterCallback = useCallback( (_value: any) => {
  if ( _value === false ) {
    m = initialQuery;
    return setMainQuery(initialQuery);
  } else {
    m = Object.assign({}, m, _value);
    setMainQuery(m);  
  }
}, []);


  /**
   * END QUERY NOTIFICATION DETAIL
   */
  const emptyData = (
    <EmptyState heading="No post here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>
  );

  const renderItem = (notification: any) => {
    const {
      notification_id,
      notification_title,
      notification_content,
      notification_channel,
      notification_user,
      createdAt,
      notification_meta,
    } = notification;
    let _notification_meta = notification_meta[0]?.notification_meta_status;
    let status_text = _notification_meta === 0 ? 'Draft' : ( _notification_meta === 1 ? 'Sent' : 'Fail')
    let status_text_badge: any = _notification_meta === 0 ? 'info' : ( _notification_meta === 1 ? 'success' : 'critical');

    let notification_schedule = notification_meta[0]?.notification_meta_schedule ?? false;
    return [
      notification_id,
      <Text as="p">{notification_title}</Text>,
      <Badge size="small" tone={status_text_badge}>{status_text}</Badge>,
      notification_user || notification_channel,
      <time>
        {notification_schedule
          ? dateandtime.format(
            new Date(Number(notification_schedule)),
            "DD-MM-YYYY HH:mm:ss"
          )
          : "-"}
      </time>,
      <time>
        {createdAt
          ? dateandtime.format(new Date( Number(createdAt)), "DD-MM-YYYY HH:mm:ss")
          : "-"}
      </time>
    ];
  };

  const PagesList =
    entities && entities.length > 0 ? (
      <>
        <DataTable
          columnContentTypes={[
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
          ]}
          headings={[
            "ID",
            "Content",
            "Status",
            "Send To",
            "Schedule",
            "Create at",
          ]}
          rows={entities?.map(renderItem)}
          hideScrollIndicator
          footerContent={`Display page ${mainQuery.page} of total ${totalItems} results...`}
        />
      </>
    ) : (
      emptyData
    );

  const Actual_page = (
    <>
      <Page
        title="Quản lý thông báo"
        secondaryActions={[
          {
            content: 'Thêm thông báo',
            icon: PlusIcon,
            onAction: () => setUploadModelactive(true)
          }
        ]}
      >
        <Layout>
          <Layout.Section>
            <Card padding="0">
              <SimpleFilter loading={loading}
              onCallback={filterCallback}
              options={[]}
              sortField={[]}
            />

              {PagesList}
            </Card>
            <br />
            {totalItems > 0 ? (
              <Pagination
                TotalRecord={totalItems}
                onChange={onChangePagination}
                pageSize={Number(mainQuery.limit)}
                activeCurrentPage={Number(mainQuery.page)}
              />
            ) : null}
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );

  
  const [uploadModelactive, setUploadModelactive] = useState(false);
  const toggleUpdateActive = useCallback(
    () => setUploadModelactive((active) => !active),
    []
  );

  return (
    <>
      { entities === null ? <SkeletonLoading /> : Actual_page}

      <NotificationNew showModal={uploadModelactive} closeModal={toggleUpdateActive} />

    </>
  );
}
