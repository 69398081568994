import React from "react";
import { useParams } from "react-router-dom";
import Theme404 from '../../../layout/404';

import banner_list from './banner.list';

export default function BannerSettingIndex() {
    let useParam = {} as any;
    useParam = useParams();
    let Param = useParam.slug || 'list';
    let ActualPage: any;
    switch (Param) {

        case 'list':
            ActualPage = banner_list;
            break;

        default:
            ActualPage = Theme404;
            break;
    }

    return (
        <>
            {<ActualPage />}
        </>
    );
}
