import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { IQueryParams, createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { TypedCustomer } from '../interface/customer.model';

type TypedQuery = TypedCustomer & IQueryParams;

/**
*   Reducer used for front-end, with customer.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using customer.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null as any,
  entities: null,
  entity: null as TypedCustomer,
  customerBirthdayList: null as TypedCustomer,
  customerBirthdayListLoading: false,
  updating: false,
  importLoading: false,
  importSuccess: null as {total: number},
  totalItems: 0,
  sessions: null as any[],
  updateSuccess: false,

  assignLoading: false,
  addAssigneeLoading:false,
  assignList: [] as any,

};

const apiUrl = 'customer';

// Actions

export const getEntities = createAsyncThunk('customer/fetch_entity_list', async (object: TypedQuery) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return axios.get<any>(requestUrl);
});


export const getCustomerSession = createAsyncThunk('customer_session/fetch_entity_list', async (object: TypedQuery) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `customer_session/${EndURL}`;
  return axios.get<any>(requestUrl);
});


export const getCustomerBirthday = createAsyncThunk('customer_birthday/fetch_entity_list', async (object: TypedQuery) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}/birthday${EndURL}`;
  return await axios.get<any>(requestUrl);
});

/**
 * Assign
 */
export const getAssignList = createAsyncThunk('customer/assign/fetch_entity_list', async (customer_id: string | bigint) => {
  const requestUrl = `${apiUrl}/assign/${customer_id}`;
  return await axios.get<any>(requestUrl);
});

// add
export const addAssignee = createAsyncThunk('customer/assign/create_entity', async (entity: {user_id: any, customer_id: any}) => {
  return await axios.post<any>(`${apiUrl}/assign/`, helpers.cleanEntity(entity));
});

// deleteAssign
export const deleteAssign = createAsyncThunk('customer/assign/delete_entity', async (assign_id: string | bigint) => {
  return await axios.delete<any>(`${apiUrl}/assign/${assign_id}`);
});

/**
 * Delete Assign
 */

export const deleteSession = createAsyncThunk(
  'customer_session/delete_entity',
  async (id: string | number) => {
    return await axios.delete<any>(`customer_session/${id}`);
  },
  { serializeError: serializeAxiosError }
);


export const getEntity = createAsyncThunk(
  'customer/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<TypedCustomer>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'customer/create_entity',
  async (entity: TypedCustomer, thunkAPI) => {
    entity = helpers.filterEmptyObject(entity);
    const result = await axios.post<TypedCustomer>(`${apiUrl}`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'customer/update_entity',
  async (entity: TypedCustomer, thunkAPI) => {
    // patch
    entity = helpers.filterEmptyObject(entity);
    let customerID = String(entity.user_id);
    delete entity.user_id;
    return await axios.put<TypedCustomer>(`${apiUrl}/${customerID}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);


export const setNewPassword = createAsyncThunk('setNewPassword', async (entity: { password: string, user_id?: string }) => {
  return await axios.patch<any>(`${apiUrl}/set_new_password`, helpers.cleanEntity(entity));
},
  { serializeError: serializeAxiosError }
);


export const importCustomer = createAsyncThunk(
  'customer/import',
  async (entity: {media_id: string}) => {
    return await axios.post<any>(`${apiUrl}/import`, helpers.cleanEntity(entity));
  }
);

export const partialUpdateEntity = createAsyncThunk(
  'customer/partial_update_entity',
  async (entity: TypedCustomer, thunkAPI) => {
    const result = await axios.patch<TypedCustomer>(`${apiUrl}/${entity.user_id}`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateManyEntities = createAsyncThunk(
  'customer/update_many_entities',
  async (entity: TypedCustomer, thunkAPI) => {
    const result = await axios.patch<TypedCustomer>(`${apiUrl}/`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);


export const deleteEntity = createAsyncThunk(
  'customer/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = axios.delete<any>(requestUrl);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);


  /**
   * search user ...
   */
  export const searchCustomer = createAsyncThunk('customer/search-entity', async (object: any) => {
    const EndURL = helpers.buildEndUrl(object);
      return await axios.get<any>(`customer/search${EndURL}`);
    }
  );


// slice

export const Reducer_customer = createEntitySlice({
  name: 'customer',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = null;
      state.importSuccess = null;
    },
    clearEntity: (state) => {
      state.entity = null
    },
    clearEntities: (state) => {
      state.entities = null
    },
    reset: (state) => {
      return {...state, ...initialState};
    }
  },
  extraReducers(builder) {
    builder


    
    .addCase(searchCustomer.fulfilled, (state, action) => {
      state.loading = false;
      state.entities = action.payload.data;
    })
    .addCase(searchCustomer.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(searchCustomer.rejected, (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
    })

    
      .addCase(getCustomerSession.fulfilled, (state, action) => {
        // state.loading = false;
        state.sessions = action.payload.data;
      })
      .addCase(getCustomerSession.pending, (state, action) => {
        // state.loading = true;
      })
      .addCase(getCustomerSession.rejected, (state, action) => {
        // state.loading = false;
        state.sessions = null;
        state.errorMessage = action.payload;
      })


      .addCase(getAssignList.fulfilled, (state, action) => {
        state.assignLoading = false;
        state.assignList = action.payload.data;
      })
      .addCase(getAssignList.pending, (state, action) => {
        state.assignLoading = true;
      })
      .addCase(getAssignList.rejected, (state, action) => {
        state.assignLoading = false;
        state.assignList = [];
        state.errorMessage = action.payload;
      })

      .addCase(addAssignee.fulfilled, (state, action) => {
        state.addAssigneeLoading = false;
        state.assignList = action.payload.data;
      })
      .addCase(addAssignee.pending, (state, action) => {
        state.addAssigneeLoading = true;
      })
      .addCase(addAssignee.rejected, (state, action) => {
        state.addAssigneeLoading = false;
        state.errorMessage = action.payload;
      })

    
      .addCase(getCustomerBirthday.fulfilled, (state, action) => {
        state.customerBirthdayListLoading = false;
        state.customerBirthdayList = action.payload.data;
      })
      .addCase(getCustomerBirthday.pending, (state, action) => {
        state.customerBirthdayListLoading = true;
      })
      .addCase(getCustomerBirthday.rejected, (state, action) => {
        state.customerBirthdayListLoading = false;
        state.customerBirthdayList = null;
        state.errorMessage = action.payload;
      })


      .addCase(setNewPassword.fulfilled, (state, action) => {
        state.updating = false;
      })
      .addCase(setNewPassword.pending, (state, action) => {
        state.updating = true;
      })
      .addCase(setNewPassword.rejected, (state, action) => {
        state.updating = false;
        state.errorMessage = action.payload;
      })

      
      .addCase(deleteSession.rejected, (state, action) => {
        state.updating = false;
        state.errorMessage = action.payload;
      })
      .addCase(deleteSession.fulfilled, (state, action) => {
        state.updating = false;
        state.errorMessage = null;
        state.updateSuccess = true;
      })
      .addCase(deleteSession.pending, (state, action) => {
        state.updating = true;
        state.errorMessage = null;
      })



      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(createEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = action.payload
      })


      .addCase(importCustomer.fulfilled, (state, action) => {
        state.importLoading = false;
        state.importSuccess = action.payload.data;
      })
      .addCase(importCustomer.pending, (state, action) => {
        state.importLoading = true;
        state.importSuccess = null;
      })
      .addCase(importCustomer.rejected, (state, action) => {
        state.importLoading = false;
        state.importSuccess = null;
        state.errorMessage = action.payload
      })



      .addCase(updateEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.entity = action.payload.data;
      })
      .addCase(updateEntity.pending, (state, action) => {
        state.updating = true;
      })
      .addCase(updateEntity.rejected, (state, action) => {
        state.updating = false;
        state.errorMessage = action.payload
      })

      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isRejected(createEntity, updateManyEntities, deleteEntity), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.payload;
      })
      .addMatcher(isFulfilled(createEntity, updateManyEntities), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        //state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(deleteEntity), state => {
        state.updating = true;
        //state.loading = true;
        state.updateSuccess = false;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateManyEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
        state.entity = null;
      });
  },
});

export const { clearError, clearEntity, clearEntities, reset } = Reducer_customer.actions;

// Reducer
export default Reducer_customer.reducer;
