import { Button, Spinner, Text } from "@shopify/polaris";
import __ from "languages/index";
import { useEffect, useRef, useState } from "react";
import {
    UploadIcon,
    XCircleIcon,
    DragDropIcon
} from '@shopify/polaris-icons';
import 'media/css/product.scss'
import { uploadFile } from "../services/uploadFile";
import { TypedMediaResponse } from "interface/media.model";
import ImgsViewer from 'react-images-viewer'
import ModalCropImage from "components/modalCropImage";
import { dataURItoBlob, useGetThumbnail } from "utils";

interface TypedProps {
    fieldUrl: any;
    onError: (err: any) => void;
}

const ThumbnailBannerUpload = (props: TypedProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [showCrop, setShowCrop] = useState(false)
    const [imgCrop, setImgCrop] = useState('')
    const [currImg, setCurrImg] = useState(0)
    const { fieldUrl, onError } = props;
    const inputRef = useRef(null);
    const [loadingPercent, setLoadingPercent] = useState(0);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const { ref, getThumbnailUrl } = useGetThumbnail();

    function uploadSuccess(rep: TypedMediaResponse[]) {
        if (Array.isArray(rep)) {
            let srcList = [...rep.map(res => res.src), ...fieldUrl.value];

            if (srcList?.length < 2) {
                srcList = srcList.slice(0, 1)
            }
            setLoadingUpload(false);
            fieldUrl.onChange(srcList)
        }
    }

    useEffect(() => {
        imgCrop && handleFileChange({})
    }, [imgCrop])

    function uploadFail(rep: any) {
        setLoadingUpload(false);
        onError?.call(this, rep);
    }

    async function saveToServer(file: File[]) {
        try {
            const fd = new FormData();
            // append selected file to the bag named 'file'
            let fileListUrl = []
            let invalidFile = false;
            Array.from(file).forEach(file => {
                const allowedTypes = ['image/jpeg', 'image/png'];
                if (allowedTypes.includes(file.type)) {
                    fd.append('file[]', file)
                    fileListUrl.push(URL.createObjectURL(file))
                } else {
                    // return onError('upload_image_only')
                    invalidFile = true
                }
            });
            if (invalidFile) throw 'File không hợp lệ';

            // if([...fileListUrl,...fieldUrl.value].length > 20){
            //     fileListUrl.splice(fileListUrl.length - (20 - fieldUrl.value.length))
            //   }

            const uploaded = await uploadFile(fd, setLoadingPercent);
            if (Array.isArray(uploaded)) {
                uploadSuccess(uploaded)
            }
        } catch (_) {
            uploadFail(_);
        }
    }

    const handleFileChange = (e) => {

        if (imgCrop) {
            const file: any = dataURItoBlob(imgCrop)
            setLoadingUpload(true);
            saveToServer([file]);
        } else {
            const file = e.target.files;
            if (file?.length <= 0) return
            // file type is only image.
            setLoadingUpload(true);
            saveToServer(file);
        }
    }

    const handleRemoveFile = (indexImg) => {
        fieldUrl.onChange([]);
        setImgCrop('');
        setShowCrop(false);
    }

    return (!fieldUrl.value[0]) ? (
        <div className="box_list_image_category" >
            <div style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Text as="span">Tải ảnh banner</Text>
                <div className="btn_upload_banner" onClick={() => inputRef.current.click()} >
                    {
                        loadingUpload ? <Spinner accessibilityLabel="Small spinner example" size="small" /> :
                            <>
                                <UploadIcon width={'1.25rem'} />
                                <input ref={inputRef} multiple type="file" hidden onChange={handleFileChange} />
                            </>
                    }
                </div>
            </div>
        </div>
    ) : (
        <div>
            {
                fieldUrl.value.length < 1 && (
                    <div className="btn_upload_banner" onClick={() => inputRef.current.click()} >
                        <UploadIcon width={'1.25rem'} />
                        <input ref={inputRef} multiple type="file" hidden onChange={handleFileChange} />
                    </div>
                )
            }
            {
                <div className="img_banner_upload">
                    <img onClick={e => {
                        setIsOpen(true)
                        setCurrImg(0)
                    }} src={getThumbnailUrl(fieldUrl.value[0])} />
                    <Button onClick={() => handleRemoveFile(0)} icon={XCircleIcon} accessibilityLabel="Add theme" />
                    <div className="icon_crop"><Button icon={DragDropIcon} onClick={() => setShowCrop(true)} accessibilityLabel="Add theme" /></div>
                </div>
            }
            <ImgsViewer
                imgs={[
                    {
                        src: fieldUrl.value[0]
                    }
                ]}
                class={'test'}
                currImg={currImg}
                showThumbnails={false}
                isOpen={isOpen}
                onClickPrev={e => setCurrImg(currImg - 1)}
                onClickNext={e => setCurrImg(currImg + 1)}
                onClickThumbnail={index => setCurrImg(index)}
                onClose={e => setIsOpen(false)}
                showImgCount={false}
            />
            <ModalCropImage imageUrl={fieldUrl?.value[0]} isOpen={showCrop} onClose={() => setShowCrop(false)} setImgCrop={setImgCrop} />
        </div>
    )
};
export default ThumbnailBannerUpload;