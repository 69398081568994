import {
  FormLayout,
  Modal,
  TextField,
  Form,
  Toast,
} from "@shopify/polaris";
import { memo, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "config/store";
import { clearError, createCollectionProduct, editCollectionProduct, getEntity } from "store/product_collection.store.reducer";
import { useParams } from "react-router-dom";
import {
  lengthLessThan,
  lengthMoreThan,
  useField,
  useForm,
} from "@shopify/react-form";

import __, { ___ } from "languages/index";

/**
 *   Create upload Modal for Notification
 */

// eslint-disable-next-line import/no-anonymous-default-export

function ProductCollectionNewModal({ onClose, show, product_collection_id }: { onClose: Function, show: boolean, product_collection_id?: string }) {
  const updating = useAppSelector((state) => state.product_collection.updating);
  const updateSuccess = useAppSelector((state) => state.product_collection.updateSuccess);
  const entity = useAppSelector((state) => state.product_collection.entity);
  const [internalErrorNotice, setInternalErrorNotice] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    product_collection_id && dispatch(getEntity(product_collection_id))
  }, [product_collection_id])
  /**
   * Lấy URL ra... để query. chú ý phải load nội dung mới 
   * trong useEffect
   */
  let useParam = {} as any;
  useParam = useParams();

  const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
  }, []);

  const successNotice = updateSuccess ? (
    <Toast content={'Cập nhật thành công!'} onDismiss={clearErrorCallback} />
  ) : null;

  const useFields = {
    collection_name: useField<string>({
      value: (entity && product_collection_id) && entity?.collection_name ? entity?.collection_name : '',
      validates: [
        lengthLessThan(250, __('lengthLessThan')),
        lengthMoreThan(1, __('lengthMoreThan')),
        inputValue => {
          if (!inputValue) {
            return __('require');
          }
        }
      ],
    }),

    collection_description: useField<string>({
      value: (entity && product_collection_id) && entity?.collection_description ? entity?.collection_description : '',
      validates: [
      ],
    }),
  }

  const {
    fields,
    submit,
    dirty,
    reset: resetForm,
  } = useForm({
    fields: useFields,

    async onSubmit(values) {
      try {
        if (entity && product_collection_id) {
          await dispatch(
            editCollectionProduct({
              product_collection_id: product_collection_id,
              ...values,
              collection_status: 1
            })
          );
        } else {
          // create new
          await dispatch(
            createCollectionProduct({
              ...values,
              collection_status: 1
            })
          );
        }
        resetForm();
        onClose()
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message =
          e?.response?.data?.title ?? "Undefined error. Try again!";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  /* End form */
  useEffect(() => {
    if (updateSuccess) {
      resetForm();
      onClose();
      dispatch(clearError());
    }
  }, [updateSuccess]);

  const handUploadError = useCallback((err: any) => {
    setInternalErrorNotice(err);
  }, []);

  const Actual_page = (
    <Modal
      open={show}
      onClose={() => {
        onClose();
        resetForm();
      }}
      title={entity && product_collection_id ? "Sửa thông tin bộ sưu tập sản phẩm" : "Tạo bộ sưu tập sản phẩm"}
      primaryAction={{
        content: "Lưu thông tin",
        disabled: !dirty,
        loading: updating,
        onAction: submit,
      }}
      secondaryActions={[
        {
          content: "Thoát",
          onAction: () => onClose(),
        },
      ]}
    >
      <Modal.Section>
        <Form onSubmit={submit}>
          <FormLayout>
           
            <TextField
              autoComplete="off"
              autoFocus
              label={'Tên bộ sưu tập sản phẩm'}
              {...fields.collection_name}
            />
            <TextField
              autoComplete="off"
              autoFocus
              label={'Mô tả chi tiết'}
              multiline={4}
              {...fields.collection_description}
            />
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );

  return (
    <>
     {successNotice}
    {internalErrorNotice ? (
        <Toast
          content={internalErrorNotice}
          error
          onDismiss={() => setInternalErrorNotice("")}
        />
      ) : null}
      {Actual_page}
    </>
  );
}

export default memo(ProductCollectionNewModal)
