import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'config/reducer.utils';


/**
*   Reducer used for front-end, with settings.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null as any,
  message: null,
  entities: null,
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'settings';

// Actions

export const clearAllSession = createAsyncThunk('settings/clear_all_session', async () => {
  const requestUrl = `utils/clear_all_session`;
  return await axios.get<any>(requestUrl);
});

export const getEntities = createAsyncThunk('settings/fetch_entity_list', async (entities: IQueryParams) => {
  let entity = helpers.buildEndUrl(entities);
  return await axios.get<any>(`${apiUrl}${entity}`);
});

export const getEntity = createAsyncThunk(
  'settings/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return await axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

/*
export const createEntity = createAsyncThunk(
  'settings/create_entity',
  async (entity: ISettings, thunkAPI) => {
    const result = await axios.post<ISettings>(`${apiUrl}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError }
);

*/

export const updateEntity = createAsyncThunk(
  'settings/update_entity',
  async (entity: any, thunkAPI) => {
    return await axios.post<any>(`${apiUrl}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);


export const sendTestEmail = createAsyncThunk(
  'settings/send_test_email',
  async () => {
    return await axios.post<any>(`utils/email_testing`);
  },
  { serializeError: serializeAxiosError }
);



export const deleteEntity = createAsyncThunk(
  'settings/delete_entity',
  async (id: string | number) => {
    return await axios.delete<any>(`${apiUrl}/${id}`);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_Settings = createEntitySlice({
  name: 'settings',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    },
    clearMessage: (state) => {
      state.message = null;
    }
  },
  extraReducers(builder) {
    builder

      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(deleteEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = "Can not delete this Entity"
      })
      .addCase(clearAllSession.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = "Can not clear system session!"
      })
      .addMatcher(isFulfilled(getEntities, clearAllSession), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.message = 'setting_saved';
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(deleteEntity), state => {
        state.updating = true;
        state.loading = true;
        state.updateSuccess = false;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity, clearAllSession), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(updateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { clearError, clearMessage, reset } = Reducer_Settings.actions;

// Reducer
export default Reducer_Settings.reducer;
