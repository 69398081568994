import { Page, Toast, Layout, FormLayout, TextField, Form, BlockStack, ContextualSaveBar, Text, InlineStack, Tooltip, Button, EmptyState, DataTable, Select } from '@shopify/polaris';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "config/store";
import {
  addProductToCommission,
  clearError,
  createEntity,
  getEntity,
  updateEntity,
} from "store/product.store.reducer";
import {
  getEntities as getEntitiesCommission
} from "store/commission.store.reducer";
import {
  ReturnIcon,
  VariantIcon
} from '@shopify/polaris-icons';
import helpers from "../../helpers";
import SkeletonLoading from 'components/skeletonLoading';
import { lengthLessThan, lengthMoreThan, useField, useForm } from '@shopify/react-form';
import InfomationGeneral from './component/infomation.general';
import ProductUpload from './component/product.upload';
import 'media/css/product.scss'
import ModalConfirm from 'components/modalConfirm';
import __ from 'languages/index';
import { categoriesSelector } from 'store/product_category.store.reducer';
import { collectionsSelector } from 'store/product_collection.store.reducer';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { getCommissionProduct } from 'store/commission.store.reducer';
import { TypeCommissionRule } from 'interface/commission.model';
import { convertDataCommission } from 'utils';
import { COMMISSION_RULE_FIELD, REGEX_VALIDATE_NUMBER, SHOW_PRODUCT } from 'constant';
import { COMMISSION_RULE_OPERATER } from 'constant';
import { COMMISSION_UNIT } from 'constant';
import emptyIMG from "../../media/images/empty.png";
import LayoutChildrenProduct from 'layout/layout.infomation.product';
import { getEntities as getStocks } from 'store/stock.store.reducer';
import { getEntities } from 'store/group_user.store.reducer';

export default function ProductEdit() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.product_id || false;

  /**
   * nếu không có ID, xóa mọe đi
   */
  if (isNaN(Param)) {
    Param = false;
  }

  const entitiesStock = useAppSelector((state) => state.stock.entities);
  const entity = useAppSelector((state) => state.product.entity);
  const updating = useAppSelector((state) => state.product.updating);
  const updateSuccess = useAppSelector((state) => state.product.updateSuccess);
  const editorRef = useRef(null)
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [internalErrorNotice, setInternalErrorNotice] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isProductParent, setIsProductParent] = useState(false);
  const account = useAppSelector(state => state.user.account);
  const [stockProducts, setStockProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [commissionValues, setCommissionValues] = useState<{ group_id: string, price: string, price_percent: string | null }[]>([]);
  const [commissionErrors, setCommissionErrors] = useState<{ group_id: string, error: string | null }[]>([]);
  const entitiesUserGroup = useAppSelector((state) => state.group_user.entities);

  const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
  }, []);

  useEffect(() => {
    clearErrorCallback();
    dispatch(getEntitiesCommission({}));
    dispatch(getStocks({}));
    dispatch(getEntities({}))
  }, []);

  useEffect(() => {
    Param && dispatch(getCommissionProduct({ product_id: Param }))
  }, [Param])

  // Tạo danh sách kho cho sản phẩm
  useEffect(() => {
    if (entity?.product_to_stock.length > 0 && Param) {
      let dataStock = [...entity?.product_to_stock?.map(item => {
        return {
          stock_id: item?.stock_id,
          product_quantity: item?.product_quantity,
          stock_name: item?.stock?.stock_name ?? '',
          product_sku: ''
        }
      })]

      let dataFormStock = [...dataStock, ...entitiesStock?.map(item => {
        return {
          stock_id: item?.stock_id,
          product_quantity: '',
          stock_name: item?.stock_name ?? '',
          product_sku: ''
        }
      }).filter(val => !(dataStock.map(item => item?.stock_id).includes(val.stock_id)))]


      setStockProduct(dataFormStock)
    } else {
      setStockProduct([...entitiesStock?.map(item => {
        return {
          stock_id: item?.stock_id,
          product_quantity: '',
          stock_name: item?.stock_name ?? '',
          product_sku: ''
        }
      }
      )])
    }
  }, [entity, entitiesStock])

  const secondaryActions = [
    {
      content: 'Đặt lại tất cả',
      accessibilityLabel: 'Secondary action label',
      onAction: () => setShowModal(true),
      icon: ReturnIcon
    },
    {
      content: 'Quản lý phân loại sản phẩm',
      accessibilityLabel: 'Secondary action label',
      onAction: () => navigate(`/product/variant/${useParam.product_id}`),
      icon: VariantIcon
    }
  ]

  const useFields = {
    product_media: useField<string[]>({
      value: (entity && Param) && Array.isArray(entity?.product_media) ? entity?.product_media : [],
      validates: [
        inputValue => {
          if (!(inputValue.length > 0)) {
            return __('require');
          }
        }
      ],
    }),

    vat: useField<string>({
      value: (entity && Param) && entity?.vat ? entity?.vat : '',
      validates: [
        inputValue => {
          if (!inputValue) {
            return __('require');
          }
        }
      ],
    }),

    product_name: useField<string>({
      value: (entity && Param) && entity?.product_name ? entity?.product_name : '',
      validates: [
        lengthLessThan(250, __('lengthLessThan')),
        lengthMoreThan(1, __('lengthMoreThan')),
        inputValue => {
          if (!inputValue) {
            return __('require');
          }
        }
      ],
    }),

    product_content: useField<string>({
      value: (entity && Param) && entity?.product_content ? entity?.product_content : '',
      validates: [
        inputValue => {
          if (!inputValue) {
            return __('require');
          }
        }
      ],
    }),

    product_original_price: useField<string>({
      value: (entity && Param) && entity?.product_original_price ? String(entity?.product_original_price) : '',
      validates: [
        inputValue => {
          if (!inputValue) {
            return __('require');
          }
        }
      ],
    }),


    product_size_width: useField<string>({
      value: (entity && Param) && entity?.product_size_width ? String(entity?.product_size_width) : '',
      validates: [
        (inputValue) => {
          if (inputValue && !(REGEX_VALIDATE_NUMBER.test(inputValue))) {
            return __('isNumber');
          }
          if (!inputValue) {
            return __('require');
          }
        },
      ],
    }),

    product_size_weight: useField<string>({
      value: (entity && Param) && entity?.product_size_weight ? String(entity?.product_size_weight) : '',
      validates: [
        (inputValue) => {
          if (inputValue && !(REGEX_VALIDATE_NUMBER.test(inputValue))) {
            return __('isNumber');
          }
          if (!inputValue) {
            return __('require');
          }
        },
      ],
    }),

    product_size_length: useField<string>({
      value: (entity && Param) && entity?.product_size_weight ? String(entity?.product_size_weight) : '',
      validates: [
        (inputValue) => {
          if (inputValue && !(REGEX_VALIDATE_NUMBER.test(inputValue))) {
            return __('isNumber');
          }
          if (!inputValue) {
            return __('require');
          }
        },
      ],
    }),

    product_size_height: useField<string>({
      value: (entity && Param) && entity?.product_size_weight ? String(entity?.product_size_weight) : '',
      validates: [
        (inputValue) => {
          if (inputValue && !(REGEX_VALIDATE_NUMBER.test(inputValue))) {
            return __('isNumber');
          }
          if (!inputValue) {
            return __('require');
          }
        },
      ],
    }),

    product_unit: useField<string>({
      value: (entity && Param) && entity?.product_unit ? String(entity?.product_unit) : '',
      validates: [
        inputValue => {
          if (!inputValue) {
            return __('require');
          }
        }
      ],
    }),

    product_collection: useField<string[]>({
      value: (entity && Param) && entity?.product_to_collection.map(item => item?.collection_id) ? entity?.product_to_collection.map(item => item?.collection_id) : [],
      validates: [
        inputValue => {
          if (!(inputValue?.length > 0)) {
            return __('require');
          }
        }
      ],
    }),


    product_category: useField<string[]>({
      value: (entity && Param) && entity?.product_to_category.map(item => item?.category_id) ? entity?.product_to_category.map(item => item?.category_id) : [],
      validates: [
        inputValue => {
          if (!(inputValue?.length > 0)) {
            return __('require');
          }
        }
      ],
    }),

    product_code: useField<string>({
      value: (entity && Param) && entity?.product_code ? String(entity?.product_code) : '',
      validates: [
        inputValue => {
          if (!inputValue) {
            return __('require');
          }
        }
      ],
    }),

    product_status: useField<string>({
      value: (entity && Param) && String(entity?.product_status) ? String(entity?.product_status) : SHOW_PRODUCT.SHOW,
      validates: [
      ],
    }),

  };


  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: productReset,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        const formData = {
          category_id: [...values.product_category],
          product_media: [...values.product_media],
          vat: helpers.parseNumeric(values.vat),
          product_content: values.product_content,
          product_name: values.product_name?.trim(),
          product_original_price: helpers.parseNumeric(values.product_original_price),
          product_price: helpers.parseNumeric(values.product_original_price),
          product_code: values.product_code?.trim(),
          product_unit: values.product_unit.trim(),
          collection_id: [...values.product_collection],
          product_status: values.product_status ? values.product_status : SHOW_PRODUCT.SHOW,
          product_size_width: values.product_size_width,
          product_size_weight: values.product_size_weight,
          product_size_length: values.product_size_length,
          product_size_height: values.product_size_height,
          product_price_level: commissionValues.map(item => {
            return {
              price_percent: null,
              group_id: Number(item.group_id),
              price: helpers.parseNumeric(item.price),
            }
          }),
          product_to_stock: stockProducts.map(item => {
            return {
              stock_id: item?.stock_id,
              product_quantity: helpers.parseNumeric(item?.product_quantity),
              product_sku: [...Array(6)].map(() => String.fromCharCode(65 + Math.floor(Math.random() * 26))).join('')
            }
          }) ?? []
        };
        if (!Param) {
          // create new 
          if (commissionValues?.every(item => !!helpers.parseNumeric(item?.price))) {
            setLoading(true)
            dispatch(createEntity({
              ...formData
            })).then(() => {
              setLoading(false)
            }).catch(() => {
              setLoading(false)
            })
          }

        } else {
          if (commissionValues?.every(item => !!helpers.parseNumeric(item?.price))) {
            setLoading(true)
            dispatch(updateEntity({
              product_id: Param,
              ...formData
            })).then(() => {
              setLoading(false)
            }).catch(() => {
              setLoading(false)
            })
          }
        }
        return { status: 'success' };
      } catch (e: any) {
        console.error(`Submit error`, e);
        setLoading(false)
        const message = e?.response?.data?.title ?? 'Lỗi không xác định, vui lòng thử lại sau.';
        const field = e?.response?.data?.errorKey ?? 'base';
        return { status: 'fail', errors: [{ field, message }] };
      }
    },
  });


  useEffect(() => {
    if (Param)
      dispatch(getEntity(Param))
  }, [Param]);

  // set lai data level price
  useEffect(() => {
    if (entity && Param) {
      const initialValues = entity?.product_price_level?.product_price_level_meta.map(group => ({
        group_id: group.group_id?.toString(),
        price: group.price?.toString() || '',
        price_percent: null
      }));
      setCommissionValues(initialValues);
    } else {
      const initialValues = entitiesUserGroup?.map(group => ({
        group_id: group.group_id?.toString(),
        price: '',
        price_percent: null
      }));
      setCommissionValues(initialValues);
    }
  }, [entity, entitiesUserGroup]);

  const successNotice = updateSuccess ? (
    <Toast content={'Cập nhật thành công!'} onDismiss={clearErrorCallback} />
  ) : null;

  const handUploadError = useCallback((err: any) => {
    setInternalErrorNotice(err);
  }, []);

  useEffect(() => {
    (updateSuccess && !Param) && navigate('/product')
  }, [updateSuccess])

  useEffect(() => {
    entity?.parent?.product_id ? setIsProductParent(true) : setIsProductParent(false)
  }, [entity])

  const emptyData = (
    <EmptyState heading="No commission here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>
  );

  const checkError = useCallback(() => {
    if (!helpers.isEmpty(commissionValues)) {
      commissionValues?.forEach(item => {
        setCommissionErrors(prevErrors => {
          const existingError = prevErrors?.find(error => error?.group_id === item?.group_id);

          if (!helpers.parseNumeric(item?.price)) {
            if (existingError) {
              return prevErrors?.map(error =>
                error?.group_id === item?.group_id ? { ...error, error: __('require') } : error
              );
            } else {
              return [...prevErrors, { group_id: item?.group_id, error: __('require') }];
            }
          } else {
            return prevErrors?.filter(error => error?.group_id !== item?.group_id);
          }
        });
      })
    }

  }, [commissionValues])

  return (
    <>
      {successNotice}

      {internalErrorNotice ? (
        <Toast
          content={internalErrorNotice}
          error
          onDismiss={() => setInternalErrorNotice("")}
        />
      ) : null}

      <Page
        fullWidth
        backAction={{
          content: 'Products',
          url: '/product'
        }}
        title={Param ? "Chi tiết sản phẩm" : 'Thêm mới sản phẩm'}
        primaryAction={(['admin', 'super_admin'].includes(account?.user_role)) && {
          content: Param ? 'Chỉnh sửa sản phẩm' : 'Lưu sản phẩm', loading: loading, onAction: () => {
            submit();
            checkError();
          }
        }}
        secondaryActions={!(entity && Param) && [secondaryActions[0]]}
      >
        <Layout >
          <Layout.Section variant='oneHalf'>
            <Form onSubmit={submit}>
              <FormLayout>
                <InfomationGeneral
                  fieldProductUnit={fields.product_unit}
                  fieldProductCategory={fields.product_category}
                  fieldCollection={fields.product_collection}
                  fieldProductName={fields.product_name}
                  fieldProductCode={fields.product_code}
                  fieldOriginalPrice={fields.product_original_price}
                  fieldVat={fields.vat}
                  fieldProductStatus={fields.product_status}
                  fieldProductWidth={fields.product_size_width}
                  fieldProductWeight={fields.product_size_weight}
                  fieldProductHeight={fields.product_size_height}
                  fieldProductLength={fields.product_size_length}
                  setStockProduct={setStockProduct}
                  stockProducts={stockProducts}
                  setCommissionValues={setCommissionValues}
                  commissionValues={commissionValues}
                  commissionErrors={commissionErrors}
                  setCommissionErrors={setCommissionErrors}
                  dirty={dirty}
                />

              </FormLayout>
            </Form><br /><br />
          </Layout.Section>


          <Layout.Section variant="oneHalf" >

            <BlockStack gap={"400"}>
              <Text as="span" fontWeight='bold'>Ảnh sản phẩm</Text>
              {fields.product_media.error && (
                <div style={{ color: 'red' }}>{fields.product_media.error}</div>
              )}
              <ProductUpload
                fieldUrl={fields.product_media}
                onError={handUploadError}
              />
              <Text as="span" fontWeight='bold'>Mô tả sản phẩm</Text>
              {fields.product_content.error && (
                <div style={{ color: 'red' }}>{fields.product_content.error}</div>
              )}
              <CKEditor
                editor={ClassicEditor}
                data={fields?.product_content?.value}
                onChange={(event, editor) => {
                  fields?.product_content?.onChange(editor.getData())
                }}
                config={{
                  placeholder: `${__("product_description")}`,
                  plugins: ['Bold', 'Italic', 'Heading', 'Link', 'List', 'Table', 'TableToolbar']
                }}
              />
            </BlockStack>

          </Layout.Section>
        </Layout>
        <ModalConfirm
          active={showModal}
          setActive={setShowModal}
          onAction={() => {
            productReset();
            setShowModal(false);
          }}
          title={`Bạn đang thao tác đặt lại tất cả nội dung sản phẩm:  ${fields.product_name.value}`}
          btnText="Đặt lại tất cả"
          contentText="Bạn sẽ phải bắt đầu nhập lại thông tin từ đầu"
        />
      </Page >
    </>
  );
}