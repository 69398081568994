import { Form, Modal, TextField } from "@shopify/polaris";
import { useField, useForm } from "@shopify/react-form";
import { useAppDispatch, useAppSelector } from "config/store";
import helpers from "helpers/index";
import __, { ___ } from "languages/index";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { assignOrderFee } from "store/order.store.reducer";


import {
  getEntities as getEntitiesOrderFee,
  getOrderFeeList,
  deleteEntity as deleteEntityOrderFee
} from "store/order_fee.store.reducer";
interface TypedProps {
  handleAssignOrderfee: (value) => void;
  onClose: () => void;
}
export default function ShowCustomFee({ onClose, handleAssignOrderfee }: TypedProps) {

  /**
   * Danh sách phí ...
   */
  const orderFeeEntities = useAppSelector((state) => state.order_fee.entities);
  const orderFeeList = useAppSelector((state) => state.order_fee.orderFeeList);
  const updating = useAppSelector((state) => state.order_fee.updating);
  const errorMessage = useAppSelector((state) => state.order_fee.errorMessage);
  const updateSuccess = useAppSelector((state) => state.order_fee.updateSuccess);
  const dispatch = useAppDispatch();
  const history = useNavigate();


  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.order_id || false;


  useEffect(() => {
    if (updateSuccess === true) {
      dispatch(getEntitiesOrderFee(Param));
      onClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    dispatch(getOrderFeeList());
  }, []);

  const [selected, setSelected] = useState<string>('');
  const handleSelectChange = useCallback(
    (value: string) => setSelected(value),
    [],
  );

  const [selectlist, setSelectlist] = useState(null);
  const options = [];
  useEffect(() => {
    if (orderFeeList) {
      for (let e in orderFeeList) {
        options.push({
          label: orderFeeList[e] + ` (${e})`, value: e
        })
      }
      setSelectlist(options);
      setSelected(options[0].value);
    }
  }, [orderFeeList]);

  const [inputFee, setInputFee] = useState('1000');
  const handleFeeInputChange = useCallback(
    (newValue: string) => setInputFee(helpers.formatNumberWithCommas(newValue)),
    [],
  );

  const handleSaveChange = useCallback(() => {
    dispatch(assignOrderFee({
      order_fee_name: String(selected),
      order_fee_value: helpers.parseNumeric(inputFee),
      order_id: Param
    }));
  }, [selected, inputFee, Param]);

  const useFields = {
    order_fee_name: useField<string>({
      value: "",
      validates: [
        inputValue => {
          if (!inputValue) {
            return `${___('{label} is_require', {
              label: <>Tên chi phí</>
            })}`;
          }
        }
      ],
    }),
    order_fee_value: useField<string>({
      value: "0",
      validates: [
        inputValue => {
          if (!inputValue) {
            return `${___('{label} is_require', {
              label: <>Tiền phí</>
            })}`;
          };

          if (helpers.parseNumeric(inputValue, 0) > 100000000) {
            return __('cost_is_too_high')
          }
        }
      ],
    }),
  };

  const { fields, submit, submitting } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await handleAssignOrderfee(values);
        return { status: 'success' };
      } catch (e) {
        console.error(`Submit error E634`, e);
        const message = e?.response?.data?.title ?? 'Lỗi không xác định, vui lòng thử lại sau.';
        const field = e?.response?.data?.errorKey ?? 'base';
        return { status: 'fail', errors: [{ field, message }] };
      }
    }
  });

  return (
    <Modal
      activator={null}
      open={true}
      onClose={onClose}
      title="Thêm chi phí cho đơn hàng này"
      primaryAction={{
        content: 'Lưu lại',
        loading: updating,
        disabled: updating,
        onAction: submit,
      }}
      secondaryActions={[
        {
          content: 'Đóng',
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <Form onSubmit={submit}>
          <TextField
            label="Tiền phí"
            autoFocus
            autoComplete="off"
            disabled={submitting}
            {...fields.order_fee_name}
          />

          <br />
          <TextField
            label="Tiền phí"
            autoFocus
            type="number"
            disabled={submitting}
            autoComplete="off"
            {...fields.order_fee_value}
          />
        </Form>
      </Modal.Section>
    </Modal>
  )
}