import { FormLayout, TextField, Text, Form, ChoiceList, Modal, OptionList} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "config/store";
import {
  getEntity,
  updateEntity,
  createEntity,
  reset as resetLogisticData
} from "store/logistic_service.store.reducer";
import helpers from "../../helpers";
import { lengthLessThan, lengthMoreThan, useField, useForm } from '@shopify/react-form';
import orderDeliverType from '../order/order.delivery.json';

export default function LogisticModal({ show, onClose}) {
  let useParam =  {} as any;
      useParam = useParams();
  let Param = useParam.logistic_id || false;

  /**
   * nếu không có ID, xóa mọe đi
   */
  if ( isNaN(Param) ) {
    Param = false;
  }
  
  const entity = useAppSelector((state) => state.logistic_service.entity);
  const updating = useAppSelector((state) => state.logistic_service.updating);
  const updateSuccess = useAppSelector((state) => state.logistic_service.updateSuccess);

  const dispatch = useAppDispatch();
  const [deliverySelected, setDeliverySelected] = useState<string[]>([]);


  const onCloseCallback = useCallback( () => {
        dispatch( resetLogisticData () );
        onClose?.call(this, 'close');
  }, []);

  useEffect( () => {
    if ( updateSuccess ) onCloseCallback();
  }, [updateSuccess]);

  useEffect( () => {
    if ( Param && show )
    dispatch( getEntity(Param) );
  }, [Param, show]);


  useEffect( () => {
    if ( ! entity ) return ;
    setDeliverySelected(entity?.logistic_delivery_method?.split(','));
  }, [entity]);

  

    const useFields = {
        
      logistic_name: useField<string>({
        value: entity?.logistic_name ?? '',
          validates: [
          lengthLessThan(250, 'No more than 250 characters.'),
          lengthMoreThan(2, 'No shorter than 2 characters.'),
                inputValue => {
                  if ( inputValue.length < 2 ) {
                      return 'Your title is too short, or it is empty.';
                  }
                }
        ],
      }),

      logistic_description: useField<string>({
          value: entity?.logistic_description ?? '',
              validates: [
              lengthLessThan(250, 'No more than 250 characters.'),
              lengthMoreThan(2, 'No shorter than 2 characters.')
            ],
      }),

      logistic_delivery_method: useField<string>({
          value: entity?.logistic_delivery_method ?? '',
              validates: [
              lengthLessThan(250, 'No more than 250 characters.'),
              lengthMoreThan(2, 'No shorter than 2 characters.')
            ],
      }),
      
      logistic_status: useField<string>({
        value: entity? String(entity.logistic_status) : '0',
          validates: [],
      }),

      module: useField<string>({
        value: entity? entity.module : '',
        validates: [
          (inputValue) => {
            if ( inputValue && helpers.isUTF8(inputValue) ) {
              return 'Không dùng mã UTF8 trong tên module';
            }
          }
        ],
      })


  };

  const logistic_delivery_method_fired = useFields.logistic_delivery_method;
  const logisticStatusOption = (useFields.logistic_status);
  const onChangeDeliveryMethod = useCallback( (val: string[]) => {
    setDeliverySelected(val);
    logistic_delivery_method_fired.dirty = true;
  }, [logistic_delivery_method_fired]);

  const orderDeliveryList = [];
  for ( let x in orderDeliverType ) {
    let key = x, name = orderDeliverType[x];
    orderDeliveryList.push({
      label: `${name}`, 
      value: `${name}`
    })
  }


  const {
    fields,
    submit,
    dirty,
    reset: Userreset,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
              if ( ! Param ) {
                  // create new 
                  dispatch( createEntity({
                    logistic_name: values.logistic_name,
                    module: values.module,
                    logistic_description: values.logistic_description,
                    logistic_status: Number(values.logistic_status),
                    logistic_delivery_method: deliverySelected.join(','),
                  }))
              } else {
                dispatch( updateEntity({
                  logistic_id: Param,
                  logistic_name: values.logistic_name,
                  module: values.module,
                  logistic_description: values.logistic_description,
                  logistic_status: Number(values.logistic_status),
                  logistic_delivery_method: deliverySelected.join(','),
                }))
              }
        return { status: 'success' };
      } catch (e:any) {
        console.error(`Submit error`, e);
        const message = e?.response?.data?.title ?? 'Lỗi không xác định, vui lòng thử lại sau.';
        const field = e?.response?.data?.errorKey ?? 'base';
        return { status: 'fail', errors: [{ field, message }] };
      }
    },
  });


  useEffect( () => {
    Userreset();
    // dispatch( resetLogisticData () );
  }, [show, Param]);

    return (
      <>
        <Modal 
            open={show} 
            title={'Tạo hoặc cập nhật đơn vị vận chuyển'} 
            onClose={onCloseCallback}
            primaryAction={{
                content: Param ? 'Cập nhật':  "Tạo & đóng",
                disabled: !dirty,
                loading: updating,
                onAction: submit
              }}
              secondaryActions={[
                {
                  content: "Đóng",
                  onAction: onCloseCallback,
                }
              ]}      
        >
            <Modal.Section>
                  <Form onSubmit={submit}>
                    <FormLayout>

                            <TextField
                                autoComplete="off"
                                  autoFocus
                                  requiredIndicator
                                  label="Tên dịch vụ"
                                  {...fields.logistic_name} />

                            <TextField
                                autoComplete="off"                                  
                                  label="Tên Module"
                                  helpText="Mỗi một nhà vận chuyển có một tên mã Module mà server hỗ trợ. Vui lòng liên hệ tới quản trị viên để bổ sung vào danh sách."
                                  {...fields.module} />

                            <TextField
                              autoComplete="off"
                              maxLength={250}
                              label="Mô tả"
                              {...fields.logistic_description} 
                              showCharacterCount={true}
                              multiline={2} 
                            />
                        
                        <Text as="p" variant="headingSm">Phương thức giao nhận</Text>
                        <OptionList
                                title=""
                                onChange={onChangeDeliveryMethod}
                                options={orderDeliveryList}
                                selected={deliverySelected}
                                allowMultiple
                              />
      

                        <ChoiceList
                              title="Tình trạng sử dụng"
                              choices={[
                              {
                                  label: 'Tắt',
                                  value: '0',
                                  helpText: "Tạm thời ngừng sử dụng dịch vụ vận chuyển này"
                              },
                              {
                                  label: 'Bật',
                                  value: '1',
                                  helpText: "Tất cả mọi người đều xem được và thêm được vào đơn hàng."
                              }
                              ]}
                              selected={ [logisticStatusOption.value] }
                              onChange={([value]) => logisticStatusOption.onChange(value)}
                          />

                    </FormLayout>
                  </Form>


            </Modal.Section>
        </Modal>
            
    </>
  );
}