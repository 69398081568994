import { DataTable, Divider, InlineStack, LegacyCard, LegacyStack, Text, Thumbnail, ResourceList, BlockStack, InlineGrid } from "@shopify/polaris";
import { LockedOrder, OrderStatus } from "enum/order.enum";
import __helpers from "helpers/index";
import { OrderProduct, TypedOrderFee } from "interface/order.model";
import {
    ProductIcon,
    NoteIcon,
    ProductCostIcon,
} from '@shopify/polaris-icons';
import { Dispatch, useEffect, useState } from "react";
interface TypedProps {
    orderToProduct: OrderProduct[];
    orderIsLock: LockedOrder;
    orderStatus: OrderStatus;
    ordersFee: TypedOrderFee[];
    orderId: string;
    orderTotalMustPay?: number
    savingQuantityThisIndex: (order_product_id: string, newVal: number) => void;
    deleteOrderProductCallback: (order_product_id: string) => void;
    showProductSearchForm: () => void;
    setShowModelCustomFeeForm: Dispatch<React.SetStateAction<boolean>>;
    setShowTransactionModal: Dispatch<React.SetStateAction<boolean>>;
}
const OrderProducts = (props: TypedProps) => {

    const {
        orderIsLock,
        orderToProduct,
        // orderId,
        // orderStatus,
        ordersFee,
        // savingQuantityThisIndex,
        // deleteOrderProductCallback,
        // showProductSearchForm,
        setShowModelCustomFeeForm,
        // setShowTransactionModal
        orderTotalMustPay
    } = props;

    // state
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalFee, setTotalFee] = useState(0);
    const [totalProductUnit, setTotalProductUnit] = useState(0);

    // end state

    /**
     * Cập nhật tổng phí. realtime
     */
    useEffect(() => {
        let tPrice = 0;
        if (__helpers.isEmpty(ordersFee)) return;
        for (let productFee of ordersFee) {
            tPrice += productFee.order_fee_value;
        }
        setTotalFee(tPrice);
    }, [ordersFee]);

    // khởi tạo số lượng của mỗi sản phẩm

    const [quantityValue, setQuantityValue] = useState({});
    useEffect(() => {
        let quantityAll = {};
        if (__helpers.isEmpty(orderToProduct)) return;
        for (let product of orderToProduct) {
            quantityAll = {
                ...quantityAll, ...{
                    [product.order_product_id]: product.item_quantity
                }
            }
        }
        setQuantityValue(quantityAll);

    }, [orderToProduct]);
    /**
     * Khối xử lý khởi tạo và click thay đổi số lượng hàng nhanh...
     */

    useEffect(() => {
        if (__helpers.isEmpty(orderToProduct)) return;
        let tPrice = 0, tProductUnit = 0;
        for (let product of orderToProduct) {
            const orderPrdId = product.order_product_id
            tPrice += (quantityValue[orderPrdId] * product.product_price);
            tProductUnit += quantityValue[orderPrdId];
        }
        setTotalPrice(tPrice);
        setTotalProductUnit(tProductUnit);
    }, [quantityValue]);

    // function updateOrderProductQuantity(newValue: any, order_product_id: any) {
    //     if (isNaN(newValue)) return;

    //     setQuantityValue({
    //         ...quantityValue, ...{
    //             [order_product_id]: +newValue
    //         }
    //     });
    // };

    /**
       * Kết thúc khối xử lý click thay đổi số lượng hàng nhanh...
       */


    const renderItemProduct = (product: OrderProduct, orderTotalMustPay: number) => {
        
        return [
            (
                <div style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center'
                }} >
                    <Thumbnail size="small" source={product?.product_data?.product_media?.[0] || NoteIcon} alt="" />
                    <p style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        display: 'block',
                        width: '75%'
                    }}>{product.product_data?.product_name}</p>
                </div>
            ),
            // product.order_quantity, 
            // <div style={{ width: '80px' }}>
            //     <TextField
            //         label=""
            //         max={99}
            //         min={1}
            //         disabled={!!orderIsLock}
            //         value={quantityValue[product.order_product_id]}
            //         onBlur={() => {
            //             savingQuantityThisIndex(product.order_product_id, quantityValue[product.order_product_id]);
            //         }}
            //         onChange={(newValue: any) => {
            //             const orderPrdId = product.order_product_id;
            //             if (quantityValue[orderId] !== newValue) {
            //                 updateOrderProductQuantity(newValue, orderPrdId);
            //             }
            //         }}
            //         autoComplete="off"
            //     /></div>,
            quantityValue[product.order_product_id],
            <span>{__helpers.formatNumber(product.product_original_price, '.')} vnđ</span>,
            <span>{__helpers.formatNumber(product.product_price, '.')} vnđ</span>,
            <span>{__helpers.formatNumber(orderTotalMustPay * quantityValue[product.order_product_id], '.')} vnđ</span>,
            // orderStatus === OrderStatus.DRAFT || orderStatus === OrderStatus.APPROVED ?
            //     <Link onClick={() => { deleteOrderProductCallback(product.order_product_id) }}><Icon source={XSmallIcon} tone="base" /></Link>
            //     : null
        ]
    }

    return (
        <>
            <LegacyCard>
                <LegacyCard.Header
                    title={<div className="small-icon"><Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg"><ProductIcon /> Sản phẩm </Text></div>}
                    // actions={[{
                    //     content: 'Thêm sản phẩm',
                    //     disabled: !!orderIsLock,
                    //     onAction: () => {
                    //         showProductSearchForm()
                    //     }
                    // }]}
                >
                </LegacyCard.Header>
                <LegacyCard.Section fullWidth>
                    {
                        (!__helpers.isEmpty(orderToProduct)) ?
                            <>
                                <DataTable
                                    columnContentTypes={[
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                    ]}
                                    headings={[
                                        'Sản phẩm',
                                        'S.lượng',
                                        "Giá niêm yết",
                                        "Giá bán",
                                        "Thành tiền",
                                    ]}
                                    rows={orderToProduct.map((res) => renderItemProduct(res, orderTotalMustPay))}
                                />
                            </>
                            : (
                                <div style={{ padding: '0 20px' }}>
                                    <Text as="p" tone="critical">
                                        Chưa có sản phẩm nào trong đơn này!
                                    </Text>
                                </div>
                            )
                    }
                </LegacyCard.Section>

                <LegacyCard.Section title="Tóm tắt" subdued>
                    <br />
                    <InlineStack gap={"300"}>
                        <LegacyStack>
                            <LegacyStack.Item fill>
                                <Text variant="bodyMd" as="p">
                                    Số hàng
                                </Text>
                            </LegacyStack.Item>
                            <LegacyStack.Item>
                                <Text variant="bodyMd" as="p" fontWeight="bold">
                                    {totalProductUnit} đơn vị
                                </Text>
                            </LegacyStack.Item>
                        </LegacyStack>
                        <Divider />
                        <LegacyStack>
                            <LegacyStack.Item fill>
                                <Text variant="bodyMd" as="p">
                                    Tổng tiền hàng
                                </Text>
                            </LegacyStack.Item>
                            <LegacyStack.Item>
                                <Text variant="bodyMd" as="p" fontWeight="bold">
                                    {__helpers.formatNumber(totalPrice, '.')} vnđ
                                </Text>
                            </LegacyStack.Item>
                        </LegacyStack>
                    </InlineStack>
                </LegacyCard.Section>
            </LegacyCard>

            <LegacyCard>
                <LegacyCard.Header
                    title={<div className="small-icon"><Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg"><ProductCostIcon /> Các chi phí </Text></div>}
                    actions={[{
                        content: 'Thêm chi phí tùy chỉnh',
                        disabled: !!orderIsLock,
                        onAction: () => {
                            setShowModelCustomFeeForm(true)
                        }
                    }]}
                >
                </LegacyCard.Header>

                <LegacyCard.Section fullWidth>
                    <div style={{ padding: ' 0 20px' }}>
                        <Text as='p' variant="bodyMd" tone="critical">
                            Bạn có thể lên phí tùy chỉnh tùy theo thỏa thuận giữa sale và khách hàng.
                        </Text>
                    </div>

                    <br />

                    {
                        !__helpers.isEmpty(ordersFee) ?
                            <ResourceList
                                resourceName={{ singular: 'sale', plural: 'sales' }}
                                items={ordersFee}
                                renderItem={(item) => {
                                    const { order_fee_name, order_fee_value, order_fee_id } = item;
                                    return (
                                        <ResourceList.Item
                                            id={order_fee_name.toLocaleLowerCase()}
                                            url={'#'}
                                            key={`` + order_fee_id}
                                        >
                                            <LegacyStack>
                                                <LegacyStack.Item fill>{order_fee_name}</LegacyStack.Item>
                                                <LegacyStack.Item>{__helpers.formatNumber(order_fee_value, ',')}</LegacyStack.Item>
                                            </LegacyStack>
                                        </ResourceList.Item>
                                    );
                                }}
                            />
                            : null
                    }

                </LegacyCard.Section>

            </LegacyCard>


            <LegacyCard>
                {/* <LegacyCard.Header
                    title={<div className="small-icon"><Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg"><PaymentIcon /> Giao dịch </Text></div>}
                    actions={[{
                        content: 'Thêm giao dịch',
                        disabled: !!orderIsLock,
                        onAction: () => {
                            setShowTransactionModal(true);
                        }
                    }]}
                >
                </LegacyCard.Header>
                <LegacyCard.Section fullWidth>

                    <OrderTransaction order_id={orderId} />
                </LegacyCard.Section> */}
                <LegacyCard.Section title="Tổng phải thanh toán" subdued>
                    <br />
                    <BlockStack gap={"200"}>
                        <InlineGrid gap="400" columns={2}>
                            <BlockStack gap={"100"} inlineAlign="start"><Text as="p" variant="bodyMd">Tổng tiền hàng</Text></BlockStack>
                            <BlockStack gap={"100"} inlineAlign="end"><Text as="p" variant="bodyMd">{__helpers.formatNumber(totalPrice, '.')} vnđ</Text></BlockStack>
                        </InlineGrid>
                        <Divider />
                        <InlineGrid gap="400" columns={2}>
                            <BlockStack gap={"100"} inlineAlign="start"><Text as="p" variant="bodyMd">Tổng tiền phí</Text></BlockStack>
                            <BlockStack gap={"100"} inlineAlign="end"><Text as="p" variant="bodyMd">{__helpers.formatNumber(totalFee, '.')} vnđ</Text></BlockStack>
                        </InlineGrid>
                        <Divider />
                        <InlineGrid gap="400" columns={2}>
                            <BlockStack gap={"100"} inlineAlign="start"><Text as="p" variant="bodyMd">Phải thanh toán</Text></BlockStack>
                            <BlockStack gap={"100"} inlineAlign="end"><Text as="p" variant="bodyMd">{__helpers.formatNumber(totalPrice + totalFee, '.')} vnđ</Text></BlockStack>
                        </InlineGrid>

                    </BlockStack>
                </LegacyCard.Section>
            </LegacyCard>

        </>
    )
};

export default OrderProducts;
