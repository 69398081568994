import React from "react";
import { useParams } from "react-router-dom";
import Theme404 from '../../layout/404';

import user_referrer_list from './users.referrer';
// import user_referrer_edit from './commission.edit';

export default function UserRefererIndex() {
    let useParam = {} as any;
    useParam = useParams();
    let Param = useParam.slug || 'list';
    let ActualPage: any;
    switch (Param) {

        case 'list':
            ActualPage = user_referrer_list;
            break;

        case 'edit':
            // ActualPage = user_referrer_edit;
            break;

        default:
            ActualPage = Theme404;
            break;
    }

    return (
        <>
            {<ActualPage />}
        </>
    );
}
