import {
  DatePicker,
  FormLayout,
  Modal,
  TextField,
  Text,
  Form,
  Select,
  RadioButton,
  LegacyStack,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "config/store";
import { createEntity, getEntity } from "store/notification.store.reducer";
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from "@shopify/react-form";
import { useParams } from "react-router-dom";
import QuickSearchUser from "components/quickSearchUser";

export default function NotificationNew({ showModal, closeModal }) {
  const updating = useAppSelector(state => state.notification.updating);

  const dispatch = useAppDispatch();

  /* Date picker */
  function pad(num: number, size = 2) {
    let numbr = num.toString();
    while (numbr.length < size) numbr = '0' + numbr;
    return numbr;
  }

  /**
   * Format date and time
   */
  function extractTimeAndDateFromSource(source: string) {
    let DateObject = new Date();
    try {
      if (source) {
        DateObject = new Date(String(source));
      }
      const _date = pad(DateObject.getDate(), 2) + '/' + pad(DateObject.getMonth() + 1, 2) + '/' + DateObject.getFullYear();
      const _timestamp = DateObject.getTime();
      return {
        date: _date,
        timestamp: _timestamp,
        day: pad(DateObject.getDate(), 2),
        month: DateObject.getMonth(),
        year: DateObject.getFullYear(),
      };
    } catch (_) {
      return {
        date: '',
        timestamp: 0,
        day: 0,
        month: 0,
        year: 0,
      };
    }
  }

  const today_active = extractTimeAndDateFromSource('');
  const [{ month, year }, setDate] = useState({ month: today_active.month, year: today_active.year });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  const handleMonthChange = useCallback((_month, _year) => setDate({ month: _month, year: _year }), []);

  /* End Month, Year change */

  /* Hours */
  const [notification_hour, setNotification_hour] = useState('00');
  const handleHourChange = useCallback((value) => setNotification_hour(value), []);
  const hours: any = [];
  for (let i = 0; i < 24; i++) {
    hours.push(
      { label: pad(i), value: pad(i) }
    );
  }

  /* Minutes */
  const [notification_minute, setNotification_minute] = useState('00');
  const handleMinuteChange = useCallback((value) =>
    setNotification_minute(value), []);
  const minutes = [
    { label: '00', value: '00' },
    { label: '15', value: '15' },
    { label: '30', value: '30' },
    { label: '45', value: '45' },
  ];

  /* Radio button */
  const [userGroup, setUserGroup] = useState('everyone');

  const [hidePerson, setHidePerson] = useState(true);

  const handleRadioButtonChange = useCallback((_checked, newValue) => {
    setUserGroup(newValue);
    if (newValue !== 'everyone') {
      setHidePerson(false);
    } else {
      setHidePerson(true);
    }
  }, []);

  /* Choose person to send */
  const [person, setPerson] = useState("");
  const toggleUpdateActive = useCallback(() => {
    closeModal();
  }, []);

  /**
   * Select
   */

   const [selectedGroup, setSelectedGroup] = useState('/topics/all');
   const handleSelectChange = useCallback((value) => setSelectedGroup(value), []);
   const notificationGroup = [
    {label: 'Tất cả mọi người', value: '/topics/all'},
    {label: 'Tất cả vai trò User', value: '/topics/users'},
    {label: 'Tất cả vai trò Customer', value: '/topics/customers'},
  ];

  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.users_slug || false;
  useEffect(() => {
    if (Param) {
      dispatch(getEntity(Param));
    } else {
      formReset()
    }
  }, []);

  /* Create notification form */
  const {
    fields,
    submit,
    dirty,
    reset: formReset,
  } = useForm({
    fields: {
      notification_title: useField<string>({
        value: '',
        validates: [
          notEmpty('Title must be not empty'),
          lengthMoreThan(5, 'Title must be more than 5 characters'),
          lengthLessThan(200, 'No more than 200 characters.'),
        ],
      }),
      notification_content: useField<string>({
        value: '',
        validates: [
          notEmpty('Content must be not empty'),
          lengthMoreThan(5, 'Content must be more than 5 characters'),
        ],
      })
    },
    async onSubmit(values) {
      try {
        const datety = new Date(selectedDates.start).getDate();
        let rawdate = `${year}/${pad(month + 1, 2)}/${pad(datety)}` + ` ${notification_hour}:${notification_minute}`
          // create new
          dispatch(createEntity({
            notification_title: values.notification_title,
            notification_content: `${values.notification_content}`,
            notification_schedule: new Date(rawdate).getTime(),
            notification_user: userGroup !== 'everyone' ? person : null,
            notification_channel: userGroup === 'everyone' ? selectedGroup : null,
          }))
        formReset();
        closeModal();
        return { status: 'success' };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!';
        const field = e?.response?.data?.errorKey ?? 'base';
        return { status: 'fail', errors: [{ field, message }] };
      }
    },
  });

  const Actual_page = (
    <Modal
      open={showModal}
      onClose={toggleUpdateActive}
      title={
        updating
          ? "Do NOT close this modal or refresh your browser!"
          : "Create notification"
      }
      primaryAction={{
        content: "Create",
        disabled: !dirty,
        loading: updating,
        onAction: submit,
      }}
      secondaryActions={[
        {
          content: "Close",
          disabled: updating,
          onAction: toggleUpdateActive,
        },
      ]}
    >
      <Modal.Section>
        <Form onSubmit={submit}>
            <FormLayout>
              <TextField label="Title" {...fields.notification_title}
                autoFocus
                autoComplete="off" />
              <TextField
                multiline={3}
                label="Content"
                {...fields.notification_content}
                autoComplete="off"
              />
              <Text as="span">Publish date</Text>
              <DatePicker
                month={month}
                year={year}
                onChange={setSelectedDates}
                onMonthChange={handleMonthChange}
                selected={selectedDates}
              />
              <LegacyStack>
                <LegacyStack.Item>
                  <Select
                    label="Hour"
                    options={hours}
                    onChange={handleHourChange}
                    value={notification_hour}
                  />
                </LegacyStack.Item>
                <LegacyStack.Item>
                  <Select
                    label="Minute"
                    options={minutes}
                    onChange={handleMinuteChange}
                    value={notification_minute}
                  />
                </LegacyStack.Item>
              </LegacyStack>
              <LegacyStack vertical>
                <RadioButton
                  label="Gửi tới một nhóm người"
                  checked={userGroup === 'everyone'}
                  id="everyone"
                  name="everyone"
                  onChange={handleRadioButtonChange}
                />

                <Select
                  label=""
                  disabled={!hidePerson}
                  options={notificationGroup}
                  onChange={handleSelectChange}
                  value={selectedGroup}
                />


                <RadioButton
                  label="Send to a specific person"
                  id="person"
                  name="person"
                  checked={userGroup === 'person'}
                  onChange={handleRadioButtonChange}
                />

                <QuickSearchUser title="" user_role="customer" disabled={hidePerson} onClose={(customerData) => setPerson(String(customerData.user_id))} />

              </LegacyStack>

            </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );

  return (
    <>
      {Actual_page}
    </>);
};
