import { DataTable, Page, Toast, InlineStack, Button, Layout, Card, EmptyState, Tooltip, Thumbnail, Badge } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import dateandtime from 'date-and-time';
import { getCategories, clearError, deleteCategoryProduct } from 'store/product_category.store.reducer';
import { useAppDispatch, useAppSelector } from "config/store";
import Pagination from "components/pagination";
import helpers from "helpers/index";
import __ from "languages/index";
import {
  PlusIcon,
  EditIcon,
  DeleteIcon,
  NoteIcon,
  DatabaseAddIcon
} from '@shopify/polaris-icons';
import emptyIMG from "../../media/images/empty.png";
import { useNavigate } from "react-router-dom";
import SkeletonLoading from "components/skeletonLoading";
import ProductCategoryNewModal from './component/product_category.new.modal'
import { useGetThumbnail, wrapText } from "utils";

export default function ProductCategoryList() {

  const loading = useAppSelector((state) => state.product_category.loading);
  const updating = useAppSelector((state) => state.product_category.updating);
  const updateSuccess = useAppSelector((state) => state.product_category.updateSuccess);
  const entities = useAppSelector((state) => state.product_category.entities);
  const [showModal, setShowModal] = useState(false)
  const totalItems = useAppSelector((state) => state.product_category.totalItems);
  const [idProductCategory, setIdProductCategory] = useState('')
  const account = useAppSelector(state => state.user.account);
  const { ref, getThumbnailUrl } = useGetThumbnail();

  const dispatch = useAppDispatch();
  const history = useNavigate();

  const rootSearchPath = window.location.search;
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;

  const initialQuery = {
    query: "",
    page: 1,
    limit: 40,
    sort: "createdAt:desc",
  };

  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery,
  });



  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({ ...mainQuery, page: numPage, limit: limit });
  }, [mainQuery]);

  useEffect(() => {
    dispatch(getCategories(mainQuery));
  }, [mainQuery]);

  const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
  }, []);

  // const deleteStock = useCallback((stock_id) => {
  //     dispatch( deleteEntity(stock_id) );
  // }, []);


  /** Clear Error */
  useEffect(() => {
    clearErrorCallback();
  }, []);

  // onClose modal
  const onClose = () => {
    setShowModal(false);
    setIdProductCategory('')
  }

  // call api delete user referrer commission
  const handleDeleteProductCategory = (category_id) => {
    if (window.confirm('Bạn có chắc chắn muốn xoá ?')) {
      dispatch(deleteCategoryProduct(category_id))
    }
  }

  const emptyData = (
    <EmptyState heading="Dữ liệu trống" image={emptyIMG}>
      <p>Không tìm thấy danh mục sản phẩm</p>
    </EmptyState>
  );

  const toastSuccessMarkup = updateSuccess ? (
    <Toast content={'Cập nhật thành công!'} onDismiss={clearErrorCallback} />
  ) : null;

  const items = (commission, index) => {
    const {
      category_id,
      category_name,
      category_description,
      category_count,
      category_thumbnail,
      createdAt,
    } = commission;

    return [
      index + 1,
      <InlineStack gap={"200"} align="start">
        <Thumbnail size="small" source={category_thumbnail ? getThumbnailUrl(category_thumbnail, 40, 40) : NoteIcon} alt="" />
        <span>{category_name}</span>
      </InlineStack>,
      category_description ? <div dangerouslySetInnerHTML={{ __html: wrapText(category_description, 50) }}></div> : <Badge></Badge>,
      category_count,
      createdAt ? <span>{dateandtime.format(new Date(+createdAt), 'DD-MM-YYYY')}</span> : '',
      <InlineStack gap={"200"} align="start" blockAlign="center">
        <Tooltip content={`Danh sách sản phẩm của danh mục ${category_name}`}>
          <Button variant="tertiary" onClick={() => history(`/product_category/detail/${category_id}`, { state: { category_name: category_name } })} icon={DatabaseAddIcon} />
        </Tooltip>
        {(['admin', 'super_admin'].includes(account?.user_role)) &&
          <Tooltip content="Sửa danh mục">
            <Button variant="tertiary" icon={EditIcon} onClick={() => {
              setIdProductCategory(category_id)
              setShowModal(true)
            }} />
          </Tooltip>
        }
        {(['admin', 'super_admin'].includes(account?.user_role)) &&
          <Tooltip content="Xoá danh mục">
            <Button variant="tertiary" icon={DeleteIcon} onClick={() => handleDeleteProductCategory(category_id)} />
          </Tooltip>}
      </InlineStack>
    ]
  }

  const productCategoryList = (
    <>
      {
        entities && entities.length > 0 ? (
          <DataTable
            hideScrollIndicator
            columnContentTypes={[
              'text',
              'text',
              'text',
              'text',
              'text',
              'text',
              'text',
            ]}
            headings={[
              "STT",
              "Tên danh mục",
              "Mô tả danh mục",
              "Số lượng sản phẩm thuộc danh mục",
              "Ngày tạo",
              (['admin', 'super_admin'].includes(account?.user_role)) && "Hành động",
            ]}
            rows={entities?.map(items)}
            footerContent={`Showing ${entities?.length} of ${entities?.length} results`}
            hasZebraStripingOnData
            increasedTableDensity
          />
        ) : emptyData
      }
    </>
  )

  return (
    <>
      {toastSuccessMarkup}
      {(loading || updating) ? <SkeletonLoading /> :
        <Page
          fullWidth
          title={"Danh mục sản phẩm"}
          subtitle="Hoa hồng"
          primaryAction={
            (['admin', 'super_admin'].includes(account?.user_role)) &&
            <Button
              icon={PlusIcon}
              variant="primary"
              onClick={() => {
                setShowModal(!showModal)
              }}>
              Thêm danh mục sản phẩm
            </Button>}
        >
          <Layout>
            <Layout.Section>
              <Card padding={'0'}>
                {productCategoryList}
              </Card>
              <br />
              {totalItems > mainQuery.limit ? (
                <Pagination
                  TotalRecord={totalItems}
                  activeCurrentPage={mainQuery.page}
                  pageSize={mainQuery.limit}
                  onChange={onChangePagination}
                />
              ) : null}
            </Layout.Section>
          </Layout>
        </Page>
      }
      <ProductCategoryNewModal show={showModal} onClose={() => onClose()} product_category_id={idProductCategory} />
    </>
  )
}