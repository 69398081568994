import axios from 'axios';
import { createAsyncThunk, isFulfilled, } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { TypedCategory } from '../interface/product.model';
import { ENDPOINT_URL } from 'constant';

/**
*   Reducer used for front-end, with media.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using media.model.ts
*/


// interface getQuery extends IQueryParams, TypedProduct{};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: null as any as TypedCategory[],
  entity: null as TypedCategory,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};
const apiUrl = `${ENDPOINT_URL}/product_category`;

// Actions
export const getCategories = createAsyncThunk('product_category/fetch_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return axios.get<TypedCategory[]>(requestUrl);
});

export const getEntity = createAsyncThunk('group_user/fetch_entity', async (product_category_id: string) => {
  const requestUrl = `${apiUrl}/${product_category_id}`;
  return axios.get<TypedCategory>(requestUrl);
},
  { serializeError: serializeAxiosError }
);

// edit category product
export const editCategoryProduct = createAsyncThunk(
  'product_category/edit_product_category',
  async (entity: any, thunkAPI) => {
    const { product_category_id, ...dataForm } = entity
    const result = await axios.patch<TypedCategory>(`${apiUrl}/${product_category_id}`, helpers.cleanEntity(dataForm));
    thunkAPI.dispatch(getCategories({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// delete category product
export const deleteCategoryProduct = createAsyncThunk(
  'product_category/delete_product_category',
  async (product_category_id: any, thunkAPI) => {
    const result = await axios.delete<TypedCategory>(`${apiUrl}/${product_category_id}`);
    thunkAPI.dispatch(getCategories({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// create category product
export const createCategoryProduct = createAsyncThunk(
  'product_category/create_product_category',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<TypedCategory>(`${apiUrl}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getCategories({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const Reducer_Product_Category = createEntitySlice({
  name: 'product_category',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = false;
    }
  },
  extraReducers(builder) {
    builder

      .addCase(createCategoryProduct.pending, (state, action) => {
        state.loading = true;
        state.updateSuccess = false;
      })
      .addCase(createCategoryProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess = true;
      })
      .addCase(createCategoryProduct.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Thêm danh mục không thành công";
        state.updateSuccess = false;
      })

      .addCase(editCategoryProduct.pending, (state, action) => {
        state.loading = true;
        state.updating = true;
        state.updateSuccess = false;
      })
      .addCase(editCategoryProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(editCategoryProduct.rejected, (state, action) => {
        state.loading = false;
        state.updating = false;
        state.errorMessage = "Sửa danh mục không thành công";
        state.updateSuccess = false;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.entity = action.payload.data;
        state.loading = false;
      })
      .addMatcher(isFulfilled(getCategories), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: (action.payload as any).data,
          entity: null,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
  },
});

export const { clearError, reset } = Reducer_Product_Category.actions;

// Reducer
export default Reducer_Product_Category.reducer;

export const categoriesSelector = (store) => store.product_category.entities;