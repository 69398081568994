import React from 'react';

import { EmptyState } from '@shopify/polaris';
import Image404 from '../media/images/404.svg';

export default function Theme404() {
    return (
      <div>
          <EmptyState
            heading="404 not found!"
            image={Image404}
          >
            <p>Wow! Perhaps something is going wrong!</p>
          </EmptyState>
      </div>
    );
}