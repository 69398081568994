import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { TypedOrder_fee } from '../interface/order.model';


/**
*   Reducer used for front-end, with order.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using order.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null as any,
  entities: null as TypedOrder_fee[],
  updating: false,
  updateSuccess: false,
  orderFeeList: null as any
};

const apiUrl = 'order_fee';

// Actions

export const getEntities = createAsyncThunk('order_fee/fetch_entity_list', async (order_id: any) => {
  const requestUrl = `${apiUrl}/${order_id}`;
  return await axios.get<any>(requestUrl);
});

export const getOrderFeeList = createAsyncThunk('order_fee/fetch_order_fee_list', async () => {
  return await axios.get<any>(`${apiUrl}/feename`);
});

export const createEntity = createAsyncThunk(
  'order_fee/create_entity',
  async (entity: TypedOrder_fee, thunkAPI) => {
    return await axios.post<TypedOrder_fee>(`${apiUrl}`, helpers.cleanEntity(entity));
    // thunkAPI.dispatch(getEntities(entity.order_id));
  },
  { serializeError: serializeAxiosError }
);


export const deleteEntity = createAsyncThunk(
  'order_fee/delete_entity',
  async (id: string | number, thunkAPI) => {
    return await axios.delete<TypedOrder_fee | any>(`${apiUrl}/${id}`);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_order = createEntitySlice({
  name: 'order_fee',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
    },
    reset: (state) => {
      return {...state, ...initialState};
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createEntity.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload
      })

      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
        };
      })
      .addMatcher(isRejected(createEntity, deleteEntity, getOrderFeeList), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.payload;
      })
      .addMatcher(isFulfilled(getOrderFeeList), (state, action) => {
        state.orderFeeList = action.payload.data;
        state.updating = false;
        state.loading = false;
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        //state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(deleteEntity), state => {
        state.updating = true;
        //state.loading = true;
        state.updateSuccess = false;
      })
      .addMatcher(isPending(getEntities, getOrderFeeList), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { clearError, reset } = Reducer_order.actions;

// Reducer
export default Reducer_order.reducer;


