import axios from 'axios';
import { createAsyncThunk, isFulfilled,  } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { ENDPOINT_URL } from 'constant';
import { TypeCommisionData, TypedEntityCommission } from 'interface/commission.model';
/**
*   Reducer used for front-end, with media.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using media.model.ts
*/


// interface getQuery extends IQueryParams, TypedProduct{};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: null as any as TypeCommisionData[],
  entity: null as  TypeCommisionData,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};
const apiUrlCommission = `${ENDPOINT_URL}/commission`;

//get commissions
export const getEntities = createAsyncThunk('commission/fetch_entity_list', async (object: TypedEntityCommission) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrlCommission}${EndURL}`;
  return await axios.get<TypeCommisionData[]>(requestUrl);
},
{ serializeError: serializeAxiosError }
);

//get commission
export const getEntity = createAsyncThunk('commission/fetch_entity_commission', async (entity?:any) => {
  const { commission_id } = entity
  const requestUrl = `${apiUrlCommission}/${commission_id}`;
  return await axios.get<TypeCommisionData[]>(requestUrl);
},
{ serializeError: serializeAxiosError }
);

// create commission
export const createCommission = createAsyncThunk(
  'commission/create_commission',
  async (entity:any, thunkAPI) => {
    const result = await axios.post<TypeCommisionData>(`${ENDPOINT_URL}/commission`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// edit commission
export const editCommission = createAsyncThunk(
  'commission/edit_commission',
  async (entity: any, thunkAPI) => {
    const { commission_id, ...dataForm }  = entity
    const result = await axios.patch<TypeCommisionData>(`${ENDPOINT_URL}/commission/${commission_id}`, helpers.cleanEntity(dataForm));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// create commission rule
export const createCommissionRule = createAsyncThunk(
  'commission/create_commission_rule',
  async (entity:any, thunkAPI) => {
    const result = await axios.post<TypeCommisionData>(`${ENDPOINT_URL}/commission/rule`, helpers.cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// delete commission rule
export const deleteCommissionRule = createAsyncThunk(
  'commission/delete_commission_rule',
  async (id: string | number) => {
    const requestUrl = `${ENDPOINT_URL}/commission/rule/${id}`;
    return await axios.delete<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

//get commistion product
export const getCommissionProduct = createAsyncThunk('commission/fetch_commission_product', async (entity?:any) => {
  const { product_id } = entity
  const requestUrl = `${apiUrlCommission}/product/${product_id}`;
  return await axios.get<TypeCommisionData[]>(requestUrl);
},
{ serializeError: serializeAxiosError }
);


// Reducer
export const Reducer_Commission = createEntitySlice({
  name: 'commission',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage  = null;
      state.updateSuccess  = false;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = (action.payload as any).data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Hiển thị nhóm hoa hồng không thành công";
      })

      .addCase(getCommissionProduct.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCommissionProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = (action.payload as any).data;
      })
      .addCase(getCommissionProduct.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Hiển thị hoa hồng sản phẩm không thành công";
      })

      .addCase(createCommissionRule.pending, (state, action) => {
        state.loading = true;
        state.updateSuccess  = false;
      })
      .addCase(createCommissionRule.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess  = true;
      })
      .addCase(createCommissionRule.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Thêm nhóm hoa hồng không thành công";
        state.updateSuccess  = false;
      })

      .addCase(deleteCommissionRule.pending, (state, action) => {
        state.loading = true;
        state.updateSuccess  = false;
      })
      .addCase(deleteCommissionRule.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess  = true;
      })
      .addCase(deleteCommissionRule.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Xoá kiểu hoa hồng không thành công";
        state.updateSuccess  = false;
      })

      .addCase(createCommission.pending, (state, action) => {
        state.loading = true;
        state.updateSuccess  = false;
      })
      .addCase(createCommission.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess  = true;
      })
      .addCase(createCommission.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Thêm nhóm hoa hồng không thành công";
        state.updateSuccess  = false;
      })

      .addCase(editCommission.pending, (state, action) => {
        state.loading = true;
        state.updating = true;
        state.updateSuccess  = false;
      })
      .addCase(editCommission.fulfilled, (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess  = true;
      })
      .addCase(editCommission.rejected, (state, action) => {
        state.loading = false;
        state.updating = false;
        state.errorMessage = "Sửa nhóm hoa hồng không thành công";
        state.updateSuccess  = false;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: (action.payload as any).data,
          entity: null,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
  },
});

export const { clearError, reset } = Reducer_Commission.actions;

// Reducer
export default Reducer_Commission.reducer;
