import { Avatar, Form, Icon, InlineStack, LegacyCard, Modal, ResourceItem, ResourceList, Tabs, Text, TextField, Thumbnail } from "@shopify/polaris";
import { useAppDispatch, useAppSelector } from "config/store";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { getEntities } from "store/users.store.reducer";
import { NoteIcon, ArrowRightIcon } from '@shopify/polaris-icons';
import __helpers from "helpers/index";

interface TypedProps {
    show: boolean;
    closeBtn: () => void;
    setDataChangeUserReferrer: any;
    dataChangeUserReferrer: any;
    onSettingUserReferrer: () => void;
    idUserReferrer?: string
}
const ModalAssignUserReferrer = (props: TypedProps) => {
    const { show, closeBtn } = props
    const dispatch = useAppDispatch();
    const [value, setValue] = useState('');
    const entities = useAppSelector((state) => state.users.entities);
    const loading = useAppSelector(state => state.member.loading);
    const [userRefferrer, setUserReferrer] = useState(null);
    const [user, setUser] = useState(null);
    const [selected, setSelected] = useState(0);
    const [dataTab, setDataTabs] = useState([])

    const handleTabChange = useCallback(
        (selectedTabIndex: number) => setSelected(selectedTabIndex),
        [],
    );

    useEffect(() => {
        dispatch(getEntities({}))
    }, []);

    useEffect(() => {
        !show && setSelected(0)
    },[show])

    useEffect(() => {
        if(props?.dataChangeUserReferrer){
            setUserReferrer(props?.dataChangeUserReferrer?.userRefferrer ?? null)
            setUser(props?.dataChangeUserReferrer?.user ?? null)
        }else{
            setUserReferrer(null)
            setUser(null)
        }
    }, [props?.dataChangeUserReferrer])

    const handleTextChange = (newValue: string) => {
        setValue(newValue);
        handleSearchDebounce(newValue)
    }

    useEffect(() => {
        (user && userRefferrer) && props.setDataChangeUserReferrer({
            user: user,
            userRefferrer: userRefferrer
        })
    },[user, userRefferrer])

    const handleSearchDebounce = useMemo(() => {
        return __helpers.debounce((text: string) => {
            dispatch(getEntities({
                query: text,
                limit: 20
            }))

        }, 500)
    }, [])

    const ListUserReferrer = () => {
        return (
            <>
            <TextField
                    label="Tên thành viên giới thiệu"
                    onChange={handleTextChange}
                    autoComplete="off"
                    value={value}
                />
                <br />
                <LegacyCard >
                    <ResourceList
                        resourceName={{ singular: 'product', plural: 'products' }}
                        items={user ? entities.filter(val => val.user_id !== user.user_id) : entities ?? []}
                        loading={loading}
                        renderItem={(item) => {
                            const { user_id, user_avatar, user_email, user_phone, display_name } = item;
                            const media = <Thumbnail source={user_avatar || NoteIcon} alt="" />;

                            return (
                                <ResourceItem
                                    id={user_id as string}
                                    url={null}
                                    key={user_id as string}
                                    onClick={() => {
                                        setUserReferrer(item)
                                    }}
                                    media={media}
                                    accessibilityLabel={`View details for ${display_name}`}
                                >
                                    <Text variant="bodyMd" fontWeight="bold" as="h3">
                                        {display_name}
                                    </Text>
                                    <Text variant="bodyMd" as="h3">
                                        {user_email}
                                    </Text>
                                    <Text variant="bodyMd" as="h3">
                                        {user_phone}
                                    </Text>
                                </ResourceItem>
                            );
                        }}
                    />
                </LegacyCard>
            </>
        )
    }

    const ListUser = () => {
        return (
            <>
            <TextField
                    label="Tên thành viên được giới thiệu"
                    onChange={handleTextChange}
                    autoComplete="off"
                    value={value}
                />
                <br />
                <LegacyCard >
                    <ResourceList
                        resourceName={{ singular: 'product', plural: 'products' }}
                        items={userRefferrer ? entities.filter(val => val.user_id !== userRefferrer.user_id) : entities ?? []}
                        loading={loading}
                        renderItem={(item) => {
                            const { user_id, user_avatar, user_email, user_phone, display_name } = item;
                            const media = <Thumbnail source={user_avatar || NoteIcon } alt="" />;

                            return (
                                <ResourceItem
                                    id={user_id as string}
                                    url={null}
                                    key={user_id as string}
                                    onClick={() => {
                                        setUser(item)
                                    }}
                                    media={media}
                                    accessibilityLabel={`View details for ${display_name}`}
                                >
                                    <Text variant="bodyMd" fontWeight="bold" as="h3">
                                        {display_name}
                                    </Text>
                                    <Text variant="bodyMd" as="h3">
                                        {user_email}
                                    </Text>
                                    <Text variant="bodyMd" as="h3">
                                        {user_phone}
                                    </Text>
                                </ResourceItem>
                            );
                        }}
                    />
                </LegacyCard>
            </>
        )
    }

    const tabs = [
        {
          id: 'user_referrer',
          content: 'Người giới thiệu',
          children: <ListUserReferrer/>
        },
        {
          id: 'user',
          content: 'Người được giới thiệu',
          children: <ListUser/>
        },
      ]

    return (
        <Modal
            activator={null}
            open={show}
            onClose={closeBtn}
            title={
                <>
                    {
                         (user || userRefferrer) ?
                         <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                             {userRefferrer && (
                                 <InlineStack gap={"200"} align="start" blockAlign="center">
                                 <Avatar source={userRefferrer?.user_avatar} name={userRefferrer?.display_name} initials={userRefferrer?.display_name.charAt(0)} size="sm" />
                                     <Text fontWeight="bold" as="h3">
                                         {userRefferrer?.display_name || userRefferrer?.user_email}
                                     </Text>
                                 </InlineStack>
                             )}
                             <div style={{ width: 20}}></div>
                             <div>
                                <Icon
                                source={ArrowRightIcon}
                                tone="base"
                                />
                             </div>
                             <div style={{ width: 20}}></div>
                             {user && (
                                 <InlineStack gap={"200"} align="start" blockAlign="center">
                                 <Avatar source={user?.user_avatar} name={user?.display_name} initials={user?.display_name.charAt(0)} size="sm" />
                                     <Text fontWeight="bold" as="h3">
                                         {user?.display_name || user?.user_email}
                                     </Text>
                                 </InlineStack>
                             )}
                         </div>
                         : <span>Tạo giới thiệu người dùng</span>
                    }
                </>
            }
            primaryAction={{
                content: 'Lưu thông tin',
                onAction: () => props.onSettingUserReferrer(),
                disabled: !(user && userRefferrer)
              }}
              secondaryActions={[
                {
                  content: 'Thoát',
                  onAction: () => closeBtn()
                },
              ]}
        >
            <Modal.Section>
               <br/>
               {
                props.idUserReferrer ?
                < ListUserReferrer/> :
                tabs && 
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                    <LegacyCard.Section>
                        {tabs[selected]?.children}
                    </LegacyCard.Section>
                </Tabs>
               }
            </Modal.Section>
        </Modal>
    )
};


export default memo(ModalAssignUserReferrer)