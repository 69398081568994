import {  useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../config/store";
import {
    BrowserRouter as Router,
    useParams,
} from "react-router-dom";
  
import {
login,
quick_login,
logout,
clearError,
reset,
} from "../store/user.store.reducer";

import {clearError as clearGlobalError} from 'store/global.warning.store.reducer';
import helpers from "helpers/index";


export default function QuickLogin() {
    
    const account = useAppSelector((state) => state.user.account);

    let useParam =  {} as any;
        useParam = useParams();
    let token = useParam.token || '';

  const __passport = (window as any).__passport || '';
  const dispatch = useAppDispatch();


  useEffect( () => {
    const params: any = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop: string): string => searchParams.get(prop),
    });
    if (params.redirect_to) {
      let decodeURI = decodeURIComponent(params.redirect_to);
      localStorage.setItem('redirect', decodeURI);
    } else {
      localStorage.setItem('redirect', process.env.REACT_APP_PUBLIC_URL);
    }
  }, []);

  useEffect(() => {
    dispatch(clearGlobalError());
    dispatch(reset());
    /**
     * Get session and write down to browser
     */
    dispatch(logout());
    dispatch(clearError());
  }, []);


  const loginByOnetimePassword = useCallback( async (token: string) => {
    await helpers.sleep(1000);
    dispatch(
        quick_login({
          device_type: "website",
          device_signature: 'a', /** For notification, but website doesn't need it ... */
          device_uuid: __passport,
          token: token
        })
      );
  }, []);

  useEffect( () => {
    if ( token ) loginByOnetimePassword(token);
  }, [token]);


  useEffect( () => {
    if ( ! helpers.isEmpty(account) ) {
        let redirect_to = localStorage.getItem('redirect') || process.env.REACT_APP_PUBLIC_URL;
        window.location.href = (decodeURIComponent(redirect_to));    
    }
  }, [account]);


    return (
        <>Waiting...</>
    );
}