import { Card, Badge, Link, Page, useIndexResourceState, Toast, EmptyState, DataTable, InlineStack, Avatar, Layout } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react"
import {
  DeleteIcon,
} from '@shopify/polaris-icons';
import {
  addProductToCommission,
  clearError,
  getEntities,
  updateEntities
} from "store/product.store.reducer";
import { getEntities as getEntitiesCommission } from 'store/commission.store.reducer';
import { useAppDispatch, useAppSelector } from "config/store";
import helpers from "helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "components/pagination";
import SkeletonLoading from 'components/skeletonLoading';
import __ from 'languages/index';
import emptyIMG from "media/images/empty.png";
import { useGetThumbnail } from "utils";

export default function ProductListForCategory() {
  const [showActionList, setShowActionList] = useState(null);
  const entities = useAppSelector((state) => state.product.entities);
  const entitiesCommission = useAppSelector((state) => state.commission.entities);
  const totalItems = useAppSelector((state) => state.product.totalItems);
  const updateSuccess = useAppSelector((state) => state.product.updateSuccess);
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { ref, getThumbnailUrl } = useGetThumbnail();

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);


  const toastSuccessMarkup = updateSuccess ? (
    <Toast content={'Cập nhật thành công'} onDismiss={toggleActive} />
  ) : null;

  const onCloseActionList = useCallback((order_id: any) => {
    setShowActionList(!order_id);
  }, []);

  const togglePophoverActionListCallback = useCallback((_s: any) => {
    setShowActionList(showActionList === _s ? false : _s);
  }, [showActionList]);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  const initialQuery = {
    query: "",
    page: 1,
    limit: 25,
    sort: "createdAt:desc",
    "product_to_category.category_id": params?.product_category_id ?? ''
  };

  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...initialQuery,
  });

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({ ...mainQuery, page: numPage, limit: limit });
  }, [mainQuery, params]);



  let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      setMainQuery(m);
    }
  }, []);

  useEffect(() => {
    dispatch(getEntitiesCommission({}));
  }, []);

  useEffect(() => {
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  function getProductDetail(product_id: string) {
    history("/product/edit/" + product_id);
    return;
  }

  const resourceName = {
    singular: "sản phẩm",
    plural: "sản phẩm",
  };
  const resourceIDResolver = (products) => {
    return products.product_id;
  };
  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(entities ?? [], {
    resourceIDResolver,
  });


  const bulkActions = useCallback((_mode: string) => {
    dispatch(updateEntities({
      product_id: selectedResources,
      product_status: _mode
    })).unwrap().then((res) => {
      dispatch(getEntities(mainQuery))
    }).catch((err) => console.log('err E342 >>>', err))
  }, [selectedResources]);

  const promotedBulkActions = [
    {
      content: "Ngừng bán",
      onAction: () => bulkActions('0'),
      icon: DeleteIcon
    },
    {
      content: "Đang bán",
      onAction: () => bulkActions('1'),
    },
  ];

  const handleActionCommission = (commission_id, product_id) => {
    dispatch(addProductToCommission({
      commission_id: commission_id,
      product_id: product_id,
      mainQuery: mainQuery
    })).then(() => {
      onCloseActionList('')
    })
  }

  const shortcutActions = (product_id: number | string) => {
    history("/product/edit/" + product_id);
  };

  const renderOptionCommission = (product_id) => {
    const data = entitiesCommission?.map((val) => {
      return {
        title: '',
        items: [
          {
            content: val.commission_name,
            onAction: () => handleActionCommission(val.commission_id, product_id)
          },
        ]
      }
    })

    return data
  }

  function badgeStatus(_status) {
    switch (_status) {
      case 0:
        return <Badge>Ngừng bán</Badge>
      case 1:
        return <Badge tone="success">Đang bán</Badge>
      default:
        return <Badge tone="info">Chờ nhập kho</Badge>
    }
  }

  const emptyData = (
    <EmptyState heading="Dữ liệu trống" image={emptyIMG}>
      <p>Không tìm thấy người dùng</p>
    </EmptyState>
  );

  const renderItem = (products, index) => {
    const {
      product_id,
      product_media,
      product_name,
      product_status,
      product_original_price,
      product_meta,
    } = products;

    const product_display_name = product_name || 'noname';
    // const birthDay = dateandtime.format(new Date(user_birthday), 'DD/MM/YYYY')
    return [
      index + 1,
      <InlineStack gap={"200"} align="start" blockAlign="center">
        <Avatar source={getThumbnailUrl(product_media[0], 40, 40)} name={product_display_name} initials={product_display_name.charAt(0)} size="sm" />
        <Link
          key={product_id}
          onClick={() => shortcutActions(product_id)}
        >
          {product_name}
        </Link>
      </InlineStack>,
      badgeStatus(product_status),
      <span>{helpers.formatNumber(product_original_price, ".")} vnđ</span>,
      product_meta?.product_sold_quantity || 0
    ];
  };

  const ProductList = (
    <>
      {
        entities?.length > 0 ? (
          <DataTable
            sortable={[
              false,
              false,
              false,
              false,
              false
            ]}
            defaultSortDirection="descending"
            initialSortColumnIndex={0}
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
            ]}
            headings={[
              "STT",
              "Sản phẩm",
              "Trạng thái",
              "Giá bán",
              "Đã bán"
            ]}
            rows={entities?.map(renderItem)}
            hideScrollIndicator
            footerContent={`Hiển thị trang ${mainQuery.page} trong tổng số ${totalItems} kết quả...`}
          />
        ) : (emptyData)
      }
    </>
  );



  return (
    <>
      {entities === null ? <SkeletonLoading fullWidth /> :

        <Page
          fullWidth
          title={`Danh sách sản phẩm của danh mục: ${location?.state?.category_name ?? ''}`}
          backAction={{
            content: 'Danh sách danh mục sản phẩm',
            url: '/product_category'
          }}
        >
          <Layout>
            <Layout.Section>
              <Card padding="0">
                {ProductList}
              </Card>
              <br />
              {
                totalItems > 0 ? (
                  <Pagination
                    TotalRecord={totalItems}
                    onChange={onChangePagination}
                    pageSize={Number(mainQuery.limit)}
                    activeCurrentPage={Number(mainQuery.page)}
                  />
                ) : null
              }
              {toastSuccessMarkup}
            </Layout.Section>
          </Layout>
        </Page>
      }
    </>
  )
}