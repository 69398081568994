import { Form, LegacyCard, Modal, ResourceItem, ResourceList, Text, TextField, Thumbnail } from "@shopify/polaris";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useMemo, useState } from "react";
import { getEntities } from "store/users.store.reducer";
import { NoteIcon } from '@shopify/polaris-icons';
import __helpers from "helpers/index";

interface TypedProps {
    show: boolean;
    closeBtn: () => void;
    selectableCallback: (user_id: string) => void;
}
const ModalAssignUserToGroup = (props: TypedProps) => {
    const { show, closeBtn, selectableCallback } = props
    const dispatch = useAppDispatch();
    const [value, setValue] = useState('');
    const entities = useAppSelector((state) => state.users.entities);
    const loading = useAppSelector(state => state.member.loading);

    useEffect(() => {
        dispatch(getEntities({}))
    }, []);

    const handleTextChange = (newValue: string) => {
        setValue(newValue);
        handleSearchDebounce(newValue)
    }


    const handleSearchDebounce = useMemo(() => {
        return __helpers.debounce((text: string) => {
            dispatch(getEntities({
                query: text,
                limit: 20
            }))

        }, 500)
    }, [])
    
    return (
        <Modal
            activator={null}
            open={show}
            onClose={closeBtn}
            title="Thêm người dùng vào nhóm"
        >
            <Modal.Section>
                <TextField
                    label="Tên tên thành viên"
                    onChange={handleTextChange}
                    autoComplete="off"
                    value={value}
                />
                <br />
                <LegacyCard >
                    <ResourceList
                        resourceName={{ singular: 'product', plural: 'products' }}
                        items={entities ?? []}
                        loading={loading}
                        renderItem={(item) => {
                            const { user_id, user_avatar, user_email, user_phone, display_name } = item;
                            const media = <Thumbnail source={user_avatar || NoteIcon} alt="" />;

                            return (
                                <ResourceItem
                                    id={user_id as string}
                                    url={null}
                                    key={user_id as string}
                                    onClick={() => {
                                        selectableCallback(user_id as string);
                                    }}
                                    media={media}
                                    accessibilityLabel={`View details for ${display_name}`}
                                >
                                    <Text variant="bodyMd" fontWeight="bold" as="h3">
                                        {display_name}
                                    </Text>
                                    <Text variant="bodyMd" as="h3">
                                        {user_email}
                                    </Text>
                                    <Text variant="bodyMd" as="h3">
                                        {user_phone}
                                    </Text>
                                </ResourceItem>
                            );
                        }}
                    />
                </LegacyCard>

            </Modal.Section>
        </Modal>
    )
};


export default ModalAssignUserToGroup