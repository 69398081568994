import React from "react";
import { useParams } from "react-router-dom";
import Theme404 from '../../layout/404';

import product_list from './product.list';
import product_edit from './product.edit';
import product_variant from './product.variant';



export default function Pagidex() {
    let useParam =  {} as any;
        useParam = useParams();
    let Param = useParam.slug || 'list';

    let ActualPage: any;
    switch (Param) {
        
        case 'list':
            ActualPage = product_list;
        break;

        case 'edit':
            ActualPage = product_edit;
        break;

        case 'new':
            ActualPage = product_edit;
        break;

        // Tạm ẩn chức năng variant
        // case 'variant':
        //     ActualPage = product_variant;
        // break;


        default:
            ActualPage =  Theme404;
        break;
    }

    return (
        <>
            {<ActualPage />}
        </>
    );
}
