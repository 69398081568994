import {
  FormLayout,
  Modal,
  TextField,
  Form,
  Toast,
  RadioButton,
} from "@shopify/polaris";
import { memo, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "config/store";
import { clearError, createBanner, editBanner, getEntity } from "store/setting_banner.store.reducer";
import { useParams } from "react-router-dom";

import {
  lengthLessThan,
  lengthMoreThan,
  useField,
  useForm,
} from "@shopify/react-form";

import __, { ___ } from "languages/index";
import ThumbnailBannerUpload from "./thumbnail.upload";
import { BANNER_META_TYPE } from "constant";
import BannerMetaInfomation from "./banner_meta_infomation";

function BannerNewModal({ onClose, show, banner_id, bannerType }: { onClose: Function, show: boolean, banner_id?: string, bannerType?: string }) {
  const updating = useAppSelector((state) => state.setting_banner.updating);
  const updateSuccess = useAppSelector((state) => state.setting_banner.updateSuccess);
  const entity = useAppSelector((state) => state.setting_banner.entity);
  const [internalErrorNotice, setInternalErrorNotice] = useState("");
  const [bannerMetaType, setBannerMetaType] = useState('product');
  const dispatch = useAppDispatch();

  useEffect(() => {
    banner_id && dispatch(getEntity(banner_id))
  }, [banner_id])
  /**
   * Lấy URL ra... để query. chú ý phải load nội dung mới 
   * trong useEffect
   */
  let useParam = {} as any;
  useParam = useParams();

  const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
  }, []);

  useEffect(() => {
    entity && setBannerMetaType(Object.keys(entity.banner_meta).toString())
  },[entity])

  const successNotice = updateSuccess ? (
    <Toast content={'Cập nhật thành công!'} onDismiss={clearErrorCallback} />
  ) : null;

  const useFields = {
    banner_image_url: useField<string[]>({
      value: entity && banner_id ? [entity?.banner_image_url] : [],
      validates: [
      ],
    }),

    banner_title: useField<string>({
      value: (entity && banner_id) && entity?.banner_title ? entity?.banner_title : '',
      validates: [
        lengthLessThan(250, __('lengthLessThan')),
        lengthMoreThan(1, __('lengthMoreThan')),
        inputValue => {
          if (!inputValue) {
            return __('require');
          }
        }
      ],
    }),

    banner_description: useField<string>({
      value: (entity && banner_id) && entity?.banner_description ? entity?.banner_description : '',
      validates: [
      ],
    }),

    banner_meta_product: useField<string>({
      value: (entity && banner_id) && entity?.banner_meta.product ? entity?.banner_meta.product : '',
      validates: [

      ],
    }),

    banner_meta_category: useField<string>({
      value: (entity && banner_id) && entity?.banner_meta.category ? entity?.banner_meta.category : '',
      validates: [

      ],
    }),


    banner_meta_link: useField<string>({
      value: (entity && banner_id) && entity?.banner_meta.link ? entity?.banner_meta.link : '',
      validates: [

      ],
    }),
  }

  const {
    fields,
    submit,
    dirty,
    reset: resetForm,
  } = useForm({
    fields: useFields,

    async onSubmit(values) {
      try {
        if (entity && banner_id) {
          await dispatch(
            editBanner({
              banner_id: banner_id,
              banner_title: values.banner_title,
              banner_description: values.banner_description,
              banner_image_url: values.banner_image_url[0] ?? "",
              banner_status: 1,
              banner_type: bannerType,
              banner_meta: getBannerData()
            })
          );
        } else {
          // create new
          await dispatch(
            createBanner({
              banner_title: values.banner_title,
              banner_description: values.banner_description,
              banner_image_url: values.banner_image_url[0] ?? "",
              banner_status: 1,
              banner_type: bannerType,
              banner_meta: getBannerData()
            })
          );
        }
        resetForm();
        onClose()
        return { status: "success" };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message =
          e?.response?.data?.title ?? "Undefined error. Try again!";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });

  /* End form */
  useEffect(() => {
    if (updateSuccess) {
      resetForm();
      onClose();
      dispatch(clearError());
    }
  }, [updateSuccess]);

  const getBannerData = () => {
    switch (bannerMetaType) {
      case BANNER_META_TYPE.PRODUCT:
        return {
          product: fields.banner_meta_product.value
        }
        break;
      case BANNER_META_TYPE.CATEGORY:
        return {
          category: fields.banner_meta_category.value
        }
        break;
      case BANNER_META_TYPE.LINK:
        return {
          link: fields.banner_meta_link.value
        }
        break;
      default:
        break;
    }
  }

  const handUploadError = useCallback((err: any) => {
    setInternalErrorNotice(err);
  }, []);

  const Actual_page = (
    <Modal
      open={show}
      onClose={() => {
        onClose();
        resetForm();
      }}
      title={entity && banner_id ? "Sửa thông tin banner" : "Tạo mới banner"}
      primaryAction={{
        content: "Lưu thông tin",
        disabled: !dirty,
        loading: updating,
        onAction: submit,
      }}
      secondaryActions={[
        {
          content: "Thoát",
          onAction: () => onClose(),
        },
      ]}
    >
      <Modal.Section>
        <Form onSubmit={submit}>
          <FormLayout>
            <ThumbnailBannerUpload
              fieldUrl={fields.banner_image_url}
              onError={handUploadError}
            />
            <TextField
              autoComplete="off"
              autoFocus
              label={'Tên banner'}
              {...fields.banner_title}
            />
             <TextField
              autoComplete="off"
              autoFocus
              label={'Mô tả chi tiết'}
              multiline={4}
              {...fields.banner_description}
            />
            <div>
              <RadioButton
                label="Banner áp dụng cho sản phẩm"
                id={`banner_meta_product`}
                name="banner_meta_product"
                checked={bannerMetaType === BANNER_META_TYPE.PRODUCT}
                onChange={() => setBannerMetaType(BANNER_META_TYPE.PRODUCT)}
              />
            </div>
            <div>
              <RadioButton
                label="Banner áp dụng cho danh mục sản phẩm"
                id={`banner_meta_category`}
                name="banner_meta_category"
                checked={bannerMetaType === BANNER_META_TYPE.CATEGORY}
                onChange={() => setBannerMetaType(BANNER_META_TYPE.CATEGORY)}
              />
            </div>
            <div>
              <RadioButton
                label="Gắn link cho banner"
                id={`banner_meta_link`}
                name="banner_meta_link"
                checked={bannerMetaType === BANNER_META_TYPE.LINK}
                onChange={() => setBannerMetaType(BANNER_META_TYPE.LINK)}
              />
            </div>
            <BannerMetaInfomation
              bannerMetaType={bannerMetaType}
              fieldBannerMetaProduct={fields.banner_meta_product}
              fieldBannerMetaCategory={fields.banner_meta_category}
              fieldBannerMetaLink={fields.banner_meta_link}
            />
            <br/>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );

  return (
    <>
      {successNotice}
      {internalErrorNotice ? (
        <Toast
          content={internalErrorNotice}
          error
          onDismiss={() => setInternalErrorNotice("")}
        />
      ) : null}
      {Actual_page}
    </>
  );
}

export default memo(BannerNewModal)
