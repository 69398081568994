import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import { Badge, Button, Divider, Layout, LegacyCard, Loading, Page, Select, Text, TextField, InlineGrid, BlockStack, Popover, InlineStack, Icon, Tooltip } from "@shopify/polaris";
import orderDeliverType from './order.delivery.json';
import Theme404 from 'layout/404';
import TransactionNewModal from '../transactions/transaction.new';
import {
  clearError,
  getEntity,
  updateEntity,
  reset as resetOrderData,
  assignProductChangeQuantity,
  assignOrderFee,
  assignOrderProduct,
  unassignOrderProduct,
  setStatusEntity
} from "store/order.store.reducer";

import {
  NoteIcon,
  ProfileIcon,
  ChevronDownIcon,
  CheckIcon,
  ArrowRightIcon,
  LockIcon
} from '@shopify/polaris-icons';
import helpers from "../../helpers";
import SkeletonLoading from "components/skeletonLoading";
import dateandtime from 'date-and-time';
import OrderCustomerSearchForm from './order.customer.search.form';
import OrderProductQuickSearchForm from '../../components/orderProductQuickSearchForm';
import ShowCustomFee from './order_fee.modal';
import __ from "languages/index";
import { LockedOrder, OrderStatus, PaymentStatus } from "enum/order.enum";
import { TypedOrderFee } from "interface/order.model";
import OrderProducts from "./order_detail/order.products";
import OrderLogistics from "./order_detail/order.logistics";


export const paymentStatus = [
  {
    value: PaymentStatus.UNPAID.toString(),
    label: __('unpaid')
  },
  {
    value: PaymentStatus.PARTIAL.toString(),
    label: __('partial')
  },
  {
    value: PaymentStatus.PAID.toString(),
    label: __('paid')
  },
]

export default function OrderView() {
  const account = useAppSelector(state => state.user.account);
  /**
   * orders
   */
  const entity = useAppSelector((state) => state.order.entity);
  const loading = useAppSelector((state) => state.order.loading);
  const updating = useAppSelector((state) => state.order.updating);
  const updateSuccess = useAppSelector((state) => state.order.updateSuccess);

  // state
  const [orderFees, setOrderFees] = useState<TypedOrderFee[]>([])
  const [orderDeliveryMethod, setOrderDeliveryMethod] = useState<string>('');


  // ----
  const dispatch = useAppDispatch();
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.order_id || false;

  useEffect(() => {
    // if ( ! Param ) return;
    dispatch(clearError());
    dispatch(resetOrderData());
    reloadData();
  }, []);

  const reloadData = useCallback(() => {
    dispatch(getEntity(Param));
  }, [Param]);

  useEffect(() => {
    if (!helpers.isEmpty(entity)) {
      setOrderFees(entity.orders_fee);
      setOrderDeliveryMethod(entity.order_delivery_type)
    }
    QueryCustomerAddr();
  }, [entity])

  /**
   * Handle selected order status change ...
   */
  const handleSelectedChange = useCallback(
    (value: OrderStatus) => {
      if (entity.locked !== 0) return;
      dispatch(updateEntity({
        order_id: Param,
        order_status: value
      }))
    },
    [entity],
  );

  /**
   * Phương pháp vận chuyển
   */
  // const handleDeliveryChange = useCallback(
  //   (value: string) => {
  //     setOrderDeliveryMethod(value);
  //     dispatch(updateEntity({
  //       order_id: entity?.order_id,
  //       order_delivery_type: value
  //     }));
  //   }, [entity]
  // )


  const subtractDate = (thatday: any) => {
    const today = dateandtime.format(new Date(), 'YYYY/MM/DD HH:mm:ss');
    const tday = dateandtime.subtract(new Date(today), new Date(Number(thatday)));
    return Math.ceil(tday.toDays());
  }

  /**
   * Quick note
   */
  const [showQuickNote, setShowQuickNote] = useState(false);
  const [valuetext, setValuetext] = useState('');



  const [diachiTinh, setDiachiTinh] = useState('');
  const [diachiHuyen, setDiachiHuyen] = useState('');
  const [diachiXa, setDiachiXa] = useState('');
  const QueryCustomerAddr = useCallback(async () => {
    let diachiTinhData = await helpers.getDiaChinh(entity?.orders_to_address?.order_customer_address_city);
    setDiachiTinh(diachiTinhData?.name_with_type);
    let diachiHuyenData = await helpers.getDiaChinh(entity?.orders_to_address?.order_customer_address_district);
    setDiachiHuyen(diachiHuyenData?.name_with_type);
    let diachiXaData = await helpers.getDiaChinh(entity?.orders_to_address?.order_customer_address_ward);
    setDiachiXa(diachiXaData?.name_with_type);
  }, [entity]);

  useEffect(() => {
    if (updateSuccess === true) {
      setShowQuickNote(false);
    }
  }, [updateSuccess]);

  function saveQuickNoteButtonCallback() {
    dispatch(updateEntity({
      order_id: entity?.order_id,
      order_note: valuetext
    }))
  }

  const handleQuickNoteChange = useCallback(
    (newValue: string) => {
      setValuetext(newValue);
    },
    [],
  );

  /**
   * Show customer form search ...
   */
  const [showModelCustomerSearchForm, setShowModelCustomerSearchForm] = useState(false);
  function showCustomerSearchForm() {
    setShowModelCustomerSearchForm(true);
  }

  function onCloseshowModelCustomerSearchForm(user_id?: any) {
    // save data and close Modal...
    if (user_id) {
      // dispatch( updateEntity({
      //   order_id: entity?.order_id,
      //   user_id: user_id
      // }))
    }
    setShowModelCustomerSearchForm(false);
  }

  /**!SECTION
   * Show product form search
   */

  const [showModelProductSearchForm, setShowModelProductSearchForm] = useState(false);
  function showProductSearchForm() {
    setShowModelProductSearchForm(true);
  }

  function onCloseshowModelProductSearchForm(product_id?: any) {
    // save data and close Modal...
    if (product_id) {
      dispatch(assignOrderProduct({
        order_id: Param,
        product_id: product_id,
        item_quantity: 1
      }))
    }
    setShowModelProductSearchForm(false);
  }
  /**
   * Thêm một giao dịch
   */

  const [showTransactionModal, setShowTransactionModal] = useState(false);
  function afterCloseTransactionModal() {
    setShowTransactionModal(false);
  }

  /**
   * Thêm chi phí cho một đơn hàng ...
   */
  const [showModelCustomFeeForm, setShowModelCustomFeeForm] = useState(false);

  const handleAssignOrderfee = (value) => {
    dispatch(assignOrderFee({
      ...value,
      order_id: Param
    })).then((res) => {
      const data = res?.payload?.['data'];
      setOrderFees(oldState => ([data, ...oldState]));
      setShowModelCustomFeeForm(false);
    }).catch((e) => console.log('error E2435 >>>', e));
  }

  /**
   * Product list
   */
  function deleteOrderProductCallback(order_product_id: any) {
    dispatch(unassignOrderProduct({
      order_product_id: order_product_id,
      order_id: Param,
    }));
  }

  /**
   * Lưu số lượng ngay khi click ra ngoài!
   * @param order_product_id 
   */
  function savingQuantityThisIndex(order_product_id: string, newVal: number) {
    dispatch(assignProductChangeQuantity({
      order_product_id: order_product_id,
      item_quantity: Number(newVal)
    }))
  }

  /**
   * Lưu số lượng ngay khi click ra ngoài!
   * @param order_product_id 
   */

  // function markThisOrderPrintPackingSlip() {
  // dispatch( updateEntity({
  //   order_id: entity?.order_id,
  //   order_print_packing_slip: 1
  // }));

  // window.open(process.env.REACT_APP_AJAX_URL + '/orders/packing_slip/print/' + entity?.order_id, '_blank');
  // }

  // function getBadgeOfPaymentMethod(_TTTT: string | number) {
  //   switch (_TTTT) {
  //     case 0:
  //       return <Badge progress="incomplete" tone="attention">Chưa thanh toán</Badge>
  //     case 1:
  //       return <Badge progress="partiallyComplete" tone="warning">Thanh toán một phần</Badge>
  //     case 2:
  //       return <Badge tone="success" progress="complete">Đã thanh toán</Badge>
  //     case 3:
  //       return <Badge tone="critical" progress="incomplete">Chờ refund</Badge>
  //     case 4:
  //       return <Badge tone="critical" progress="incomplete">Đã refund</Badge>
  //     default:
  //       return <Badge></Badge>;
  //   }
  // }

  function getOrderStatusBadge(_status: OrderStatus): any {
    switch (_status) {
      case OrderStatus.DRAFT:
        return <Badge tone={"attention"}>{__(`order_status_${_status}`)}</Badge>;
      case OrderStatus.APPROVED:
        return <Badge tone="success">{__(`order_status_${_status}`)}</Badge>;
      case OrderStatus.TRANSPORTING:
        return <Badge tone="info">{__(`order_status_${_status}`)}</Badge>;
      case OrderStatus.COMPLETED:
        return <Badge tone="success-strong">{__(`order_status_${_status}`)}</Badge>;
      case OrderStatus.CANCEL:
        return <Badge tone="critical">{__(`order_status_${_status}`)}</Badge>;
      case OrderStatus.CUSTOMER_CANCEL:
        return <Badge tone="critical">{__(`order_status_${_status}`)}</Badge>;
      case OrderStatus.REFUNDED:
        return <Badge tone="warning">{__(`order_status_${_status}`)}</Badge>;
      case OrderStatus.RETURNED:
        return <Badge tone="critical-strong">{__(`order_status_${_status}`)}</Badge>;
    }

  }

  // function getOrderFullfillmentBadge(order_fullfillment_status: number): any {
  //   let name = orderFullfillmentStatus[order_fullfillment_status] || "";
  //   switch (order_fullfillment_status) {
  //     case 10:
  //     case 11:
  //     case 12:
  //     case 13:
  //       return <Badge tone="info" progress="partiallyComplete">{name}</Badge>
  //     case 14:
  //       return <Badge tone="success" progress="complete">{name}</Badge>
  //     case 15:
  //       return <Badge tone="attention" progress="complete">{name}</Badge>
  //     case 16:
  //       return <Badge tone="critical">{name}</Badge>;
  //     case 19:
  //       return <Badge tone="critical">{name}</Badge>;
  //     default:
  //       return <Badge>Đang xử lý</Badge>;
  //   }
  // }

  /**
  * Order status
  */

  const [showPopupForChangeOrderStatus, setShowPopupForChangeOrderStatus] = useState('');

  const changeOrderStatus = useCallback((order_id: string, new_status: OrderStatus) => {
    setShowPopupForChangeOrderStatus('');
    dispatch(setStatusEntity({
      order_id: order_id,
      order_status: new_status
    }));

  }, []);

  // Hiển thị danh sách chọn trạng thái orders
  const renderItemOrderStatus = (content, order_id: string, status: OrderStatus, tone, active: boolean = false) => {
    return (
      <div style={{ marginTop: 5, cursor: 'pointer' }} onClick={() => changeOrderStatus(order_id, status)}>
        <Badge icon={active && CheckIcon} tone={tone}>{content}</Badge>
      </div>
    )
  }

  const orderDeliveryList = [{ label: "Chọn cách giao nhận", value: "" }];
  for (let x in orderDeliverType) {
    if (+x < 2) {
      let key = x, name = orderDeliverType[x];
      orderDeliveryList.push({
        label: __(`${name}`),
        value: `${name}`
      })
    }
  }

  // Hiển thị danh sách chọn trạng thái thanh toán orders
  const renderItemPaymentStatus = (content, order_id: string, status: PaymentStatus, tone, active: boolean = false) => {
    return (
      <div style={{ marginTop: 5, cursor: 'pointer' }} onClick={() => changePaymentStatus(order_id, status)}>
        <Badge icon={active && CheckIcon} tone={tone}>{content}</Badge>
      </div>
    )
  }

  function getPaymentStatusBadge(status: number) {
    switch (status) {
      case -1:
        return <Badge tone="critical-strong">Chưa thanh toán</Badge>
      case 0:
        return <Badge tone="critical-strong">Chưa thanh toán</Badge>
      case 1:
        return <Badge tone={'warning'} progress={'partiallyComplete'}>T.Toán một phần</Badge>
      case 2:
        return <Badge tone={'success'} progress={'complete'}>Đã thanh toán</Badge>
      // case 3:
      //   return <Badge tone="critical" progress="incomplete">Chờ hoàn tiền</Badge>
      // case 4:
      //   return <Badge tone="critical" progress="incomplete">Hoàn tiền</Badge>
      default:
        return <Badge></Badge>;
    }
  }

  /**
   * Order payment status
   */

  const [showPopupForChangePaymentStatus, setShowPopupForChangePaymentStatus] = useState('');

  const changePaymentStatus = useCallback((order_id: string, new_status: PaymentStatus) => {
    setShowPopupForChangePaymentStatus('');
    dispatch(updateEntity({
      order_id: order_id,
      payment_status: new_status
    }))
  }, []);


  const ActualPage = (
    <>
      <Page
        title={entity?.order_pnr}
        fullWidth
        subtitle={"Tạo bởi " + entity?.user?.display_name + ' / ' + String(entity ? subtractDate(entity.createdAt) : '1') + ' ngày trước'}
        backAction={{ content: 'Danh sách đơn hàng', url: '/orders' }}
        titleMetadata={
          <div className='box-row-center '>
            {/* Xử lý trạng thái thanh toán đơn hàng */}
            {(['admin', 'super_admin', 'accountant'].includes(account?.user_role)) && entity?.order_status !== OrderStatus.COMPLETED ?
              <Popover
                active={showPopupForChangePaymentStatus === entity?.order_id}
                activator={<>
                  <span style={{ cursor: 'pointer' }} onClick={() => setShowPopupForChangePaymentStatus(entity?.order_id)}>
                    <InlineStack>
                      {
                        getPaymentStatusBadge(entity?.payment_status)
                      }
                      <Icon
                        source={ChevronDownIcon}
                        tone="base"
                      />
                    </InlineStack>
                  </span>
                </>}
                autofocusTarget="first-node"
                onClose={() => setShowPopupForChangePaymentStatus('')}
              >
                <div style={{ padding: 10 }}>
                  {
                    (entity?.payment_status == PaymentStatus.UNPAID || entity?.payment_status == PaymentStatus.UNKNOW) ?
                      renderItemPaymentStatus(`Đã thanh toán`, entity?.order_id, PaymentStatus.PAID, 'success')
                      : renderItemPaymentStatus(`Chưa thanh toán`, entity?.order_id, PaymentStatus.UNPAID, 'critical-strong')
                  }
                </div>
              </Popover> :
              <span>{getPaymentStatusBadge(entity?.payment_status)}</span>}
            <span style={{ paddingLeft: 20, paddingRight: 20 }}>
              <Icon
                source={ArrowRightIcon}
                tone="base"
              />
            </span>
            {/* Xử lý trạng thái vận chuyển đơn hàng */}
            {(
              entity?.order_status === OrderStatus.CANCEL ||
              entity?.order_status === OrderStatus.CUSTOMER_CANCEL ||
              entity?.order_status === OrderStatus.RETURNED ||
              entity?.locked === LockedOrder.LOCKED
            )
              ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>{
                     entity?.locked === LockedOrder.LOCKED
                    ? <Badge tone="enabled">{__(`order_lock`)}</Badge> : getOrderStatusBadge(entity?.order_status)}</span>
                  <span className="small-icon">{
                      entity?.order_status === OrderStatus.CANCEL ||
                      entity?.order_status === OrderStatus.CUSTOMER_CANCEL ||
                      entity?.order_status === OrderStatus.RETURNED ||
                      entity?.locked === LockedOrder.LOCKED
                    ? <Tooltip content={"Bạn không có quyền thay đổi trạng thái , cần liên hệ với quản lý"}><Text as="span"><LockIcon /></Text></Tooltip> : ''}</span>
                </div>
              ) : (
                <Popover
                  active={showPopupForChangeOrderStatus === entity?.order_id}
                  activator={<>
                    <span style={{ cursor: 'pointer' }} onClick={() => setShowPopupForChangeOrderStatus(entity?.order_id)}>
                      <InlineStack>{
                        getOrderStatusBadge(entity?.order_status)
                      }
                        <Icon
                          source={ChevronDownIcon}
                          tone="base"
                        /></InlineStack>
                    </span>
                  </>}
                  autofocusTarget="first-node"
                  onClose={() => setShowPopupForChangeOrderStatus('')}
                >
                  <div style={{ padding: 10 }}>
                    {entity?.order_status !== OrderStatus.COMPLETED && renderItemOrderStatus(__(`order_status_draft`), entity?.order_id, OrderStatus.DRAFT, 'attention', entity?.order_status === OrderStatus.DRAFT)}
                    {entity?.order_status !== OrderStatus.COMPLETED && renderItemOrderStatus(__(`order_status_approved`), entity?.order_id, OrderStatus.APPROVED, 'success', entity?.order_status === OrderStatus.APPROVED)}
                    {(entity?.payment_status === PaymentStatus.PAID && entity?.order_status !== OrderStatus.COMPLETED) && renderItemOrderStatus(__(`entity?._transporting`), entity?.order_id, OrderStatus.TRANSPORTING, 'info', entity?.order_status === OrderStatus.TRANSPORTING)}
                    {(entity?.payment_status === PaymentStatus.PAID && entity?.order_status !== OrderStatus.COMPLETED) && renderItemOrderStatus(__(`order_status_completed`), entity?.order_id, OrderStatus.COMPLETED, 'success-strong')}
                    {(entity?.payment_status === PaymentStatus.PAID && entity?.order_status !== OrderStatus.COMPLETED) && renderItemOrderStatus(__(`order_status_cancel`), entity?.order_id, OrderStatus.CANCEL, 'critical')}
                    {(entity?.payment_status === PaymentStatus.PAID && entity?.order_status !== OrderStatus.COMPLETED) && renderItemOrderStatus(__(`order_status_customer_cancel`), entity?.order_id, OrderStatus.CUSTOMER_CANCEL, 'critical')}
                    {/* {(entity?.payment_status === PaymentStatus.PAID && order_status === OrderStatus.COMPLETED) && renderItemOrderStatus(__(`order_status_refunded`), order_id, OrderStatus.REFUNDED, 'warning')} */}
                    {(entity?.payment_status === PaymentStatus.PAID && entity?.order_status === OrderStatus.COMPLETED) && renderItemOrderStatus(__(`order_status_returned`), entity?.order_id, OrderStatus.RETURNED, 'critical-strong')}
                    {(entity?.payment_status === PaymentStatus.PAID && entity?.order_status === OrderStatus.COMPLETED) && renderItemOrderStatus(__(`order_lock`), entity?.order_id, OrderStatus.LOCK, 'enabled')}
                  </div>
                </Popover>
              )
            }
          </div>
        }
        // titleMetadata={ getBadgeOfPaymentMethod(entity?.payment_status) +''+ <Badge></Badge> }
        secondaryActions={[
          // {
          //   content: "Invoice",
          //   icon: ReceiptIcon,
          //   onAction: () => {
          //     window.open(process.env.REACT_APP_AJAX_URL + '/orders/invoice/print/' + entity?.order_id, '_blank');
          //   },
          // },
          // {
          //   content: "Packing slip",
          //   // icon: entity?.order_print_packing_slip ? CheckSmallIcon : MenuHorizontalIcon, // CheckSmallIcon
          //   icon: MenuHorizontalIcon,
          //   onAction: () => {
          //     markThisOrderPrintPackingSlip();
          //   },
          // }
        ]}
      >
        <Layout>
          <Layout.Section>
            {
              !helpers.isEmpty(entity) ?
                <OrderProducts
                  orderToProduct={entity.orders_to_product}
                  orderIsLock={entity.locked}
                  deleteOrderProductCallback={deleteOrderProductCallback}
                  ordersFee={orderFees}
                  orderStatus={entity.order_status}
                  savingQuantityThisIndex={savingQuantityThisIndex}
                  showProductSearchForm={showProductSearchForm}
                  setShowModelCustomFeeForm={setShowModelCustomFeeForm}
                  orderId={Param}
                  setShowTransactionModal={setShowTransactionModal}
                  orderTotalMustPay={entity?.order_total_mustpay}
                />
                : null
            }
            <br />
            {/* <OrderAssign /> */}
            <br />
            <Divider />
            <br />

            {/* <Comments objectId={Param} commentType="order_comment" title="Nhận xét"></Comments> */}


          </Layout.Section>

          <Layout.Section variant="oneThird">

            <LegacyCard>
              <LegacyCard.Section>
                <Text as="h4" variant="headingMd">Thanh toán </Text>
                <br />
                <Select
                  label="Phương thức thanh toán"
                  labelHidden
                  disabled={(true)}
                  options={orderDeliveryList}
                  // onChange={handleDeliveryChange}
                  value={orderDeliveryMethod}
                />
                {/* <br />
                <Text as="h4" variant="headingMd">Voucher</Text>
                <br />
                <Select
                  label="Voucher"
                  labelHidden
                  disabled={true}
                  options={[]}
                  // onChange={handleDeliveryChange}
                  value={""}
                /> */}
                <br />
                <Text as="h4" variant="headingMd">Trạng thái thanh toán</Text>
                <br />
                <Select
                  label="Voucher"
                  labelHidden
                  disabled={true}
                  options={paymentStatus}
                  // onChange={handleDeliveryChange}
                  value={entity?.payment_status.toString()}
                />
              </LegacyCard.Section>

            </LegacyCard>

            <LegacyCard>
              <OrderLogistics stock_id={entity?.stock_id} />
            </LegacyCard>

            <LegacyCard>
              <LegacyCard.Header
                title={<div className="small-icon"><Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg"><ProfileIcon /> Khách hàng </Text></div>}
                actions={[{
                  content: 'Sửa',
                  disabled: !!entity?.user?.user_id,
                  onAction: () => {
                    showCustomerSearchForm()
                  }
                }]}
              >
              </LegacyCard.Header>

              <LegacyCard.Section>
                {
                  entity?.user?.user_id ?
                    <>
                      <Text as="h4" variant="bodyMd" fontWeight="bold" tone="subdued">{entity?.user?.display_name}</Text>
                    </>
                    : "Chưa có khách hàng"
                }
              </LegacyCard.Section>


              <LegacyCard.Section>

                {
                  entity?.user?.user_id ?
                    <>
                      <Text as="p" fontWeight="bold">
                        Thông tin liên hệ
                      </Text>
                      <br />

                      <InlineGrid gap={"200"} columns={1}>
                        <Text as="p" tone="success">
                          Điện thoại: {' '}
                          {entity?.orders_to_address?.order_customer_phonenumber ? entity?.orders_to_address?.order_customer_phonenumber : '-'}
                        </Text>
                      </InlineGrid>
                      <br />
                      <BlockStack gap={"200"}>
                        <Text as="p" fontWeight="bold">
                          Địa chỉ giao hàng
                        </Text>
                        <div>
                          <Text as="p" tone="critical">{entity?.orders_to_address.order_customer_address ?? 'Chưa có thông tin'}</Text>
                          <Text as="p" tone="critical">{diachiXa ?? null}</Text>
                          <Text as="p" tone="critical">{diachiHuyen ?? null}</Text>
                          <Text as="p" tone="critical">{diachiTinh ?? null}</Text>
                        </div>
                      </BlockStack>
                    </>
                    : "Chưa có thông tin"
                }

              </LegacyCard.Section>

              {/* <LegacyCard.Section title="Địa chỉ giao hàng">
                      Như địa chỉ khách hàng
                    </LegacyCard.Section>

                    {
                      entity?.user ?
                      <LegacyCard.Section subdued>
                          <Link url={"/customer/view/" + entity?.user.user_id }>Xem thêm thông tin về khách hàng này hoặc chỉnh sửa</Link>
                      </LegacyCard.Section>
                      : null
                    } */}

            </LegacyCard>

            <LegacyCard>
              <LegacyCard.Header
                title={<div className="small-icon"><Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg"><NoteIcon /> Ghi chú </Text></div>}
                actions={[{
                  content: 'sửa',
                  disabled: (!!entity?.locked),
                  onAction: () => setShowQuickNote(true)
                }]}
              >
              </LegacyCard.Header>

              <LegacyCard.Section>
                {
                  showQuickNote ?
                    <>
                      <TextField
                        label=""
                        multiline={2}
                        value={valuetext}
                        onChange={handleQuickNoteChange}
                        autoComplete="off"
                        maxLength={250}
                        showCharacterCount
                      />

                      <Button variant="plain" onClick={saveQuickNoteButtonCallback} loading={updating}>
                        Save
                      </Button>
                      &nbsp; or &nbsp;
                      <Button variant="plain" onClick={() => {
                        setShowQuickNote(false)
                      }}>
                        Cancel
                      </Button>


                    </>
                    :
                    entity?.order_note ?? 'chưa có ghi chú'

                }
              </LegacyCard.Section>
            </LegacyCard>

            <br />
            {/* <Card>
              <QuickTags objectId={Param} taxonomyType="tag" />
            </Card> */}

          </Layout.Section>

        </Layout>
      </Page>

      <br />
      <br />

      <OrderCustomerSearchForm show={showModelCustomerSearchForm} onClose={onCloseshowModelCustomerSearchForm} />
      <OrderProductQuickSearchForm show={showModelProductSearchForm} onClose={onCloseshowModelProductSearchForm} stockId={entity?.stock_id} />
    </>

  )



  return (
    <>
      {
        loading ? <SkeletonLoading /> : (
          entity ? ActualPage : <Theme404 />
        )
      }
      {(updating) ? <Loading /> : null}
      {showModelCustomFeeForm ? <ShowCustomFee handleAssignOrderfee={handleAssignOrderfee} onClose={() => setShowModelCustomFeeForm(false)} /> : null}
      {
        showTransactionModal ? <TransactionNewModal order_pnr={entity?.order_pnr} default_customer={entity?.user.user_id} moneyQuantity={(entity?.order_total_price + entity?.order_total_fee).toFixed()} onClose={afterCloseTransactionModal} /> : null
      }


    </>
  )
}







