import {
  LegacyCard,
  DataTable,
  EmptyState,
  Layout,
  Page,
  Toast,
  Link,
  InlineStack,
  Avatar,
  Card,
  Button,
  Badge,
  Popover,
  Icon,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import emptyIMG from "media/images/empty.png";
import Pagination from "components/pagination";
import helpers from "helpers";
import __ from "languages/index";
import { TypeTopSeller } from "interface/analytics.model";
import { EditIcon, ChevronDownIcon } from '@shopify/polaris-icons'
import { USER_CAPACITY_LIST, USER_STATUS } from "constant/index";
import dateandtime from "date-and-time";
import {
  PlusIcon
} from '@shopify/polaris-icons';
import SimpleFilter from "components/simpleFilter";
import { getTopSeller, clearError } from "store/analytics.store.reducer";

export default function TopSellers() {
  const entities = useAppSelector((state) => state.users.entities);
  const topSeller = useAppSelector(state => state.analytics.topSeller);
  const loading = useAppSelector((state) => state.users.loading);
  const updateSuccess = useAppSelector((state) => state.users.updateSuccess);
  const totalItems = useAppSelector((state) => state.users.totalItems);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);

  useEffect(() => {
    dispatch(getTopSeller({}));
  }, []);


  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();

  /**
   *
   * @param user_id
   */
  const shortcutActions = (user_id: number | string) => {
    history("/customer/view/" + user_id);
  };

  const emptyData = (
    <EmptyState heading="Dữ liệu trống" image={emptyIMG}>
      <p>Không tìm thấy người dùng</p>
    </EmptyState>
  );

  const handleSort = useCallback(
    (index, direction) => {
      let _direction = direction === "descending" ? "desc" : "asc";
      let sort = "";
      switch (index) {
        case 0:
          sort = "display_name:";
          break;
        case 1:
          sort = "user_role:";
          break;
        case 2:
          sort = "user_email:";
          break;
        case 3:
          sort = "user_phone:";
          break;
        case 4:
          sort = "user_birthday:";
          break;
        case 5:
          sort = "description:";
          break;

        default:
          break;
      }

      if (sort.length) {
        entities.sort((a, b) => {
          if (_direction == 'desc') {
            return a[sort] - b[sort]
          }
          return b[sort] - a[sort]
        })
      }

    },
    [entities]
  );

  const renderItem = (users: TypeTopSeller, index) => {
    const {
      user: {
        display_name,
        user_avatar,
        user_email,
        user_id,
        user_phone
      },
      revenue
    } = users;

    const user_display_name = display_name || user_email || user_phone || 'noname';
    // const birthDay = dateandtime.format(new Date(user_birthday), 'DD/MM/YYYY')
    return [
      index + 1,
      <InlineStack gap={"200"} align="start" blockAlign="center">
        <Avatar source={user_avatar} name={user_display_name} initials={user_display_name.charAt(0)} size="sm" />
        <Link
          key={user_id}
          onClick={() => shortcutActions(user_id)}
        >
          {display_name}
        </Link>
      </InlineStack>,
      <span>{helpers.formatNumber(revenue)} vnđ </span> ?? <Badge></Badge>,
      user_email,
      user_phone ?? <Badge></Badge>,
    ];
  };

  const UsersList = (
    <>
      {
        topSeller?.length > 0 ? (
          <DataTable
            sortable={[
              false,
              false,
              false,
              false,
              false,
            ]}
            defaultSortDirection="descending"
            initialSortColumnIndex={0}
            onSort={handleSort}
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
            ]}
            headings={[
              "STT",
              __('user_full_name'),
              'Doanh thu',
              "Email",
              __('phone_number'),
            ]}
            rows={topSeller?.map(renderItem)}
            hideScrollIndicator
          />
        ) : (emptyData)
      }
    </>
  );

  const Actual_page = (
    <>
      <Layout>
        <Layout.Section>
          <Card padding={'0'}>
            {UsersList}
          </Card>
          <br />
          {/* {totalItems > mainQuery.limit ? (
              <Pagination
                TotalRecord={totalItems}
                activeCurrentPage={mainQuery.page}
                pageSize={mainQuery.limit}
                onChange={onChangePagination}
              />
            ) : null} */}
        </Layout.Section>
      </Layout>
    </>
  );


  const toastMarkupNotification = updateSuccess ? (
    <Toast content={"Cập nhật thông tin thành công!"} onDismiss={toggleActive} />
  ) : null;

  return (
    <>
      {toastMarkupNotification}
      {Actual_page}
    </>
  );
}
