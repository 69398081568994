import { DataTable, Page, Toast, InlineStack, Button, Layout, Card, EmptyState, Tooltip } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { getEntities, clearError, deleteEntity } from 'store/stock.store.reducer';
import { useAppDispatch, useAppSelector } from "config/store";
import Pagination from "components/pagination";
import helpers from "helpers/index";
import { TypeStoke } from "interface/stoke.model";
import __ from "languages/index";
import {
  PlusIcon,
  EditIcon,
  DeleteIcon,
  DatabaseAddIcon
} from '@shopify/polaris-icons';
import emptyIMG from "../../media/images/empty.png";
import { useNavigate } from "react-router-dom";
import SkeletonLoading from 'components/skeletonLoading';

export default function StockList() {

  const entities = useAppSelector((state) => state.stock.entities);
  const loading = useAppSelector((state) => state.stock.loading);
  const updating = useAppSelector((state) => state.stock.updating);
  const updateSuccess = useAppSelector((state) => state.stock.updateSuccess);
  const account = useAppSelector(state => state.user.account);
  const totalItems = useAppSelector((state) => state.stock.totalItems);

  const dispatch = useAppDispatch();
  const history = useNavigate();
  const rootSearchPath = window.location.search;
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;

  const initialQuery = {
    query: "",
    page: 1,
    limit: 40,
    sort: "createdAt:desc",
  };

  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery,
  });



  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({ ...mainQuery, page: numPage, limit: limit });
  }, [mainQuery]);

  useEffect(() => {
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
  }, []);

  const deleteStock = useCallback((stock_id) => {
      dispatch( deleteEntity(stock_id) );
  }, []);


  /** Clear Error */
  useEffect(() => {
    clearErrorCallback();
  }, []);

  const emptyData = (
    <EmptyState heading="Dữ liệu trống" image={emptyIMG}>
      <p>Không tìm thấy danh sách kho hàng</p>
    </EmptyState>
  );

  const toastSuccessMarkup = updateSuccess ? (
    <Toast content={'Thành công!'} onDismiss={clearErrorCallback} />
  ) : null;

  const items = (stock: TypeStoke, index) => {
    const {
      stock_id,
      stock_name,
      stock_address_district_name
    } = stock;

    return [
      index + 1,
      stock_name,
      stock_address_district_name,
      <InlineStack gap={"200"} align="start" blockAlign="center">
        <Tooltip content="Danh sách sản phẩm trong kho">
          <Button variant="tertiary" onClick={() => history(`/stock/detail/${stock_id}`, { state: { stockName: stock_name } })} icon={DatabaseAddIcon} />
        </Tooltip>
        {(['admin', 'super_admin', 'stock_manager'].includes(account?.user_role)) &&
        <Tooltip content="Sửa thông tin kho">
          <Button variant="tertiary" onClick={() => history(`/stock/edit/${stock_id}`)} icon={EditIcon} />
        </Tooltip>
        }
        {(['admin', 'super_admin', 'stock_manager'].includes(account?.user_role)) &&
        <Tooltip content="Xoá kho">
          <Button variant="tertiary" onClick={() => {window.confirm('Bạn có chắc chắn muốn xoá không?') && deleteStock(stock_id)}} icon={DeleteIcon} />
        </Tooltip>
        }
      </InlineStack>
    ]
  }

  const stokeList = (
    <>
      {
        entities.length > 0 ? (
          <DataTable
            hideScrollIndicator
            columnContentTypes={[
              'text',
              'text',
              'text',
              'text',
            ]}
            headings={[
              "STT",
              __('stoke_name'),
              __('stock_address'),
              (['admin', 'super_admin', 'stock_manager'].includes(account?.user_role)) && __('action'),

            ]}
            rows={entities?.map(items)}
            footerContent={`Showing ${entities?.length} of ${entities?.length} results`}
            hasZebraStripingOnData
            increasedTableDensity
          />
        ) : emptyData
      }
    </>
  )

  return (
    <>
      {toastSuccessMarkup}
      {(loading || updating) ? <SkeletonLoading /> : 
      <Page
        fullWidth
        title={__('stoke')}
        subtitle="Kho hàng"
        primaryAction={(['admin', 'super_admin', 'stock_manager'].includes(account?.user_role)) && <Button icon={PlusIcon} variant="primary" onClick={() => history('/stock/new')}>Thêm kho</Button>}
      >
        <Layout>
          <Layout.Section>
            <Card padding={'0'}>
              {stokeList}
            </Card>
            <br />
            {totalItems > mainQuery.limit ? (
              <Pagination
                TotalRecord={totalItems}
                activeCurrentPage={mainQuery.page}
                pageSize={mainQuery.limit}
                onChange={onChangePagination}
              />
            ) : null}
          </Layout.Section>
        </Layout>
      </Page>
}
    </>
  )
}