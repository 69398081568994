export enum OrderStatus {
    DRAFT = "draft", //Chờ xác nhận
    APPROVED = "approved", // Đã xác nhận
    TRANSPORTING = "transporting", // Đang vận chuyển
    COMPLETED = "completed", // Đã giao hàng
    CANCEL = "cancel", // Hệ thống huỷ
    RETURNED = "returned", // Hoàn hảng ( hoàng hàng sau khi đã giao hàng thành công)
    REFUNDED = "refunded",
    CUSTOMER_CANCEL = "customer_cancel", // Khách hàng tự huỷ đơn hàng
    LOCK = "order_lock"
}

export enum PaymentStatus {
    UNKNOW = -1,
    UNPAID = 0, //chưa thanh toán
    PARTIAL = 1, //thanh toán 1 phần,
    PAID = 2 // đã thanh toán
}

export enum LockedOrder {
    UNLOCKED = 0,
    LOCKED = 1
};


export enum ORDER_FULLFILLMENT_STATUS_ENUM {
    EMPTY = 0,
    PROCESSING = 10,
    PACKED = 11,
    SENT = 12,
    TRANSPORTING = 13,
    DONE = 14,
    RETURNED = 15,
    ERROR = 16,
    CANCEL = 19,
}