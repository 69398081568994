import { useParams } from "react-router-dom";
import UserInGroupManagement from "./groupUser";
import UserGroupList from './group_user_list'

export default  function UserInGroupIndex() {
    let useParam =  {} as any;
        useParam = useParams();
    let Param = useParam.slug;
    let ActualPage = UserInGroupManagement;

    if(Param === 'list'){
        ActualPage = UserGroupList
    }else{
        ActualPage = UserInGroupManagement;
    }

    return (
        <>
            {<ActualPage />}
        </>
    );
}
