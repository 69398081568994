  import { useCallback, useEffect } from "react";
  import { useAppDispatch } from "../config/store";
  import {
    useLocation,
  } from "react-router-dom";
  
  
  import helpers from "../helpers";
  import {
    clearError,
    reset,
    loginByFacebook,
    // createSession,
  } from "../store/user.store.reducer";
  
  import {clearError as clearGlobalError} from 'store/global.warning.store.reducer';
  
export default function LoginByFacebook() {

    const __passport = (window as any).__passport || '';
    const dispatch = useAppDispatch();

    // /login/sso
    let {hash} = useLocation();


      useEffect(() => {
        dispatch(clearGlobalError());
        dispatch(reset());
        dispatch(clearError());
      }, []);


      useEffect( () => {
        if ( hash ) {
          let _hash = helpers.ExtractUrl(hash);
          let access_token = _hash['#access_token'] || '';
          if ( ! access_token ) return;
          facebookCallbackSuccess(access_token);
        }
      }, [hash]);

  const redirectTo = useCallback( async (access_token_for_sso: string) => {
    dispatch(clearGlobalError());
    let app_id = localStorage.getItem('app_id');
    let redirect_to = localStorage.getItem('redirect') || process.env.REACT_APP_PUBLIC_URL;
    if ( app_id ) {
      window.location.href = (decodeURIComponent(redirect_to) + `#oauth_access_token=` + access_token_for_sso);
    } else {
      window.location.href = (decodeURIComponent(redirect_to));
    }
  }, []);


      const facebookCallbackSuccess = useCallback( async (access_token: string) => {
        await dispatch(loginByFacebook({
          access_token: access_token,
          device_type: "website",
          device_signature: 'a', /** For notification, but website doesn't need it ... */
          device_uuid: __passport,
        })).unwrap().then( e => {
          const token = e.headers['x-authorization'] ?? '';
          redirectTo(token);
        }).catch(e => {});
      }, [redirectTo]);



    

    return (
        <div style={{padding: 10, margin: 'auto'}}>
          {
            hash ? ( 'Loading...' ): 'Error'   
          }
        </div>
    )
}