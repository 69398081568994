import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';

import setupAxiosInterceptors from './config/axios';
import App from './App';
import reportWebVitals from './reportWebVitals';
import getStore from './config/store';
import {logout} from './store/user.store.reducer';
import {setError} from './store/global.warning.store.reducer';
import {setLoading, stopLoading} from 'store/global_process_bar.store.reducer';
import './media/css/APP.scss';
import { BrowserRouter } from 'react-router-dom';

const store = getStore();

/**
 * If API header return 401 or 403, call login now!
 */
const actions = bindActionCreators({ setLoading, stopLoading, logout, setError }, store.dispatch);
setupAxiosInterceptors(
    (status: number) => {
        // not log-in
        if ( status === 401 ) {
          // window.location.href = '/login#redirect_from_index';
        }
        // logout ? do something now, or leave it there...
    },
    (errorMessage: string ) => {
      actions.setError(errorMessage);
    },
    (loadingStatus: boolean) => {
      if ( loadingStatus === true )
        actions.setLoading();
      else
        actions.stopLoading();
    }
  );

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);
  // Create a root.
const baseHref = document.querySelector("base")?.getAttribute("href")?.replace(/\/$/, "");

root.render(
    <Provider store={store}>
      <BrowserRouter basename={baseHref}>
        <App />
      </BrowserRouter>
    </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
function sendToAnalytics({ id, name, value }) {
  // console.log(id, name, value, '<< REPORT >>');
  // ga('send', 'event', {
  //   eventCategory: 'Web Vitals',
  //   eventAction: name,
  //   eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
  //   eventLabel: id, // id unique to current page load
  //   nonInteraction: true, // avoids affecting bounce rate
  // });
}

reportWebVitals(sendToAnalytics);



/**
 * Webpack HMR Activation
 */

/**/


 type ModuleId = string | number;

 interface WebpackHotModule {
   hot?: {
     data: any;
     accept(
       dependencies: string[],
       callback?: (updatedDependencies: ModuleId[]) => void,
     ): void;
     accept(dependency: string, callback?: () => void): void;
     accept(errHandler?: (err: Error) => void): void;
     dispose(callback: (data: any) => void): void;
   };
 }
 
 declare const module: WebpackHotModule;
 
 if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      process.exit();
    });
 }