import { Button, Text } from "@shopify/polaris";
import __ from "languages/index";

const LayoutChildrenProduct = ({ children, title, btnText = '', actionBtn = () => {}, disable=false }) => {
    // infomation_general
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Text as="h2" fontWeight="bold" >{__(title)}</Text>
                {btnText && <Button variant="primary" disabled={disable} onClick={() => actionBtn()}>{btnText}</Button>}
            </div>
            <div style={{
                marginTop: '8px',
                background: '#F7F7F7',
                padding: '16px',
                borderRadius: '16px'
            }} >
                {children}
            </div>
        </div>
    )
};

export default LayoutChildrenProduct;