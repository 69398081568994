import { Form, LegacyCard, Modal, Text, ResourceItem, ResourceList, TextField, Thumbnail } from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "config/store";
import helpers from "helpers";
import {
    getEntities,
} from "store/product.store.reducer";
import { NoteIcon } from '@shopify/polaris-icons';

/**
 * modal show to find product ...
 * @param param0 
 * @returns product_id or nothing...
 */

export default function OrderProductQuickSearchForm({ show, onClose, stockId }: { show: boolean, onClose: Function, stockId: string }) {

    const dispatch = useAppDispatch();
    let entities = useAppSelector((state) => state.product.entities);
    const updating = useAppSelector((state) => state.product.updating);
    const loading = useAppSelector((state) => state.product.loading);

    useEffect(() => {
        if (show)
            onChangeText('');
    }, [show]);

    const closeBtn = useCallback(() => {
        onClose();
    }, []);

    const [value, setValue] = useState('');

    const onChangeCallback = useMemo(() => helpers.debounce(_value => onChangeText?.call(this, _value), 300), []);

    function onChangeText(_t: string) {
        dispatch(getEntities({
            limit: 20,
            product_status: 1,
            ['product_to_stock.stock_id']: stockId,
            query: _t
        }))
    }


    const handleTextChange = useCallback(
        (newValue: string) => {
            setValue(newValue);
            onChangeCallback(newValue)
        },
        [],
    );

    /**
     * selectableCallback
     * @param product_id 
     */
    function selectableCallback(product_id: any) {
        onClose(product_id); // truyen ID sang ben khac
    }




    return (
        <>
            <Modal
                activator={null}
                open={show}
                onClose={closeBtn}
                title="Tìm sản phẩm"
            >
                <Modal.Section>
                    <Form onSubmit={null}>
                        <TextField
                            label="Tên sản phẩm hoặc mã SKU"
                            value={value}
                            onChange={handleTextChange}
                            autoComplete="off"
                        />
                    </Form>
                    <br />
                    <LegacyCard >
                        <ResourceList
                            resourceName={{ singular: 'product', plural: 'products' }}
                            items={entities ?? []}
                            loading={loading}
                            renderItem={(item) => {
                                const { product_id, product_name, product_price, product_sku, product_media  } = item;
                                const media = <Thumbnail source={product_media?.[0] ||NoteIcon} alt="" />;

                                return (
                                    <ResourceItem
                                        id={product_id}
                                        url={null}
                                        onClick={() => {
                                            selectableCallback(product_id);
                                        }}
                                        media={media}
                                        accessibilityLabel={`View details for ${product_name}`}
                                    >
                                        <Text variant="bodyMd" fontWeight="bold" as="h3">
                                            {product_name}
                                        </Text>
                                        <div>
                                            <Text as="p" tone="critical">{product_price ?? ''}</Text>
                                            <Text as="p">{product_sku ?? ''}</Text>
                                        </div>
                                    </ResourceItem>
                                );
                            }}
                        />
                    </LegacyCard>

                </Modal.Section>
            </Modal>
        </>
    );
}