import { useParams } from "react-router-dom";

import logisticList from './logistic.list';
import logisticView from './logistic.view';


export default  function LogisticIndex() {
    let useParam =  {} as any;
        useParam = useParams();
    let Param = useParam.logistic_id || 'list';

    let ActualPage: any;
    switch (Param) {
        
        case 'list':
            ActualPage = logisticList;
        break;

        default:
            ActualPage =  logisticView;
        break;
    }

    return (
        <>
            {<ActualPage />}
        </>
    );
}
