import { Modal, TextContainer } from '@shopify/polaris';
import { useState, useEffect, memo, useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

function ModalCropImage({ imageUrl, isOpen, onClose, setImgCrop }) {
  const cropperRef = useRef(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const handleCrop = () => {
    const cropper = cropperRef.current.cropper;
    const croppedCanvas = cropper.getCroppedCanvas();
    if (croppedCanvas) {
      setCroppedImage(croppedCanvas.toDataURL());
    }
  };

  const handleConfirmCrop = () => {
    if (croppedImage) {
      setImgCrop(croppedImage)
      handleClose();
    }
  };

  useEffect(() => {
    croppedImage && handleConfirmCrop()
  }, [croppedImage])


  const handleClose = () => {
    setCroppedImage(null);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => onClose()}
      title={''}
      primaryAction={{
        content: 'Cắt ảnh',
        onAction: () => handleCrop(),
      }}
      secondaryActions={[
        {
          content: 'Thoát',
          onAction: () => onClose(),
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <div className={`modal ${isOpen ? 'is-active' : ''}`}>
            <div className="modal-background" onClick={handleClose}></div>
            <div className="modal-content">
              <div className="box">
                <Cropper
                  ref={cropperRef}
                  src={imageUrl}
                  style={{ height: 400, width: '100%' }}
                  aspectRatio={1}
                  guides={true}
                  cropBoxResizable={true}
                />
              </div>
            </div>
          </div>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
}

export default memo(ModalCropImage)