import { Card, Badge, Text, IndexTable, Link, Page, useIndexResourceState, Toast, Thumbnail, Button, Popover, ActionList } from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  DeleteIcon,
  NoteIcon,
  PlusIcon,
  ViewIcon
} from '@shopify/polaris-icons';
import {
  addProductToCommission,
  clearError,
  getEntities,
  updateEntities
} from "store/product.store.reducer";
import { getEntities as getEntitiesCommission } from 'store/commission.store.reducer';
import { useAppDispatch, useAppSelector } from "config/store";
import helpers from "../../helpers";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "components/pagination";
import SkeletonLoading from 'components/skeletonLoading';
import __ from 'languages/index';
import SimpleFilter from 'components/simpleFilter';
import { useGetThumbnail } from "utils";

export default function ProductList() {
  const { ref, getThumbnailUrl } = useGetThumbnail();
  const [showActionList, setShowActionList] = useState(null);
  const entities = useAppSelector((state) => state.product.entities);
  const entitiesCommission = useAppSelector((state) => state.commission.entities);
  const loading = useAppSelector((state) => state.product.loading);
  const totalItems = useAppSelector((state) => state.product.totalItems);
  const updating = useAppSelector((state) => state.product.updating);
  const account = useAppSelector(state => state.user.account);

  const updateSuccess = useAppSelector((state) => state.product.updateSuccess);
  const dispatch = useAppDispatch();
  const history = useNavigate();

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);

  
  const toastSuccessMarkup = updateSuccess ? (
    <Toast content={'Cập nhật thành công'} onDismiss={toggleActive} />
  ) : null;

  const onCloseActionList = useCallback((order_id: any) => {
    setShowActionList(!order_id);
  }, []);

  const togglePophoverActionListCallback = useCallback((_s: any) => {
    setShowActionList(showActionList === _s ? false : _s);
  }, [showActionList]);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  const initialQuery = {
    query: "",
    page: 1,
    limit: 25,
    sort: "createdAt:desc",
  };

  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...initialQuery,

  });

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({ ...mainQuery, page: numPage, limit: limit });
  }, [mainQuery]);



  let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      setMainQuery(m);
    }
  }, []);

  useEffect(() => {
    dispatch(getEntitiesCommission({}));
  }, []);

  useEffect(() => {
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  function getProductDetail(product_id: string) {
    history("/product/edit/" + product_id);
    return;
  }

  const resourceName = {
    singular: "sản phẩm",
    plural: "sản phẩm",
  };
  const resourceIDResolver = (products) => {
    return products.product_id;
  };
  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(entities ?? [], {
    resourceIDResolver,
  });


  const bulkActions = useCallback((_mode: string) => {
    dispatch(updateEntities({
      product_id: selectedResources,
      product_status: _mode
    })).unwrap().then((res) => {
      dispatch(getEntities(mainQuery))
    }).catch((err) => console.log('err E342 >>>', err))
  }, [selectedResources]);

  const promotedBulkActions = [
    {
      content: "Ngừng bán",
      onAction: () => bulkActions('0'),
      icon: DeleteIcon
    },
    {
      content: "Đang bán",
      onAction: () => bulkActions('1'),
    },
  ];

  const handleActionCommission = (commission_id, product_id) => {
    dispatch(addProductToCommission({
      commission_id: commission_id,
      product_id: product_id,
      mainQuery: mainQuery
    })).then(() => {
      onCloseActionList('')
    })
  }

  const renderOptionCommission = (product_id) => {
    const data = entitiesCommission?.map((val) => {
      return {
          title: '',
          items: [
            {
              content: val.commission_name,
              onAction: () => handleActionCommission(val.commission_id, product_id)
            },
          ]
      }
    })

    return data
  }

  function badgeStatus(_status) {
    switch (_status) {
      case 0:
        return <Badge>Ngừng bán</Badge>
      case 1:
        return <Badge tone="success">Đang bán</Badge>
      default:
        return <Badge tone="info">Chờ nhập kho</Badge>
    }
  }

  const rowMarkup = entities?.map(
    (
      {
        product_id,
        product_media,
        product_name,
        product_status,
        product_to_category,
        product_type,
        product_original_price,
        // product_original_price,
        product_variant,
        product_meta,
        // product_to_commission
      },
      index
    ) => (
      <IndexTable.Row
        id={product_id}
        key={product_id}
        selected={selectedResources.includes(product_id)}
        position={index}
      >
        <IndexTable.Cell><Thumbnail size="small" source={Array.isArray(product_media) && product_media.length ? getThumbnailUrl(product_media[0], 40, 40) : NoteIcon} alt="" /></IndexTable.Cell>
        <IndexTable.Cell>
          <Link removeUnderline onClick={() => getProductDetail(product_id)}>{product_name}</Link>
          <p>{product_variant.length > 0 && '(Sản phẩm gốc)'}</p>
        </IndexTable.Cell>
        <IndexTable.Cell>{badgeStatus(product_status)}</IndexTable.Cell>
        <IndexTable.Cell><Text as="span" variant="bodyMd">{helpers.formatNumber(product_original_price, ".")} vnđ</Text></IndexTable.Cell>
        <IndexTable.Cell>{product_meta?.product_sold_quantity || 0}</IndexTable.Cell>
        <IndexTable.Cell>{product_meta?.product_in_stock_quantity || 0}</IndexTable.Cell>
        {/* <IndexTable.Cell>
          <Popover
            active={showActionList === product_id}
            activator={<Button disclosure={'down'} onClick={() => togglePophoverActionListCallback(product_id)}>{product_to_commission?.commission_id ? product_to_commission?.commission?.commission_name : `Chọn loại phân phối hoa hồng`}</Button>}
            autofocusTarget="first-node"
            onClose={() => onCloseActionList(product_id)}
          >
            <ActionList
              actionRole="menuitem"
              sections={renderOptionCommission(product_id)}

            />
          </Popover>
        </IndexTable.Cell> */}
      </IndexTable.Row>
    )
  );

  return (
    <>
      {entities === null ? <SkeletonLoading fullWidth /> :
        (
          <Page
            fullWidth
            title={"Danh sách sản phẩm"}
            primaryAction={(['admin', 'super_admin'].includes(account?.user_role)) && <Button icon={PlusIcon} variant="primary" onClick={() => history('/product/new')}>Thêm sản phẩm</Button>}
          >
            <Card padding="0">
              <SimpleFilter loading={loading}
                onCallback={filterCallback}
                options={[
                  { label: 'Ngừng bán', value: '0', field: 'product_status' },
                  { label: 'Đang bán', value: '1', field: 'product_status' },
                ]}
                sortField={[
                  { label: 'Giá sản phẩm', field: 'product_original_price' },
                  { label: 'Tồn kho', field: 'product_quantity' },
                  { label: 'Đã bán', field: 'product_sold_quantity' },
                ]}
                totalItems={totalItems}
              />

              <IndexTable
                resourceName={resourceName}
                itemCount={entities?.length || 0}
                selectedItemsCount={
                  allResourcesSelected ? "All" : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
                bulkActions={[]}
                onSort={null}
                loading={updating}
                promotedBulkActions={promotedBulkActions}
                sortable={[]}
                headings={[
                  { title: "" },
                  { title: "Sản phẩm" },
                  { title: "Trạng thái" },
                  // { title: "Danh mục" },
                  // { title: "Phân loại" },
                  { title: "Giá bán" },
                  // { title: "Giá triết khấu" },
                  { title: "Đã bán" },
                  { title: "Tồn kho" },
                  // { title: "Hoa hồng" },
                ]}
              >
                {entities && rowMarkup}
              </IndexTable>
            </Card>

            <br />
            {totalItems > 0 ? (
              <Pagination
                TotalRecord={totalItems}
                onChange={onChangePagination}
                pageSize={Number(mainQuery.limit)}
                activeCurrentPage={Number(mainQuery.page)}
              />
            ) : null}
          </Page>
        )}

      {toastSuccessMarkup}
    </>
  )
}