import React, { useCallback, useEffect, useState } from "react";
import { LegacyCard, Loading, Page, Toast } from "@shopify/polaris";
import { useAppDispatch, useAppSelector } from "config/store";
import { useParams } from "react-router-dom";
import {
  clearError,
  getEntity,
} from "store/logistic_service.store.reducer";
import LogisticModal from './logistic.modal';

export default function LogisticsView() {

  const loading = useAppSelector((state) => state.logistic_service.loading);
  const updating = useAppSelector((state) => state.logistic_service.updating);
  const updateSuccess = useAppSelector((state) => state.logistic_service.updateSuccess);
  const dispatch = useAppDispatch();
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.logistic_id || false;

  useEffect(() => {
    dispatch(getEntity(Param))
  }, [Param]);

  const [showModalCreateNew, setShowModalCreateNew] = useState(false);

  const closeModalCallback = useCallback(() => {
    setShowModalCreateNew(false);
  }, []);

  const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
  }, []);

  useEffect(() => {
    clearErrorCallback();
  }, []);

  const successNotice = updateSuccess ? (
    <Toast content={'Cập nhật thành công!'} onDismiss={clearErrorCallback} />
  ) : null;

  return (
    <>
      {successNotice}
      {loading || updating ? <Loading /> : null}
      <Page
        title="Đơn vị vận chuyển"
        primaryAction={{
          content: 'Chỉnh sửa',
          onAction: () => {
            setShowModalCreateNew(true);
          },
          loading: updating
        }}
        backAction={{
          content: 'Back',
          url: '/logistic'
        }}
      >
        <LegacyCard>
          ABC DEF
        </LegacyCard>
      </Page>
      <LogisticModal show={showModalCreateNew} onClose={closeModalCallback} />
    </>
  )
}