import { Badge, Card, DataTable, EmptyState, Icon, Layout, Page, Popover, Toast } from "@shopify/polaris";
import { useAppDispatch, useAppSelector } from "config/store";
import { MEMBER_GROUP_STATUS, REQUEST_WITHDRAW_STATUS } from "constant";
import dateandtime from "date-and-time";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { assignUserToGroup } from "store/group_user.store.reducer";
import helpers from "../../helpers";
import emptyIMG from "../../media/images/empty.png";
import { NoteIcon, ChevronDownIcon } from '@shopify/polaris-icons'
import Pagination from "components/pagination";
import { approveRequestWithdraw, clearError, getRequestWithdrawList } from "store/request_withdraw.store.reducer";

export default function RequestWithdrawList() {
  const entities = useAppSelector((state) => state.request_withdraw.entities);
  const totalItems = useAppSelector((state) => state.request_withdraw.totalItem);
  const updateSuccess = useAppSelector((state) => state.request_withdraw.updateSuccess);
  const dispatch = useAppDispatch();
  const [showPopupForChangeWithdrawStatus, setShowPopupForChangeWithdrawStatus] = useState('');

  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  const initialQuery = {
    query: "",
    page: 1,
    limit: 20,
    sort: "createdAt: desc",
    user_role: ''
  }
  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...initialQuery
  });

  const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
  }, []);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({ ...mainQuery, page: numPage, limit: limit });
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      setMainQuery(m);
    }
  }, []);



  /**
   * New model
   */

  useEffect(() => {
    dispatch(getRequestWithdrawList(mainQuery));
  }, [mainQuery]);

  const emptyData = (
    <EmptyState heading="Chưa có thành viên trong nhóm này!" image={emptyIMG}>
    </EmptyState>
  );

  const handleApproveRequestWithdraw = (_id: string) => {
    dispatch(approveRequestWithdraw({
      request_id: _id,
      request_status: REQUEST_WITHDRAW_STATUS.PAID
    })).unwrap().then((res) => {
      dispatch(getRequestWithdrawList(mainQuery));
    })
  }

  const convertRequestWithdrawStatusStatus = (value) => {
    switch (value) {
      case REQUEST_WITHDRAW_STATUS
        .UNKNOW:
        return <Badge tone="critical-strong">Chờ duyệt</Badge>
        break;
      case REQUEST_WITHDRAW_STATUS
        .UNPAID:
        return <Badge tone="critical-strong">Chờ duyệt</Badge>
        break;
      case REQUEST_WITHDRAW_STATUS
        .PAID:
        return <Badge tone="success">Đã duyệt</Badge>
        break;
      default:
        break;
    }
  }

  const renderItem = (item, index) => {
    const {
      user_id,
      createdAt,
      request_status,
      request_id,
      amount,
      user: {
        user_email,
        user_avatar,
        user_phone,
        user_payment_profile
      },
    } = item;

    const sign_date = dateandtime.format(new Date(+createdAt), 'DD/MM/YYYY')

    return [
      index + 1,
      user_payment_profile[0]?.payment_information?.bank_account,
      user_payment_profile[0]?.payment_information?.bank_name,
      user_payment_profile[0]?.payment_information?.bank_branch,
      user_payment_profile[0]?.payment_information?.bank_number,
      <span>{helpers.formatNumber(amount, ".")} vnđ</span>,
      user_phone,
      user_email,
      sign_date,
      +request_status != REQUEST_WITHDRAW_STATUS.PAID ?
      <Popover
        active={showPopupForChangeWithdrawStatus === request_id}
        activator={<>
          <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => setShowPopupForChangeWithdrawStatus(request_id)}>
            {
              convertRequestWithdrawStatusStatus(request_status)
            }
            <Icon
              source={ChevronDownIcon}
              tone="base"
            />
          </div>
        </>}
        autofocusTarget="first-node"
        onClose={() => setShowPopupForChangeWithdrawStatus('')}
      >
        <div style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
          {(+request_status === REQUEST_WITHDRAW_STATUS.UNKNOW || +request_status === REQUEST_WITHDRAW_STATUS.UNPAID) &&
            <div style={{ cursor: 'pointer' }} onClick={() => handleApproveRequestWithdraw(request_id)}><Badge tone="success-strong">Phê duyệt</Badge></div>
          }
        </div>
      </Popover> :
      convertRequestWithdrawStatusStatus(request_status)
    ];
  };

  const RequestWithdrawList = (
    <>
      {
        entities.length > 0 ? (
          <DataTable
            sortable={[
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,

            ]}
            defaultSortDirection="descending"
            initialSortColumnIndex={0}
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
            ]}
            headings={[
              "STT",
              "Tên chủ tài khoản",
              "Tên ngân hàng",
              "Địa chỉ chi nhánh ngân hàng",
              "Số tài khoản",
              'Số tiền rút',
              "Số điện thoại",
              "Email",
              'Thời gian yêu cầu',
              'Trạng thái',
            ]}
            rows={entities?.map(renderItem)}
            hideScrollIndicator
            footerContent={`Display page ${mainQuery.page} of total ${totalItems} results...`}
          />
        ) : (emptyData)
      }
    </>
  );

  const successNotice = updateSuccess ? (
    <Toast content={'Phê duyệt thành công!'} onDismiss={clearErrorCallback} />
  ) : null;

  const Actual_page = (
    <>
      <Page
        fullWidth
        title={`Danh sách duyệt đề nghị thanh toán`}
      >
        <Layout>
          <Layout.Section>
            <Card padding={'0'}>
              {RequestWithdrawList}
            </Card>
            <br />
            {totalItems > mainQuery.limit ? (
              <Pagination
                TotalRecord={totalItems}
                activeCurrentPage={mainQuery.page}
                pageSize={mainQuery.limit}
                onChange={onChangePagination}
              />
            ) : null}
          </Layout.Section>
        </Layout>
      </Page>

    </>
  );

  return (
    <>
     {successNotice}
      {Actual_page}
    </>
  )
}