import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { generateDaysArray } from 'utils';

const AnalyticsChart = ({dataChart}) => {
  const chartRef = useRef(null);
  useEffect(() => {
    const chartDom = chartRef.current;
    const myChart = echarts.init(chartDom);

    const option = {
      title: {
        text: ''
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: dataChart?.data_name ?? []
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: dataChart?.time_log ?? []
      },
      yAxis: {
        type: 'value',
        label: 'VNĐ'
      },
      series: [
        {
          name: dataChart?.data_name[0],
          type: 'line',
          stack: 'Total',
          data: dataChart?.data_value.value_old
        },
        {
          name: dataChart?.data_name[1],
          type: 'line',
          stack: 'Total',
          data: dataChart?.data_value.value_new
        },
      ]
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose(); // Dispose the chart when the component unmounts
    };
  }, [dataChart]); // Run once after initial render

  return <div ref={chartRef} style={{ width: '100%', height: '600px' }}></div>;
};

export default AnalyticsChart;
