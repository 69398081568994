import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'config/reducer.utils';
import helpers from 'helpers/index';
import { ENDPOINT_URL } from 'constant';


export interface TypedStock {
  stock_id?: string; 
  stock_name?: string; 
  stock_address?: string; 
  stock_code?: string; 
  stock_count?: number; 
  manager?: string; 
  hotline?: string; 
  createdAt?: string; 
  stock_address_city?: string,
  stock_address_district?: string,
  stock_address_ward?: string
}

type TypedEntity = IQueryParams & TypedStock;

/**
*   Reducer used for front-end, with settings.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = `${ENDPOINT_URL}/stock`;

// Actions

export const getEntities = createAsyncThunk('stock/fetch_entity_list', async (object: TypedEntity) => {
      const EndURL = helpers.buildEndUrl(object);
      const requestUrl = `${apiUrl}${EndURL}`;
      return await axios.get<any>(requestUrl);
    },
    { serializeError: serializeAxiosError }
);


export const getEntity = createAsyncThunk(
  'comment/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return await axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
    'stock/create_entity',
    async (entity: TypedStock, thunkAPI) => {
      const result = await axios.post<TypedStock>(`${apiUrl}`, helpers.cleanEntity(entity));
      thunkAPI.dispatch(getEntities({}));
      return result;
    },
    { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
    'stock/update_entity',
    async (entity: TypedStock, thunkAPI) => {
      let stock_id = String(entity.stock_id);
      delete entity.stock_id;
      const result = await axios.patch<TypedStock>(`${apiUrl}/${stock_id}`, helpers.cleanEntity(entity));
      thunkAPI.dispatch(getEntities({}));
      return result;
    },
    { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
    'stock/delete_entity',
    async (file_name: string | number, thunkAPI) => {
      const requestUrl = `${apiUrl}/${file_name}`;
      const result = await axios.delete<any>(requestUrl);
      thunkAPI.dispatch(getEntities({}));
      return result;
    },
    { serializeError: serializeAxiosError }
  );

// slice

export const Reducer_System = createEntitySlice({
  name: 'stock',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = false;
    },
    reset: (state) => {
      return {...state, ...initialState};
    }
  },
  extraReducers(builder) {
    builder
    
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true;
        state.entities = [];
      })
    
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.pending, (state, action) => {
        state.loading = true;
        state.entity = [];
      })
    

      
      .addCase(createEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(createEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(createEntity.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })

      .addCase(updateEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(updateEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addCase(updateEntity.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })


    
      .addCase(deleteEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(deleteEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(deleteEntity.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })
  },
});

export const { clearError, reset } = Reducer_System.actions;

// Reducer
export default Reducer_System.reducer;
