import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { IUser, ILogin, IRegister } from '../interface/user.model';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';

import helpers from 'helpers/index';
import { ENDPOINT_URL } from 'constant';

export const initialState = {
  loading: false,
  isAuthenticated: false,
  isAuthenticating: true,
  account: null as IUser,
  entity: {} as IUser,
  entities: null as IUser[],
  userBirthdayListLoading: false,
  userBirthdayList: null as IUser[],
  userAchievementList: null as any[],
  userAchievementListCount: 0,
  errorMessage: null as unknown as string, // Errors returned from server side
  updateSuccess: false,
  loginRegisterErrorMessage: null as unknown as string, // Errors returned from server side
};

const apiUrl = `${ENDPOINT_URL}/user/me`;

/**
 * JamDev: CheckLogin and getCurrentUser
 */
export const getCurrentUser = createAsyncThunk('user/getcurrentuser', async () => {
  const requestUrl = `${apiUrl}`;
  return await axios.get<any>(requestUrl);
},
  { serializeError: serializeAxiosError }
);

/**
 * JamDev: Logout
 */
export const logout = createAsyncThunk('logout', async () => {
  !!helpers.cookie_get('session') && helpers.cookie_delete('session')
  // const requestUrl = `${ENDPOINT_URL}/logout`;
  // return axios.get<any>(requestUrl);
});


/**
 * JamDev: clearAuthentication
 export const clearAuthentication = (): AppThunk => (dispatch, getState) => {
  dispatch(logout());
}; 

/**
 * JamDev: Login
 */



export const login = createAsyncThunk('login', async (entity: ILogin) => {
  return await axios.post<ILogin>(`user/login/password`, helpers.cleanEntity(entity));
  // thunkAPI.dispatch(getCurrentUser());
  // return result;
},
  { serializeError: serializeAxiosError }
);


export const activeAccount = createAsyncThunk('activeAccount', async (entity: any) => {
  return await axios.post<ILogin>(`user/active_account`, helpers.cleanEntity(entity));
},
  { serializeError: serializeAxiosError }
);



export const quick_login = createAsyncThunk('quick_login', async (entity: ILogin, thunkAPI) => {
  return await axios.post<ILogin>(`login/onetimepassword`, helpers.cleanEntity(entity));
},
  { serializeError: serializeAxiosError }
);

export const loginByFacebook = createAsyncThunk('login-facebook', async (entity: ILogin, thunkAPI) => {
  return await axios.post<ILogin>(`login/facebook`, helpers.cleanEntity(entity));
},
  { serializeError: serializeAxiosError }
);


export const getEntity = createAsyncThunk('user/fetch-entity', async (userID: string) => {
  const result = await axios.get<any>(`${apiUrl}`);
  return result;
},
  { serializeError: serializeAxiosError }
);

/**
 * search user ...
 */
export const searchUser = createAsyncThunk('user/search-entity', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  return await axios.get<any>(`user/search${EndURL}`);
}
);
/**
 *  user birthday ...
 */
export const getUserBirthday = createAsyncThunk('user/get_birthday', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  return await axios.get<any>(`user/birthday${EndURL}`);
}
);


/**
 * Create session before login.
 */
//  export const createSession = createAsyncThunk('user/create_session', async () => {
//     return await axios.post<any>(`${apiUrl}/create_session`);
//   },
//   { serializeError: serializeAxiosError }
// );

/**
 * JamDev: Update profile
 */

export const updateProfile = createAsyncThunk(
  'user/update_profile',
  async (entity: IUser) => {
    let userID = String(entity.user_id);
    delete entity.user_id;
    return await axios.patch<IUser>(`${ENDPOINT_URL}/user/admin/${userID}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);


/**
 * JamDev: register
 */


export const register = createAsyncThunk('register', async (entity: IRegister) => {
  return await axios.post<IRegister>(`register`, helpers.cleanEntity(entity));
},
  { serializeError: serializeAxiosError }
);

/**
 * JamDev: RecoverPassword
 */
export const recoverPassword = createAsyncThunk('recoverPassword', async (entity: any) => {
  return await axios.post<any>(`recover_password`, helpers.cleanEntity(entity));
},
  { serializeError: serializeAxiosError }
);


export const setNewPassword = createAsyncThunk('setNewPassword', async (entity: { password: string, user_email?: string, code?: string }) => {
  return await axios.patch<any>(`user/set_new_password`, helpers.cleanEntity(entity));
},
  { serializeError: serializeAxiosError }
);



export const getOnetimepassword = createAsyncThunk(
  'users/get_onetimepassword',
  async (user_id: string | number) => {
    const requestUrl = `user/onetimepassword/${user_id}`;
    return await axios.post<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);



export const USER = createEntitySlice({
  name: 'user',
  initialState: initialState as any,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.loginRegisterErrorMessage = null;
      state.updateSuccess = false;
    },
    reset: (state) => {
      return { ...state, ...initialState };
    }
  },

  extraReducers(builder) {
    builder

      .addCase(searchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(searchUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(searchUser.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })


      .addCase(getUserBirthday.fulfilled, (state, action) => {
        state.userBirthdayListLoading = false;
        state.userBirthdayList = action.payload.data;
      })
      .addCase(getUserBirthday.pending, (state, action) => {
        state.userBirthdayListLoading = true;
      })
      .addCase(getUserBirthday.rejected, (state, action) => {
        state.userBirthdayListLoading = false;
        state.errorMessage = action.payload;
      })


      .addCase(setNewPassword.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(setNewPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.account = null;
      })


      .addCase(loginByFacebook.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.isAuthenticating = false;
        state.loginRegisterErrorMessage = null;
        state.account = action.payload.data;
      })
      .addCase(loginByFacebook.pending, (state, action) => {
        state.isAuthenticated = false;
        state.isAuthenticating = true;
        state.loginRegisterErrorMessage = null;
      })
      .addCase(loginByFacebook.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.isAuthenticating = true;
        state.loginRegisterErrorMessage = action.payload;
      })



      .addCase(quick_login.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          isAuthenticated: true,
          isAuthenticating: false,
          account: action.payload.data,
          // errorMessage: null
          loginRegisterErrorMessage: null
        };
      })



      .addMatcher(isFulfilled(getCurrentUser, login), (state, action) => {
        /**
         * Login success
         */
        return {
          ...state,
          loading: false,
          isAuthenticated: true,
          isAuthenticating: false,
          account: action.payload.data,
          // errorMessage: null
          loginRegisterErrorMessage: null
        };
      })

      .addMatcher(isFulfilled(register), (state, action) => {
        /**
         * Register OKAY!
         */
        return {
          ...state,
          loading: false,
          isAuthenticated: false,
          account: action.payload.data,
          errorMessage: null,
          loginRegisterErrorMessage: null
        };
      })

      // .addMatcher( isFulfilled(createSession), (state, action) => {
      //   /**
      //    * Register OKAY!
      //    */
      //   return {
      //     ...state,
      //     loading: false,
      //     isAuthenticated: false,
      //     session: action.payload.data,
      //     errorMessage: null
      //   };
      // })

      .addMatcher(isFulfilled(updateProfile), (state, action) => {
        /**
         * Update profile okay
         */
        return {
          ...state,
          loading: false,
          errorMessage: null,
          updateSuccess: true,
          entity: action.payload.data,
        };
      })
      .addMatcher(isRejected(login, register), (state, action) => {
        /**
 * Login fail, register fail
 */
        // console.info( action.error.response.data.message );
        return {
          ...state,
          loading: false,
          isAuthenticated: false,
          isAuthenticating: false,
          account: {},
          loginRegisterErrorMessage: action.error.message
        }
      })
      .addMatcher(isRejected(getCurrentUser, recoverPassword, updateProfile), (state, action) => {
        /**
         * Login fail, register fail
         */
        // console.info( action.error.response.data.message );
        return {
          ...state,
          loading: false,
          updateSuccess: false,
          isAuthenticated: false,
          isAuthenticating: false,
          account: {},
          errorMessage: action.error.message,
        }
      })
      .addMatcher(isFulfilled(logout), (state, action) => {
        /**
         * Log out ...
         */
        !!helpers.cookie_get('session') && helpers.cookie_delete('session')
        return {
          ...state,
          loading: false,
          isAuthenticated: false,
          account: {},
        };
      })
      .addMatcher(isFulfilled(recoverPassword), (state, action) => {
        /**
         * RecoverPassword
         */
        return {
          ...state,
          loading: false,
          isAuthenticated: false,
          account: {},
        };
      })

      .addMatcher(isFulfilled(getEntity), (state, action) => {
        return {
          ...state,
          loading: false,
          entity: action.payload.data,
        };
      })
      .addMatcher(isRejected(getEntity), (state, action) => {
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
          entity: null,
        };
      })
      .addMatcher(isPending(getEntity), (state, action) => {
        return {
          ...state,
          loading: true,
          errorMessage: null,
          entity: null,
        };
      })

      .addMatcher(isPending(updateProfile), state => {
        state.errorMessage = null;
        state.loading = true;
        state.updateSuccess = false;
      })

      .addMatcher(isPending(login), state => {
        state.isAuthenticating = true;
      })
      .addMatcher(isPending(register, getCurrentUser, updateProfile, login), state => {
        /**
         * Loading ...
         */
        state.loginRegisterErrorMessage = null;
        state.errorMessage = null;
        state.updateSuccess = false;
        //state.account = {};
        //state.isAuthenticated = false;
        state.loading = true;
      })
  },
});
export const { clearError, reset } = USER.actions;
// Reducer
export default USER.reducer;