import { useParams } from "react-router-dom";
import Theme404 from '../../layout/404';

import customer_list from './customer.list';
import customer_view from './customer.new';

export default  function CustomerIndex() {
    let useParam =  {} as any;
        useParam = useParams();
    let Param = useParam.slug || 'list';
    let ActualPage: any;
    switch (Param) {
        
        case 'list':
            ActualPage = customer_list;
        break;

        case 'view':
            ActualPage = customer_view;
        break;

        case 'edit':
            ActualPage = customer_view;
        break;

        case 'new':
            ActualPage = customer_view;
        break;

        default:
            ActualPage =  Theme404;
        break;
    }

    return (
        <>
            {<ActualPage />}
        </>
    );
}
