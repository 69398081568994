import { Box, Button, InlineGrid, InlineStack, OptionList, Popover, Scrollable, Select, useBreakpoints } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import {
  CalendarIcon,
  } from '@shopify/polaris-icons';

export default function DateRangePickerChart({onChange, Alignment, label, sizeBtn, fullWidth}: {onChange: Function, Alignment?: 'left' | 'right' | 'center', label?: string, sizeBtn?: "slim" | "micro" | "medium" | "large", fullWidth?: boolean}) {
    const { mdDown, lgUp } = useBreakpoints();
    const shouldShowMultiMonth = lgUp;
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const yesterday = new Date(
      new Date(new Date().setDate(today.getDate() - 1)).setHours(0, 0, 0, 0)
    );
    const ranges = [
      {
        title: "theo năm",
        alias: "year",
        period: {
          since: yesterday,
          until: new Date(new Date(yesterday).setHours(23, 59, 59, 0)),
        },
      },
      {
        title: "theo ngày",
        alias: "yesterday",
        period: {
          since: yesterday,
          until: new Date(new Date(yesterday).setHours(23, 59, 59, 0)),
        },
      },
      // {
      //   title: "Hôm nay",
      //   alias: "today",
      //   period: {
      //     since: today,
      //     until: new Date(new Date(today).setHours(23, 59, 59, 0)),
      //   },
      // },
      {
        title: "theo tuần",
        alias: "last7days",
        period: {
          since: new Date(
            new Date(new Date().setDate(today.getDate() - 7)).setHours(0, 0, 0, 0)
          ),
          until: new Date(new Date(yesterday).setHours(23, 59, 59, 0)),
        },
      },
      {
        title: "theo tháng",
        alias: "last30days",
        period: {
          since: new Date(
            new Date(new Date().setDate(today.getDate() - 30)).setHours(0, 0, 0, 0)
          ),
          until: new Date(new Date(yesterday).setHours(23, 59, 59, 0)),
        },
      },
      // {
      //   title: "Tháng này",
      //   alias: "thismonth",
      //   period: {
      //     since: new Date(
      //       new Date(today.getFullYear(), today.getMonth(), 1)
      //     ),
      //     until: new Date(
      //       new Date(today.getFullYear(), today.getMonth() + 1, 0).setHours(23, 59, 59, 0)
      //     ),
      //   },
      // },
      // {
      //   title: "Tháng trước",
      //   alias: "lastmonth",
      //   period: {
      //     since: new Date(
      //       new Date(today.getFullYear(), today.getMonth() - 1, 1)
      //     ),
      //     until: new Date(
      //       new Date(today.getFullYear(), today.getMonth(), 0).setHours(23, 59, 59, 0)
      //     ),
      //   },
      // },
    ];
    const [popoverActive, setPopoverActive] = useState(false);
    const [activeDateRange, setActiveDateRange] = useState(ranges[0]); // đặt mặc định
    const [inputValues, setInputValues] = useState<any>({});
    const [{ month, year }, setDate] = useState({
      month: activeDateRange.period.since.getMonth(),
      year: activeDateRange.period.since.getFullYear(),
    });
    
    function formatDateToYearMonthDayDateString(date) {
      const year = String(date.getFullYear());
      let month = String(date.getMonth() + 1);
      let day = String(date.getDate());
      if (month.length < 2) {
        month = String(month).padStart(2, "0");
      }
      if (day.length < 2) {
        day = String(day).padStart(2, "0");
      }
      return [year, month, day].join("-");
    }
    function formatDate(date: Date) {
      return formatDateToYearMonthDayDateString(date);
    }

    const apply = useCallback( () => {
      const dataTime = {
        title: activeDateRange.title,
        since: new Date(activeDateRange.period.since).getTime(),
        until: new Date(activeDateRange.period.until).getTime(),
        alias: activeDateRange.alias
      }
      onChange(dataTime)
      setPopoverActive(false);
    }, [activeDateRange]);

    function cancel() {
      setPopoverActive(false);
    }
    useEffect(() => {
      if (activeDateRange) {
        setInputValues({
          since: formatDate(activeDateRange.period.since),
          until: formatDate(activeDateRange.period.until),
        });
        function monthDiff(referenceDate, newDate) {
          return (
            newDate.month -
            referenceDate.month +
            12 * (referenceDate.year - newDate.year)
          );
        }
        const monthDifference = monthDiff(
          { year, month },
          {
            year: activeDateRange.period.until.getFullYear(),
            month: activeDateRange.period.until.getMonth(),
          }
        );
        if (monthDifference > 1 || monthDifference < 0) {
          setDate({
            month: activeDateRange.period.until.getMonth(),
            year: activeDateRange.period.until.getFullYear(),
          });
        }
      }
    }, [activeDateRange]);

    const options: any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const buttonValue = activeDateRange.title === "Custom" ? (
        activeDateRange.period.since.toLocaleDateString('vn', options) +
        " - " +
        activeDateRange.period.until.toLocaleDateString('vn', options)
      ) : (activeDateRange.title);

    return (
      <Popover
        active={popoverActive}
        autofocusTarget="none"
        preferredAlignment={Alignment || 'left'}
        preferredPosition="below"
        fluidContent
        sectioned={false}
        fullHeight
        activator={
          <div>
            { label && <p style={{ marginBottom: 4 }}>{label}</p> }
            <Button
              size={sizeBtn || 'slim'}
              fullWidth={fullWidth || false}
              icon={CalendarIcon}
              onClick={() => setPopoverActive(!popoverActive)}
            >
              {buttonValue}
            </Button>
          </div>
        }
        onClose={() => setPopoverActive(false)}
      >
        <Popover.Pane fixed>
          <InlineGrid
            columns={{
              xs: "1fr",
              // @ts-ignore
              mdDown: "1fr",
              md: "max-content max-content",
            }}
            gap={'0'}
            // ref={datePickerRef}
          >
            <Box
              maxWidth={mdDown ? "516px" : "212px"}
              width={mdDown ? "100%" : "212px"}
              padding={{ xs: '500', md: '0' }}
              paddingBlockEnd={{ xs: '100', md: '0' }}
            >
              {mdDown ? (
                <Select
                  label="dateRangeLabel"
                  labelHidden
                  onChange={(value) => {
                    const result = ranges.find(
                      ({ title, alias }) => title === value || alias === value
                    );
                    setActiveDateRange(result);
                  }}
                  value={activeDateRange?.title || activeDateRange?.alias || ""}
                  options={ranges.map(({ alias, title }) => title || alias)}
                />
              ) : (
                <Scrollable style={{ height: "334px" }}>
                  <OptionList
                    options={ranges.map((range) => ({
                      value: range.alias,
                      label: range.title,
                    }))}
                    selected={[activeDateRange.alias]}
                    onChange={(value) => {
                      setActiveDateRange(
                        ranges.find((range) => range.alias === value[0])
                      );
                    }}
                  />
                </Scrollable>
              )}
            </Box>
          </InlineGrid>
        </Popover.Pane>
        <Popover.Pane fixed>
          <Popover.Section>
            <InlineStack align="end" gap={"200"}>
              <Button onClick={cancel}>Hủy</Button>
              <Button variant="primary" onClick={apply}>
                Áp dụng
              </Button>
            </InlineStack>
          </Popover.Section>
        </Popover.Pane>
      </Popover>
    )
  }