import "@shopify/polaris/build/esm/styles.css";
import {
  Link as ReactRouterLink,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "config/store";
import viTranslations from "@shopify/polaris/locales/vi.json";
import { AppProvider } from "@shopify/polaris";
import {
  separate_route as SeparateRoute,
  public_route,
  private_route,
} from "./config/router-define";
import AppFrame from "layout/appFrame";
import Theme404 from "layout/404";
import { getCurrentUser, clearError, logout } from "store/user.store.reducer";
import { memo, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { IDENTIFY } from "assets";
import { BLACK_LIST_USER_ROLE } from "constant/permission";
import helpers from 'helpers/index';

// In theme
// import tokens from '@shopify/polaris-tokens';

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;
function NtsLink({ children, url, external, ...rest }: any) {
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    // rest.target = "_blank";
    rest.rel = "noopener noreferrer nofollow";
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
}

const PrivateRoute = memo(({ children }: { children: any }) => {
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
  const isAuthenticating = useAppSelector((state) => state.user.isAuthenticating);
  if (!isAuthenticated && !isAuthenticating) {
    let current_url = encodeURIComponent(window.location.href);
    return <Navigate to={`/login?redirect_to=${current_url}#redirect_from_private_route`} replace />;
  } else {

    return children;
  }
});

export function App() {
  const [userRole, setUserRole] = useState('')
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
  const account = useAppSelector((state) => state.user.account);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loadUserData = useCallback(async () => {
    !!helpers.cookie_get('session') && await dispatch(getCurrentUser());
    dispatch(clearError());
    document.getElementById('root').style.display = 'block';
    document.getElementById('before_load').style.display = 'none';
    document.getElementById('before_load').style.width = '0';
    document.getElementById('before_load').style.height = '0';
  }, []);

  const logOutCallback = async () => {
    localStorage.removeItem('app_id');
    localStorage.setItem('redirect', window.location.href);
    dispatch(logout());
  }

  useEffect(() => {
    loadUserData();
  }, []);

  useEffect(() => {
    account && setUserRole(account?.user_role)
  }, [account])

  useEffect(() => {
    if (BLACK_LIST_USER_ROLE.includes(userRole)) {
      logOutCallback()
    }
  }, [userRole])

  useEffect(() => {
    if (isAuthenticated) {
      SeparateRoute.some((item) => item.path === window.location.pathname) && navigate('/')
    }
  }, [isAuthenticated, account])

  return (
    <>
      <Helmet>
        <title>{IDENTIFY.TITLE}</title>
      </Helmet>
      <AppProvider i18n={viTranslations} linkComponent={NtsLink}>
        <Routes>
          {SeparateRoute.map((MainRouter, index) => (
            <Route key={index} path={MainRouter.path} element={
              <AppFrame>
                <MainRouter.Entity />
              </AppFrame>
            } />
          ))}
          {public_route.map((MainRouter, index) => (
            <Route
              key={`public_${index}`}
              path={MainRouter.path}
              element={
                <AppFrame>
                  <MainRouter.Entity />
                </AppFrame>
              }
            />
          ))}
          {private_route.filter((MainRouter, index) => MainRouter.roles.includes(userRole)).map((MainRouter, index) => (
            <Route
              key={`private_${index}`}
              path={MainRouter.path}
              element={
                <AppFrame>
                  <PrivateRoute>
                    <MainRouter.Entity />
                  </PrivateRoute>
                </AppFrame>
              }
            />
          ))}
          <Route path="*" element={<Theme404 />} />
        </Routes>
      </AppProvider>
    </>
  );
};

export default App;
