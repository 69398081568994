import { LegacyCard, Select, Text } from "@shopify/polaris";
import { useAppDispatch } from "config/store";
import { IsDefaultStock } from "enum/stock.enum";
import __helpers from "helpers/index";
import { TypedStock } from "interface/stock.model";
import { useEffect, useState } from "react";
import { TypedLogistic_service } from "store/logistic_service.store.reducer";
import { getEntities } from "store/order_stock.store.reducer";
import { DEFAULT_LOGISTIC_SERVICE } from "enum/logistic.enum";

interface TypedProps {
    // logistic_id: string;
    stock_id?: string;
}
const OrderLogistics = (props: TypedProps) => {
    const { stock_id } = props;

    const [stockes, setStockes] = useState<TypedStock[]>([]);
    const [stockOption, setStockOption] = useState([]);
    const [stockSelected, setStockSelected] = useState(null);

    const [logistics, setLogistics] = useState<TypedLogistic_service[]>([]);
    const [logisticSelected, setLogisticSelected] = useState<string>("");
    const [logisticOptions, setLogisticOptions] = useState([]);

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getEntities({ limit: 100 })).unwrap().then((res) => {
            if (!__helpers.isEmpty(res.data)) {
                setStockes(res.data)
                const dataOption = res.data.map(i => ({ label: i.stock_name, value: i.stock_id }));
                const defaultStock = res.data.find((item) => item.is_default_stock === IsDefaultStock.DEFAULT) || res.data[0];
                setStockSelected(stock_id ?? defaultStock.stock_id)
                setStockOption(dataOption);
            }
        })
    }, []);

    useEffect(() => {
        if (!stockSelected && !Array.isArray(stockes)) return;
        const findStock = stockes.find(i => i.stock_id === stockSelected);

        if (!Array.isArray(findStock?.logistic_service_to_stock)) return;
        const findLogisticDefault = findStock?.logistic_service_to_stock.find(i => i.is_default_service === DEFAULT_LOGISTIC_SERVICE.ENABLE) || findStock?.logistic_service_to_stock?.[0];
        setLogisticSelected(findLogisticDefault.logistic_id)
        setLogistics(findStock.logistic_service_to_stock || []);
        const dataOption = findStock?.logistic_service_to_stock.map(i => ({ label: i.logistic_name, value: i.logistic_id }))
        setLogisticOptions(dataOption)
    }, [stockSelected]);


    return (
        <LegacyCard.Section subdued>
            <Text as="h4" variant="headingMd">Kho vận</Text>
            <br />

            <Select
                label="Chọn kho"
                disabled={true}
                options={stockOption}
                // onChange={handleChangeStock}
                value={stockSelected}
            />
            <br />
{/* 
            {
                !__helpers.isEmpty(logistics) ? (
                    <Select
                        label="Chọn dịch vụ logistic"
                        disabled={false}
                        options={logisticOptions}
                        // onChange={handleLogiscticServiceChange}
                        value={logisticSelected}
                    />
                ) : null
            } */}
            <br />
        </LegacyCard.Section>
    )

};

export default OrderLogistics