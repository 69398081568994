import React, { useCallback, useEffect, useState } from "react";
import { Badge, DataTable, LegacyCard, Link, Loading, Page, Toast, Text, InlineStack } from "@shopify/polaris";
import { useAppDispatch, useAppSelector } from "config/store";
import { useNavigate } from "react-router-dom";
import dateandtime from "date-and-time";


import {
    clearError,
    getEntities,
    TypedLogistic_service,
    reset as resetLogisticData
  } from "store/logistic_service.store.reducer";


import LogisticModal from './logistic.modal';


export default function LogisticsManager() {

    const entities = useAppSelector((state) => state.logistic_service.entities);
    const loading = useAppSelector((state) => state.logistic_service.loading);
    const updating = useAppSelector((state) => state.logistic_service.updating);
    const updateSuccess = useAppSelector((state) => state.logistic_service.updateSuccess);
    const dispatch = useAppDispatch();
    const history = useNavigate();


    const loadInitialData = useCallback( async() => {
      // await dispatch(resetLogisticData());
      dispatch( getEntities({
        limit: 30,
        sort: 'createdAt:desc'
      }))
    }, []);

    useEffect (() => {
      loadInitialData();
    }, []);


    const [showModalCreateNew, setShowModalCreateNew] = useState(false);
    
    const closeModalCallback = useCallback( () => {
        setShowModalCreateNew(false);
        loadInitialData();
    }, []);

    const showDetailCallback = useCallback( (logistic_id: string) => {
        history('/logistic/' + logistic_id);
    }, []);


    const clearErrorCallback = useCallback(() => {
        dispatch(clearError());
      }, []);
    
      useEffect( () => {
        clearErrorCallback();
      }, []);

  
  const successNotice = updateSuccess ? (
    <Toast content={'Cập nhật thành công!'} onDismiss={clearErrorCallback} />
  ) : null;
    
    const element = (logisticData: TypedLogistic_service) => {
        const {
          logistic_id, logistic_count, 
          module, 
          logistic_name, logistic_status, 
          logistic_delivery_method,
          createdAt} = logisticData;
        return [
            <Link onClick={() => showDetailCallback(logistic_id) }>{logistic_name}</Link>,
            <InlineStack gap={"200"} blockAlign="center" align="start">
              {
                (logistic_delivery_method)?.split(',').map( (e, i) => e && <Text as="span" key={i}>#{e}</Text> )
              }
            </InlineStack>
            ,
            logistic_status === 1 ? ( <Badge tone="success">Bật</Badge> ) : <Badge>Tắt</Badge>,
            module,
            logistic_count,
            dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY hh:mm')
        ];
    }

    return (
        <>
        {successNotice}
        {loading || updating ? <Loading /> : null}
        <Page 
            title="Đơn vị vận chuyển"
            primaryAction={{
                content: 'Thêm mới',
                onAction: () => {
                    setShowModalCreateNew(true);
                },
                loading: updating
            }}
        >
        <LegacyCard>
          {
            entities.length > 0 ? (
              <DataTable
              columnContentTypes={[
                'text',
                'text',
                'numeric',
                'text',
                'numeric',
                'numeric',
              ]}
              headings={[
                'Tên đơn vị',
                'Phương thức',
                'Tình trạng',
                'Module',
                'Đã giao',
                'Ngày tạo',
              ]}
              hideScrollIndicator
              rows={entities?.map(element)}
            />
  
            ): (
              <div style={{padding: "15px", textAlign: 'center'}}>
                <Text as="h1" variant="headingMd">Chưa có một nhà vận chuyển nào!</Text>
                <Text as="p" tone="caution">Bấm THÊM MỚI để cài đặt một nhà vận chuyển mặc định cho từng phương thức vận chuyển. Ví dụ như Grab cho vận chuyển nhanh!</Text>
              </div>
            )
          }
        </LegacyCard>
      </Page>
      <LogisticModal show={showModalCreateNew} onClose={closeModalCallback} />
      </>
    )
}