import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { IQueryParams, createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { IUser } from '../interface/user.model';
import { ENDPOINT_URL } from 'constant';

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as IUser[],
  entity: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = `${ENDPOINT_URL}/user/admin`;

// Actions

export const getEntities = createAsyncThunk('users/fetch_entity_list', async (_URL: IQueryParams) => {
  let __URL = helpers.buildEndUrl(_URL);
  const requestUrl = `${apiUrl}${__URL}`;
  return await axios.get<any>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'users/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'users/create_entity',
  async (entity: IUser, thunkAPI) => {
    const result = await axios.post<IUser>(`${apiUrl}`, helpers.cleanEntity(entity));
    /**
     * Sau khi thực hiện xong quẻry bên trên thì bắt đầu thực hiện thêm cái quẻry bên dưới :D 
     */
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);



export const setUserNewPassword = createAsyncThunk('setNewPassword', async (entity: { password: string, user_id?: string }) => {
  return await axios.patch<any>(`${apiUrl}/set_new_password`, helpers.cleanEntity(entity));
},
  { serializeError: serializeAxiosError }
);


export const updateEntity = createAsyncThunk(
  'users/update_entity',
  async (entity: IUser, thunkAPI) => {
    let userID = String(entity.user_id);
    delete entity.user_id;
    const result = await axios.patch<IUser>(`${apiUrl}/${userID}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntity(userID));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'users/partial_update_entity',
  async (entity: IUser, thunkAPI) => {
    const result = await axios.patch<IUser>(`${apiUrl}/${entity.user_id}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);


export const deleteEntity = createAsyncThunk(
  'users/delete_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return await axios.delete<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);


export const getOnetimepassword = createAsyncThunk(
  'users/get_onetimepassword',
  async (user_id: string | number) => {
    const requestUrl = `user/onetimepassword/${user_id}`;
    return await axios.post<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const addUserToJob = createAsyncThunk(
  'users/add_user_to_job',
  async (params: any) => {
    const requestUrl = `user/user_to_job/`;
    return await axios.post<any>(requestUrl, params);
  },
  { serializeError: serializeAxiosError }
);


// slice

export const USERS = createEntitySlice({
  name: 'users',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = null;
    },
    reset: (state) => {
      return { ...state, ...initialState };
    },
    clearEntity: (state) => {
      state.entity = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(deleteEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = "Can not delete this Entity"
      })
      .addCase(setUserNewPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(deleteEntity), state => {
        state.updating = true;
        state.loading = true;
        state.updateSuccess = false;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { clearError, reset, clearEntity } = USERS.actions;

// Reducer
export default USERS.reducer;
