import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { IQueryParams, createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { TypedCustomer } from '../interface/customer.model';
import { ENDPOINT_URL } from 'constant';

type TypedQuery = TypedCustomer & IQueryParams;

/**
*   Reducer used for front-end, with customer.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using customer.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null as any,
  entities: [],
  updateSuccess: false,
  totalItem: 0
};

const apiUrl = '/wallet/admin/withdraw';

// Actions

export const getRequestWithdrawList = createAsyncThunk('request_withdraw/fetch_entity_list', async (object: TypedQuery) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${ENDPOINT_URL}${apiUrl}${EndURL}`;
  return axios.get<any>(requestUrl);
});

// POST duyệt

export const approveRequestWithdraw = createAsyncThunk(
  'request_withdraw/approveRequestWithdraw',
  async (entity: any, thunkAPI) => {
    const result = await axios.post(`${ENDPOINT_URL}${apiUrl}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getRequestWithdrawList({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_request_withdraw = createEntitySlice({
  name: 'request_withdraw',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = null;
    },
    clearEntities: (state) => {
      state.entities = null
    },
    reset: (state) => {
      return {...state, ...initialState};
    }
  },
  extraReducers(builder) {
    builder

    .addCase(getRequestWithdrawList.fulfilled, (state, action) => {
      state.loading = false;
      state.entities = action.payload.data;
      state.totalItem = parseInt(action.payload.headers['x-total-count'], 10);
    })
    .addCase(getRequestWithdrawList.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getRequestWithdrawList.rejected, (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
    })

    .addCase(approveRequestWithdraw.fulfilled, (state, action) => {
      state.updateSuccess = true;
      state.loading = false;
    })
    .addCase(approveRequestWithdraw.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(approveRequestWithdraw.rejected, (state, action) => {
      state.loading = false;
      state.updateSuccess = false;
    })

  },
});

export const { clearError, clearEntities, reset } = Reducer_request_withdraw.actions;

// Reducer
export default Reducer_request_withdraw.reducer;
