import { createEntitySlice } from "config/reducer.utils";  
  

const initialState = {
    errorMessage: null,
    normalMessage: null,
  };


  export const globalNotification = createEntitySlice({
    name: 'global_notification',
    initialState,
    reducers: {
      clearError: (state) => {
        state.errorMessage = null;
      },
      clearNormalMessage: (state) => {
        state.normalMessage = null;
      },
      setError: (state, action) => {
        state.errorMessage = action.payload;
      },
      setNormalMessage: (state, action) => {
        state.normalMessage = action.payload;
      },
    },
    extraReducers: (builder) =>  {}
  });
  
  export const { setError, clearError, setNormalMessage, clearNormalMessage, reset } = globalNotification.actions;

// Reducer
export default globalNotification.reducer;

