import React from "react";
import { useCallback, useEffect, useState } from "react";

import { Divider, Grid, InlineGrid, InlineStack, Select, SkeletonDisplayText, Text, BlockStack, Card, Layout, LegacyCard, ResourceList, Thumbnail, Bleed, Image, Box, Page } from "@shopify/polaris";
import { ReactECharts, ReactEChartsProps } from 'components/React-ECharts';
import helpers from "../../../helpers";
import { useAppDispatch, useAppSelector } from '../../../config/store';
import DateRangePickerChart from "../../../components/dateRangePickerChart";
import BestSellingProducts from "./components/best_selling_products";
import TopSellers from "./components/top_sellers";
import { getProductTop5 } from "store/analytics.store.reducer";

export default function ReportProductIndex() {
  const top5product = useAppSelector(state => state.analytics.top5product);
  const dispatch = useAppDispatch();
  const [topListProduct, setTopListProduct] = useState([])
  const [productTop, setProductTop]: any = useState()

  useEffect(() => {
    dispatch(getProductTop5({}));
  }, []);

  useEffect(() => {
    if (top5product?.length > 0) {
      const productList = [...top5product]
      setTopListProduct(productList.sort((a, b) => b.item_sold_quantity - a.item_sold_quantity));
    }
  }, [top5product])

  useEffect(() => {
    topListProduct?.length > 0 && setProductTop(topListProduct[0])
  }, [topListProduct])


  return (
    <Page
      fullWidth
      title={'Thống kê sản phẩm'}
    >
      <Layout>
        <Layout.Section>
          <LegacyCard title="Top 5 sản phẩm bán chạy nhất" sectioned>
            <ResourceList
              resourceName={{ singular: 'product', plural: 'products' }}
              items={topListProduct.map((item, index) => {
                return {
                  id: item.product_id,
                  url: `/product/edit/${item.product_id}`,
                  index: index,
                  name: item?.product_name,
                  quantity: item?.item_sold_quantity,
                  media: (
                    <Thumbnail
                      source={item.product_media[0]}
                      alt={item.product_name}
                    />
                  ),
                }
              })}
              renderItem={(item) => {
                const { id, url, name, media, quantity, index } = item;

                return (
                  <ResourceList.Item
                    id={id}
                    url={url}
                    media={media}
                    accessibilityLabel={`View details for ${name}`}
                  >
                    <span style={{ color: 'red', fontWeight: 'bold', fontSize: 18 }}>
                      Top {index + 1}
                    </span>
                    <Text variant="bodyMd" fontWeight="bold" as="h3">
                      {name}
                    </Text>
                    <div>{quantity ?? 0} lượt bán</div>
                  </ResourceList.Item>
                );
              }}
            />
          </LegacyCard>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <LegacyCard title="Sản phẩm top 1" sectioned>
            <Card roundedAbove="sm">
              <Bleed marginInline="400" marginBlock="400">
                <Image
                  width={'100%'}
                  source={productTop?.product_media[0]}
                  alt={productTop?.product_name}
                />
                <Box background="bg-surface-secondary" padding="400">
                  <Text variant="bodyMd" fontWeight="bold" as="h3">
                    {productTop?.product_name}
                  </Text>
                  <div>{productTop?.item_sold_quantity ?? 0} lượt bán</div>
                </Box>
              </Bleed>
            </Card>
          </LegacyCard>
        </Layout.Section>
      </Layout>
      <br />
      <Layout>
        {/* <Layout.Section variant="oneHalf">
          <LegacyCard title="Danh sách top sản phẩm doanh thu cao" sectioned>
            <BestSellingProducts/>
          </LegacyCard>
        </Layout.Section> */}
        <Layout.Section  variant="oneHalf">
          <LegacyCard title="Danh sách top người bán doanh thu cao" sectioned>
            <TopSellers/>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  )
}