  export default function errorMiddlewareHandle() {
    
    return next => action => {
        // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    /**
       *
       * The error middleware serves to log error messages from dispatch
       * It need not run in production
       */
      if (process.env.NODE_ENV === 'development') {
        const { error } = action;
        if (error) {
          console.error(`${action.type} caught at middleware with reason: ${JSON.stringify(error, null, 4)}.`);
        }
      }
      
    // Dispatch initial action
    return next(action);
  }
}