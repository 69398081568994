import { useEffect, useRef, useState } from "react";

// convert data adress
export const convertData = (data: any) => {
  const dataArray: Array<any> = []
  if (data !== undefined && data !== null) {
    Object.keys(data).forEach(key => {
      dataArray.push(data[key])
    })
    return dataArray
  }
}

// convert list commission value 
export const convertDataCommission = (value, array) => {
  const result = array.find(val => value === val.value)
  return result.label
}

// convert content to slug
export function slugify(str: string) {
  str = str.replace(/^\s+|\s+$/g, '');
  str = str.toLowerCase();
  str = str.replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
  return str;
}

export const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: mimeString });
};

// validate number
export function validateNumber(inputString) {
  var pattern = /^[0-9]\d*$/;
  return pattern.test(inputString);
}

// convert value init commission
export const convertCommissionValueUnit = (rule_unit) => {
  switch (rule_unit) {
    case `money`:
      return 'vnđ'
      break;
    case `percent`:
      return '%'
      break;
    default:
      break;
  }
}

// Cắt chữ phần mô tả trong table
export function wrapText(text, maxLength) {
  let words = text?.split(' ');
  let lines = [];
  let currentLine = '';

  words?.forEach(word => {
    if ((currentLine + ' ' + word).trim()?.length <= maxLength) {
      currentLine += (currentLine === '' ? '' : ' ') + word;
    } else {
      lines.push(currentLine?.trim());
      currentLine = word;
    }
  });

  if (currentLine !== '') {
    lines.push(currentLine?.trim());
  }

  return lines?.map(line => `<p>${line}</p>`).join('\n');
}

// convert data time for chart
export const generateDaysArray = (since, until) => {
  const days = [];
  const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in one day
  for (let timestamp = since; timestamp <= until; timestamp += oneDay) {
    const date = new Date(timestamp);
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    days.push(formattedDate);
  }
  if (days.length === 1) {
    const [day, month, year] = days[0].split('-').map(Number);
    const firstTimestampOfDay = new Date(year, month - 1, day).getTime();
    const hoursArray = [];
    for (let i = 0; i < 24; i++) {
      hoursArray.push(`${i}h`);
    }
    return hoursArray;
  }
  return days;
};

export const useGetThumbnail = ({
  filterQuality = 70,
  useFilterQuality = false
}: {
  filterQuality?: number;
  useFilterQuality?: boolean;
} = {}) => {
  const wrapperRef: any = useRef(null);
  const [thumbnailSize, setThumbnailSize] = useState({
    width: 300,
    height: 300,
  });

  const handleResize = () => {
    if (wrapperRef?.current && !useFilterQuality) {
      setThumbnailSize({
        width: wrapperRef?.current?.clientWidth * 2 || 300,
        height: wrapperRef?.current?.clientHeight * 2 || 300,
      });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [wrapperRef?.current]);

  const handleGetThumbnailUrl = (url: string, width?: string | number, height?: string | number) => {
    if (!url) return null;
    const widthImage = typeof width === 'string' ? width?.replace('px', '') : width
    const heightImage = typeof height === 'string' ? height?.replace('px', '') : height
    if (useFilterQuality) {
      return `${process.env.PUBLIC_THUMB_URL='https://thumb.socialv2.appuni.io'}/unsafe/filters:quality(${filterQuality})/${url}`;
    }

    return `${process.env.PUBLIC_THUMB_URL='https://thumb.socialv2.appuni.io'}/unsafe/${widthImage || thumbnailSize.width}x${heightImage || thumbnailSize.height}/${url}`;
  };

  return { ref: wrapperRef, getThumbnailUrl: handleGetThumbnailUrl };
};