import axios, { AxiosProgressEvent, AxiosResponse } from 'axios'
import { TypedMediaResponse } from 'interface/media.model'

export async function uploadMedia(
  formData: FormData,
  callbackProgress?: (progress: number) => void,
  callbackSuccess?: Function,
  callbackFail?: Function
): Promise<TypedMediaResponse[]> {
  try {
    const mediaResponse = await axios.post < TypedMediaResponse[] > (
      `${process.env.REACT_APP_MEDIA_UPLOAD_URL}`,
      formData,
      {
        timeout: 1800000,
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: progressEvent => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          callbackProgress?.(progress)
        }
      }
    )

    if (Array.isArray(mediaResponse.data)) {
      callbackSuccess?.()
      return mediaResponse.data
    }
    return []
  } catch (error) {
    // callbackFail?.();  // ?
    callbackFail?.call(this, null)
    console.log(error, 'syncMessages')
    throw error
  }
}
export async function uploadFilesZip(
  formData: FormData,
  callbackProgress?: (progress: number) => void,
  callbackSuccess?: Function,
  callbackFail?: Function
): Promise<TypedMediaResponse[]> {
  try {
    const mediaResponse = await axios.post < TypedMediaResponse[] > (
      `${process.env.REACT_APP_FILE_UPLOAD_URL}`,
      formData,
      {
        timeout: 1800000,
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: progressEvent => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          callbackProgress?.(progress)
        }
      }
    )

    if (Array.isArray(mediaResponse.data)) {
      callbackSuccess?.()
      return mediaResponse.data
    }
    return []
  } catch (error) {
    // callbackFail?.();  // ?
    callbackFail?.call(this, null)
    console.log(error, 'syncMessages')
    throw error
  }
}

export async function uploadFile(
  formData: FormData,
  callbackProgress?: (progress: number) => void,
  callbackSuccess?: Function,
  callbackFail?: Function
): Promise<TypedMediaResponse[]> {
  try {
    const mediaResponse = await axios.post < TypedMediaResponse[] > (
      `${process.env.REACT_APP_FILE_UPLOAD_URL}`,
      formData,
      {
        timeout: 1800000,
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: progressEvent => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          callbackProgress?.(progress)
        }
      }
    )

    if (Array.isArray(mediaResponse.data)) {
      callbackSuccess?.()
      return mediaResponse.data
    }
    return []
  } catch (error) {
    // callbackFail?.();  // ?
    callbackFail?.call(this, null)
    console.log(error, 'syncMessages')
    throw error
  }
}

const handleUploadProgress = (progressEvent: AxiosProgressEvent) => {
  const { loaded, total } = progressEvent
  let progress = Math.floor((loaded * 100) / total)
  return progress
}

export async function uploadFiles(
  formData: FormData,
  onProgress?: (progress: number) => void
): Promise<AxiosResponse<TypedMediaResponse[]>> {
  return axios.post < FormData, AxiosResponse < TypedMediaResponse[] >> (
    `${process.env.REACT_APP_FILE_UPLOAD_URL}`,
    formData,
    {
      timeout: 1800000,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: event => onProgress(handleUploadProgress(event))
    }
  )
}
