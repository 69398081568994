import React from "react";
import { Divider, InlineGrid, Page } from "@shopify/polaris";
import { ReactECharts, ReactEChartsProps } from 'components/React-ECharts';
import DateRangePickerChart from "../../../components/dateRangePickerChart";

export default function ReportRevenueIndex() {

  const totalSaleViaAllChanelOption: ReactEChartsProps["option"] = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {},
    grid: {
      left: 50,
      top: 50,
      right: 50,
      bottom: 50
    },
    xAxis: [
      {
        type: 'category',
        data: ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'],
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Đơn vị tính: VNĐ',
        alignTicks: true,
        axisLabel: {
          formatter: '{value}'
        }
      }
    ],
    series: [
      {
        name: 'Comming1',
        type: 'bar',
        // prettier-ignore
        data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3]
      },
      {
        name: 'Comming2',
        type: 'bar',
        // prettier-ignore
        data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3]
      },
    ]
  };

  return (
    <Page
      fullWidth
      title={'Thống kê doanh thu (Đang phát triển phần mềm dữ liệu chưa chính xác)'}
    >
      <InlineGrid gap="400" columns={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
        <div className="dashboard_column_1_wrap">
          <Divider />
          <div>
            <DateRangePickerChart onChange={() => { }} />
            <ReactECharts option={totalSaleViaAllChanelOption} style={{ minHeight: '400px' }} graphic={undefined} />
          </div>
        </div>
      </InlineGrid>
    </Page>
  )
}