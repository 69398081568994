/**
 * Config bắt đầu từ đây!
 * Quy định route với: Private, public, separate route ...
 */
import HelpCenter from "../entities/help_center/help_center";
import Login from "../layout/login";
import ActiveAccount from "../layout/active.account";
import LoginByFacebook from "../layout/login.facebook";
import QuickLogin from "../layout/quick.login";
// import Register from "../layout/register";
import RecoverPassword from "../layout/recover-password";
import Dashboard from "entities/dashboard";
import Profile from "entities/user-profile/profile";

import Notification from "../entities/notification";
import Product from "entities/product";
import Order from "entities/order";
import Logistic from "entities/logistic/index";
import Stock from "entities/stock";
import UserInternalIndex from "entities/user_internal";
import UserInGroupIndex from "entities/user_in_group";
import Commission from "entities/commission";
import UserReferrer from 'entities/user_referrer'
import { USER_CAPACITY_LIST } from "constant";
import UserReferrerCommission from 'entities/user_referrer_commission'
import UserGroupCommission from 'entities/user_group_commission'
import CustomerIndex from 'entities/customers'
import ProductCategory from 'entities/product_category'
import ProductCollection from 'entities/product_collection'
import ReportProductIndex from "entities/reports/report_product";
import ReportRevenueIndex from "entities/reports/report_revenue";
import ReportOrdersIndex from "entities/reports/report_orders";
import ReportGroupUserIndex from "entities/reports/report_group_user";
import BannerSettingIndex from "entities/settings/setting_banner";
import ReleaseNote from "entities/release_note";
import RequestWithdrawIndex from "entities/request_withdraw";

/**
 * Private, w/ <AppFrame />
 * Only authorization can access this route
 */
export const private_route = [
  {
    path: "/notification",
    Entity: Notification,
    roles: USER_CAPACITY_LIST
  },
  {
    path: "/notification/:slug/:notification_slug",
    Entity: Notification,
    roles: USER_CAPACITY_LIST
  },
  {
    path: "/notification/:slug",
    Entity: Notification,
    roles: USER_CAPACITY_LIST
  },
  
  
  {
    path: "/orders",
    Entity: Order,
    roles: ['admin', 'super_admin', 'accountant', 'sale']
  },
  {
    path: "/orders/:slug/:order_id",
    Entity: Order,
    roles: ['admin', 'super_admin', 'accountant', 'sale']
  },
  {
    path: "/orders/:slug",
    Entity: Order,
    roles: ['admin', 'super_admin', 'accountant', 'sale']
  },
  {
    path: "/logistic",
    Entity: Logistic,
    roles: ['admin', 'super_admin', 'accountant', 'sale', 'stock_manager']
  },

  {
    path: "/logistic/:logistic_id",
    Entity: Logistic,
    roles: ['admin', 'super_admin', 'accountant', 'sale', 'stock_manager']
  },


  {
    path: "/profile",
    Entity: Profile,
    roles: USER_CAPACITY_LIST
  },
  {
    path: "/profile/:user_id",
    Entity: Profile,
    roles: USER_CAPACITY_LIST
  },

  // {
  //   path: "/commission",
  //   Entity: Commission,
  //   roles: ['admin', 'super_admin', 'accountant']
  // },
  // {
  //   path: "/commission/:slug",
  //   Entity: Commission,
  //   roles: ['admin', 'super_admin', 'accountant']
  // },
  // {
  //   path: "/commission/:slug/:commission_id",
  //   Entity: Commission,
  //   roles: ['admin', 'super_admin', 'accountant']
  // },

  // {
  //   path: "/user_referrer_commission",
  //   Entity: UserReferrerCommission,
  //   roles: ['admin', 'super_admin', 'accountant']
  // },
  // {
  //   path: "/user_referrer_commission/:slug",
  //   Entity: UserReferrerCommission,
  //   roles: ['admin', 'super_admin', 'accountant']
  // },
  // {
  //   path: "/user_referrer_commission/:slug/:user_referrer_commission_id",
  //   Entity: UserReferrerCommission,
  //   roles: ['admin', 'super_admin', 'accountant']
  // },

  // {
  //   path: "/user_group_commission",
  //   Entity: UserGroupCommission,
  //   roles: ['admin', 'super_admin', 'accountant']
  // },
  // {
  //   path: "/user_group_commission/:slug",
  //   Entity: UserGroupCommission,
  //   roles: ['admin', 'super_admin', 'accountant']
  // },
  // {
  //   path: "/user_group_commission/:slug/:user_group_commission_id",
  //   Entity: UserGroupCommission,
  //   roles: ['admin', 'super_admin', 'accountant']
  // },

  {
    path: "/user_referrer",
    Entity: UserReferrer,
    roles: ['admin', 'super_admin', 'accountant']
  },
  {
    path: "/user_referrer/:slug",
    Entity: UserReferrer,
    roles: ['admin', 'super_admin', 'accountant']
  },
  {
    path: "/user_referrer/:slug/:user_referrer_id",
    Entity: UserReferrer,
    roles: ['admin', 'super_admin', 'accountant']
  },

  {
    path: "/stock",
    Entity: Stock,
    roles: ['admin', 'super_admin', 'accountant', 'sale', 'stock_manager']
  },
  {
    path: "/stock/:slug",
    Entity: Stock,
    roles: ['admin', 'super_admin', 'accountant', 'stock_manager', 'sale']
  },
  {
    path: "/stock/:slug/:stock_id",
    Entity: Stock,
    roles: ['admin', 'super_admin', 'accountant', 'stock_manager', 'sale']
  },
  {
    path: "/product",
    Entity: Product,
    roles: ['admin', 'super_admin', 'accountant', 'sale']
  },
  {
    path: "/product/:slug/:product_id",
    Entity: Product,
    roles: ['admin', 'super_admin', 'accountant', 'sale']
  },
  {
    path: "/product/:slug",
    Entity: Product,
    roles: ['admin', 'super_admin', 'accountant', 'sale']
  },

  {
    path: "/product_category",
    Entity: ProductCategory,
    roles: ['admin', 'super_admin', 'accountant', 'sale']
  },
  {
    path: "/product_category/:slug/:product_category_id",
    Entity: ProductCategory,
    roles: ['admin', 'super_admin', 'accountant', 'sale']
  },
  {
    path: "/product_category/:slug",
    Entity: ProductCategory,
    roles: ['admin', 'super_admin', 'accountant', 'sale']
  },

  {
    path: "/product_collection",
    Entity: ProductCollection,
    roles: ['admin', 'super_admin', 'accountant', 'sale']
  },
  {
    path: "/product_collection/:slug/:product_collection_id",
    Entity: ProductCollection,
    roles: ['admin', 'super_admin', 'accountant', 'sale']
  },
  {
    path: "/product_collection/:slug",
    Entity: ProductCollection,
    roles: ['admin', 'super_admin', 'accountant', 'sale']
  },

  {
    path: "/user_internal",
    Entity: UserInternalIndex,
    roles: ['admin', 'super_admin', 'accountant']
  },
  {
    path: "/user_internal/:slug",
    Entity: UserInternalIndex,
    roles: ['admin', 'super_admin', 'accountant']
  },
  {
    path: "/user_internal/:slug/:user_id",
    Entity: UserInternalIndex,
    roles: ['admin', 'super_admin', 'accountant']
  },

  {
    path: "/customer",
    Entity: CustomerIndex,
    roles: ['admin', 'super_admin', 'accountant']
  },
  {
    path: "/customer/:slug",
    Entity: CustomerIndex,
    roles: ['admin', 'super_admin', 'accountant']
  },
  {
    path: "/customer/:slug/:user_id",
    Entity: CustomerIndex,
    roles: ['admin', 'super_admin', 'accountant']
  },
  
  {
    path: "/group_user",
    Entity: UserInGroupIndex,
    roles: ['admin', 'super_admin', 'accountant']
  },
  
  {
    path: "/group_user/:slug",
    Entity: UserInGroupIndex,
    roles: ['admin', 'super_admin', 'accountant']
  },

  // report router
  {
    path: "/reports_product",
    Entity: ReportProductIndex,
    roles: ['admin', 'super_admin', 'accountant']
  },
  {
    path: "/reports_revenue",
    Entity: ReportRevenueIndex,
    roles: ['admin', 'super_admin', 'accountant']
  },
  {
    path: "/reports_orders",
    Entity: ReportOrdersIndex,
    roles: ['admin', 'super_admin', 'accountant']
  },
  {
    path: "/reports_group_user/:slug",
    Entity: ReportGroupUserIndex,
    roles: ['admin', 'super_admin', 'accountant']
  },

  // setting banner
  {
    path: "/setting_banner",
    Entity: BannerSettingIndex,
    roles: ['admin', 'super_admin', 'accountant']
  },

  // paymnet manager
  {
    path: "/withdraw",
    Entity: RequestWithdrawIndex,
    roles: ['admin', 'super_admin', 'accountant']
  },

];

/**
 * Any one can access this URL
 */
export const public_route = [
  {
    path: "/",
    Entity: Dashboard,
  },
  {
    path: "/release_note",
    Entity: ReleaseNote,
  }
];

/**
 * Run first and wihout <AppFrame>,
 * It is like login page, register page
 */

export const separate_route = [
  {
    path: "/login",
    Entity: Login,
  },
  {
    path: "/active-account",
    Entity: ActiveAccount,
  },
  {
    path: "/login/sso",
    Entity: Login,
  },
  {
    path: "/login/facebook",
    Entity: LoginByFacebook,
  },
  {
    path: "/quick-login/:token",
    Entity: QuickLogin,
  },
  // {
  //   path: "/register",
  //   Entity: Register,
  // },
  {
    path: "/recover_password",
    Entity: RecoverPassword,
  },
  {
    path: "/help_center",
    Entity: HelpCenter,
  },
  {
    path: "/help_center/:slug",
    Entity: HelpCenter,
  }
];
