/*************
 * User permission
 */

export const USER_CAPACITY_LIST = {
  "visitor": [
    "visitor_view_post"
  ],
  "customer": [],
  "user": [
    "user_view_post",
    "user_view_news"
  ],
  "advisor": [
    "advisor_general_service",
    "advisor_premium_service",
    "advisor_livestream",
  ],
  "staff": [
    "staff_write_post",
    "staff_delete_post",
    "staff_edit_post",
    "staff_view_post",
  ],
  "admin": [
    "admin_write_post",
    "admin_delete_post",
    "admin_edit_post",
    "admin_view_post",
  ],
}

export const BLACK_LIST_USER_ROLE = ['user', 'customer']