import { DataTable, Page, Layout, Card, EmptyState, Badge } from "@shopify/polaris";
import __ from "languages/index";
import emptyIMG from "../../media/images/empty.png";
import { wrapText } from "utils";
import ReleaseNotes from "../../assets/release_note.json"

export default function ReleaseNote() {

  const emptyData = (
    <EmptyState heading="Dữ liệu trống" image={emptyIMG}>
      <p>Không tìm thấy danh mục sản phẩm</p>
    </EmptyState>
  );

  const items = (release, index) => {
    const {
      link_git,
      description,
      createdAt,
      createBy,
      round
    } = release;

    return [
      index + 1,
      <a target={'_blank'} href={link_git}>{link_git}</a>,
      description ? <div dangerouslySetInnerHTML={{ __html: wrapText(description, 50) }}></div> : <Badge></Badge>,
      <span>{createBy}</span>,
      <span>{round}</span>,
      createdAt ? <span>{createdAt}</span> : '',
    ]
  }

  const ReleaseNote = (
    <>
      {
        ReleaseNotes.release_notes && ReleaseNotes.release_notes.length > 0 ? (
          <DataTable
            hideScrollIndicator
            columnContentTypes={[
              'text',
              'text',
              'text',
              'text',
              'text',
              'text',
              'text',
            ]}
            headings={[
              "STT",
              "Link git branch",
              "Mô tả chức năng",
              "Người code",
              "Lượt code trong ngày",
              "Ngày tạo",
            ]}
            rows={ReleaseNotes.release_notes?.map(items)}
            footerContent={`Showing ${ReleaseNotes.release_notes?.length} of ${ReleaseNotes.release_notes?.length} results`}
            hasZebraStripingOnData
            increasedTableDensity
          />
        ) : emptyData
      }
    </>
  )

  return (
    <>
      <Page
        fullWidth
        title={"Danh sách các chức năng đã làm"}
      >
        <Layout>
          <Layout.Section>
            <Card padding={'0'}>
              {ReleaseNote}
            </Card>
            <br />
          </Layout.Section>
        </Layout>
      </Page>
    </>
  )
}