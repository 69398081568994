import axios from 'axios';
import { createAsyncThunk, isFulfilled,  } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { ENDPOINT_URL } from 'constant';
import { TypeDataUserReferrerCommission } from 'interface/user_referrer_comission.model';
/**
*   Reducer used for front-end, with media.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using media.model.ts
*/


// interface getQuery extends IQueryParams, TypedProduct{};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: null as any as TypeDataUserReferrerCommission[],
  entity: null as  TypeDataUserReferrerCommission,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};
const apiUrlReferrerCommission = `${ENDPOINT_URL}/user_referrer_rule`;

//get user referrer commissions
export const getEntities = createAsyncThunk('commission/fetch_entity_list', async (object: TypeDataUserReferrerCommission) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrlReferrerCommission}${EndURL}`;
  return await axios.get<TypeDataUserReferrerCommission[]>(requestUrl);
},
{ serializeError: serializeAxiosError }
);

//get user referrer commission rule
export const getEntity = createAsyncThunk('userReferrrerCommission/fetch_entity_user_referrer_commission', async (entity?:any) => {
  const { user_referrer_rule_id } = entity
  const requestUrl = `${apiUrlReferrerCommission}/${user_referrer_rule_id}`;
  return await axios.get<TypeDataUserReferrerCommission[]>(requestUrl);
},
{ serializeError: serializeAxiosError }
);

// create user referrer commission rule
export const createUserReferrerCommission = createAsyncThunk(
  'userReferrrerCommission/create_user_referrer_commission',
  async (entity:any, thunkAPI) => {
    const result = await axios.post<TypeDataUserReferrerCommission>(`${apiUrlReferrerCommission}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// edit user referrer commission rule
export const editUserReferrerCommission = createAsyncThunk(
  'userReferrrerCommission/edit_user_referrer_commission',
  async (entity: any, thunkAPI) => {
    const { user_referrer_rule_id, ...dataForm }  = entity
    const result = await axios.patch<TypeDataUserReferrerCommission>(`${apiUrlReferrerCommission}/${user_referrer_rule_id}`, helpers.cleanEntity(dataForm));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);


// delete user referrer commission rule
export const deleteUserReferrerCommission = createAsyncThunk(
  'userReferrrerCommission/delete_user_referrer_commission_rule',
  async (user_referrer_rule_id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrlReferrerCommission}/${user_referrer_rule_id}`;
    thunkAPI.dispatch(getEntities({}));
    return await axios.delete<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);



// Reducer
export const Reducer_User_Referrer_Commission = createEntitySlice({
  name: 'userReferrrerCommission',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage  = null;
      state.updateSuccess  = false;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = (action.payload as any).data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Hiển thị nhóm hoa hồng không thành công";
      })

      .addCase(deleteUserReferrerCommission.pending, (state, action) => {
        state.loading = true;
        state.updateSuccess  = false;
      })
      .addCase(deleteUserReferrerCommission.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess  = true;
      })
      .addCase(deleteUserReferrerCommission.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Xoá kiểu hoa hồng không thành công";
        state.updateSuccess  = false;
      })

      .addCase(createUserReferrerCommission.pending, (state, action) => {
        state.loading = true;
        state.updateSuccess  = false;
      })
      .addCase(createUserReferrerCommission.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess  = true;
      })
      .addCase(createUserReferrerCommission.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Thêm nhóm hoa hồng không thành công";
        state.updateSuccess  = false;
      })

      .addCase(editUserReferrerCommission.pending, (state, action) => {
        state.loading = true;
        state.updating = true;
        state.updateSuccess  = false;
      })
      .addCase(editUserReferrerCommission.fulfilled, (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess  = true;
      })
      .addCase(editUserReferrerCommission.rejected, (state, action) => {
        state.loading = false;
        state.updating = false;
        state.errorMessage = "Sửa nhóm hoa hồng không thành công";
        state.updateSuccess  = false;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: (action.payload as any).data,
          entity: null,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
  },
});

export const { clearError, reset } = Reducer_User_Referrer_Commission.actions;

// Reducer
export default Reducer_User_Referrer_Commission.reducer;
