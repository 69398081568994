import React from "react";
import { useEffect, useState } from "react";
import { Divider, InlineGrid, SkeletonDisplayText, Text, BlockStack, Card, Page } from "@shopify/polaris";
import { useAppSelector } from '../../config/store';
import DateRangePickerChart from "../../components/dateRangePickerChart";
import AnalyticsChart from "./components/analytics_chart";
import { generateDaysArray } from "utils";
import { dataTimeYear } from "constant";

export default function Analytics() {

  const loadingGeneralAnalytics = useAppSelector(state => state.analytics.loadingGeneralAnalytics);
  const [timeLog, setTimeLog] = useState({
    title: "theo năm",
    alias: "year",
    since: "",
    until: "",
  });
  const [active, setActive] = useState('revenue');
  const [dataChart, setDataChart] = useState(null);

  useEffect(() => {
    switch (active) {
      case 'revenue':
        setDataChart({
          time_log: timeLog.alias !== 'year' ? generateDaysArray(timeLog?.since, timeLog?.until) : dataTimeYear,
          data_name: [`Doanh thu ${timeLog?.title}`, 'Doanh thu mới nhất'],
          data_value: {
            value_old: [120, 132, 101, 134, 90, 230, 210],
            value_new: [220, 182, 191, 234, 290, 330, 310]
          }
        })
        break;

      case 'commission':
        setDataChart({
          time_log: timeLog.alias !== 'year' ? generateDaysArray(timeLog?.since, timeLog?.until) : dataTimeYear,
          data_name: [`Hoa hồng ${timeLog?.title}`, 'Hoa hồng mới nhất'],
          data_value: {
            value_old: [120, 132, 101, 134, 90, 230, 210],
            value_new: [220, 182, 191, 234, 290, 330, 310]
          }
        })
        break;

      case 'bonus':
        setDataChart({
          time_log: timeLog.alias !== 'year' ? generateDaysArray(timeLog?.since, timeLog?.until) : dataTimeYear,
          data_name: [`Tiền thưởng chi ${timeLog?.title}`, 'Tiền thưởng chi mới nhất'],
          data_value: {
            value_old: [120, 132, 101, 134, 90, 230, 210],
            value_new: [220, 182, 191, 234, 290, 330, 310]
          }
        })
        break;

      default:
        break;
    }
  }, [active, timeLog]);

  const onChangeRangeDatePicker = (value) => {
    setTimeLog(value)
  }

  return (
    <Page
      fullWidth
      title={'Thống kê (Đang phát triển phần mềm dữ liệu chưa chính xác)'}
      subtitle="Toàn cảnh việc kinh doanh của bạn"
    >

      <div style={{ paddingLeft: 15 }} ><DateRangePickerChart onChange={onChangeRangeDatePicker} /></div>
      <InlineGrid gap="400" columns={{ xs: 1, sm: 1, md: 1, lg: 1 }}>

        <div className="dashboard_column_1_wrap">
          <div style={{ padding: '15px' }}>
            <InlineGrid gap={"600"} columns={{ xs: 1, sm: 1, md: 3, lg: 3 }}>

              <div style={{ cursor: 'pointer' }} onClick={() => setActive('revenue')}>
                <Card background={active === 'revenue' ? "bg-fill-info-active" : "bg-fill"}>
                  <img src={require('media/images/4l.png')} width={"50px"} alt="" />
                  <BlockStack>
                    {
                      loadingGeneralAnalytics ? <SkeletonDisplayText size="small" /> : (
                        <>
                          <Text tone={active === 'revenue' ? "text-inverse" : "success"} as="h3" fontWeight="regular" variant="headingXl">Tổng doanh thu</Text>
                          <Text tone={active === 'revenue' ? "text-inverse" : "success"} as="h3" fontWeight="bold" variant="headingXl">10 tỷ VNĐ</Text>
                        </>
                    )}
                  </BlockStack>
                </Card>
              </div>

              <div style={{ cursor: 'pointer' }} onClick={() => setActive('commission')}>
                <Card background={active === 'commission' ? "bg-fill-info-active" : "bg-fill"}>
                  <img src={require('media/images/4l.png')} width={"50px"} alt="" />
                  <BlockStack>
                    {
                      loadingGeneralAnalytics ? <SkeletonDisplayText size="small" /> : (
                        <>
                          <Text tone={active === 'commission' ? "text-inverse" : "success"} as="h3" fontWeight="regular" variant="headingXl">Tổng hoa hồng đã chi</Text>
                          <Text tone={active === 'commission' ? "text-inverse" : "success"} as="h3" fontWeight="bold" variant="headingXl">850 triệu VNĐ</Text>
                        </>
                    )}
                  </BlockStack>
                </Card>
              </div>

              <div style={{ cursor: 'pointer' }} onClick={() => setActive('bonus')}>
                <Card background={active === 'bonus' ? "bg-fill-info-active" : "bg-fill"}>
                  <img src={require('media/images/4l.png')} width={"50px"} alt="" />
                  <BlockStack>
                    {
                      loadingGeneralAnalytics ? <SkeletonDisplayText size="small" /> : (
                        <>
                          <Text tone={active === 'bonus' ? "text-inverse" : "success"} as="h3" fontWeight="regular" variant="headingXl">Tổng tiền thưởng đã chi</Text>
                          <Text tone={active === 'bonus' ? "text-inverse" : "success"} as="h3" fontWeight="bold" variant="headingXl">500 triệu VNĐ</Text>
                        </>
                    )}
                  </BlockStack>
                </Card>
              </div>

            </InlineGrid>

          </div>
          <br />

          <Divider />
          <div>
            <br />
            <AnalyticsChart dataChart={dataChart} />
          </div>
        </div>

      </InlineGrid>
    </Page>
  )
}